import {
  useContext,
  useEffect,
  useState,
  KeyboardEvent,
  ReactElement,
  useRef,
} from "react";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { formatNumber } from "@progress/kendo-intl";
import { Label } from "@progress/kendo-react-labels";
import { StackLayout } from "@progress/kendo-react-layout";
import { FaPhone } from "react-icons/fa";
import { LocalizationContext } from "../../context/localizationContext";
import AppButton from "../buttons/AppButton";
import AppInput from "../inputs/AppInput";
import uiHelper from "../../helpers/uiHelper";
import baseStrings from "../../base/baseStrings";
import baseMessages from "../../base/baseMessages";
import { Popover } from "@progress/kendo-react-tooltip";
import BusinessObjectModel from "./../../interfaces/BusinessObjectModel";

interface Props {
  skipTranslate?: boolean;
  labelTitle: string;
  labelValue: string | undefined;
  minWidth: number;
  orientation?: "horizontal" | "vertical";
  labelType?: "success" | "error" | "warning" | "none";
  type?: "phone" | "email" | "link" | "numeric";
  route?: string;
  hideTitle?: boolean;
  onValueChanged?: (value: string | null) => void;
  enabled?: boolean;
  required?: boolean;
  showEditFlag?: boolean;
  editControl?: ReactElement;
  editControlValue?: number | string | BusinessObjectModel | undefined | null;
  onControlValueChanged?: (value: any) => void;
  skipTextTransformation?: boolean;
  defaultOnNoValue?: string;
  minValueWidth?: number;
  onClick?: () => void;
  valueClasses?: string;
  verticalAlign?: object;
  maxLabelWidth?: number;
  breakValueWhiteSpaces?: boolean;
  breakLabelWhiteSpaces?: boolean;
  breakValueWords?: boolean;
  breakLabelWords?: boolean;
  titlePill?: ReactElement<any, any>;
  ignoreDecimals?: boolean;
  onRemoveValue?: (event: any) => void;
  tooltipEnabled?: boolean;
  fitTooltipWidthContent?: boolean;
  disableFlex?: boolean;
  titleClasses?: string;
  validateControlValue?: boolean;
  isControlValueValid?: () => boolean;
}
export default function LabelText({
  labelTitle,
  labelValue,
  minWidth,
  skipTranslate = false,
  orientation = "vertical",
  type,
  hideTitle = false,
  enabled = true,
  onValueChanged,
  required = false,
  showEditFlag = false,
  editControl,
  editControlValue,
  onControlValueChanged,
  skipTextTransformation = false,
  defaultOnNoValue,
  minValueWidth,
  onClick,
  valueClasses,
  labelType,
  verticalAlign,
  maxLabelWidth,
  breakValueWhiteSpaces,
  breakLabelWhiteSpaces,
  breakValueWords,
  breakLabelWords,
  titlePill,
  ignoreDecimals,
  onRemoveValue,
  tooltipEnabled = false,
  fitTooltipWidthContent = false,
  disableFlex = false,
  titleClasses,
  validateControlValue,
  isControlValueValid,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  const [value, setValue] = useState<string | null>(labelValue || null);

  const [valid, setValid] = useState<boolean>(true);
  const [editable, setEditable] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  const [showTitleNotificationPopup, setShowTitleNotificationPopup] =
    useState<boolean>(false);

  const anchor = useRef<HTMLElement>(null);

  const toggleTitleNotificationPopup = () => {
    setShowTitleNotificationPopup((oldValue) => !oldValue);
  };

  const handleValueClick = () => {
    handleClick();

    if (tooltipEnabled) toggleTitleNotificationPopup();
  };

  const handleClick = () => {
    if (enabled && (onValueChanged || onControlValueChanged)) {
      setEditable(true);
      setShowSaveButton(true);
    }

    if (onClick) onClick();
    // if (editControl) setShowSaveButton(true);
  };
  const handleInputChanged = (event: InputChangeEvent) => {
    setShowSaveButton(true);

    setValue(event.value);
  };

  const handleSaveChanges = (/*e: MouseEvent<HTMLButtonElement>*/) => {
    submitValues();
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // if (event.keyCode === 13 || event.code === "Enter") {
    if (event.code === "Enter") {
      submitValues();
    }
  };

  const isValid = () => {
    if (!required) return true;

    if (editControl) {
      if (editControlValue === null) return false;
    } else {
      if (uiHelper.isNullOrEmptyString(value)) return false;
    }
    return true;
  };

  const submitValues = () => {
    if (!isValid()) {
      setValid(false);

      if (editControl) {
        setEditable(false); //Close Editing for editorControl State to be able to blur
      }

      return;
    }

    if (editControl && onControlValueChanged) {
      if (validateControlValue) {
        if (isControlValueValid && isControlValueValid())
          onControlValueChanged(editControlValue);
        else {
          setValid(false);
          return;
        }
      } else onControlValueChanged(editControlValue);
    } else if (onValueChanged && hasNewValue(value)) onValueChanged(value);

    setValid(true);
    setShowSaveButton(false);
    setEditable(false);
  };

  const hasNewValue = (value: string | null) => {
    //null != empty string
    return value === null
      ? !uiHelper.isNullOrEmptyString(labelValue)
      : value !== labelValue;
  };

  useEffect(() => {
    if (editControl) setShowSaveButton(true);
    // eslint-disable-next-line
  }, [editControlValue]);

  return (
    <Label
      style={
        maxLabelWidth
          ? {
              maxWidth: maxLabelWidth + "px",
              ...(disableFlex ? { flex: 0 } : {}),
            }
          : { ...(disableFlex ? { flex: 0 } : {}) }
      }
    >
      <StackLayout
        orientation={orientation}
        align={
          orientation === "horizontal"
            ? { horizontal: "start" }
            : verticalAlign || {}
        }
        gap={orientation === "horizontal" ? 10 : undefined}
      >
        {!hideTitle && (
          <span
            className={
              "labelTitle" +
              (breakLabelWhiteSpaces ? " break-white-spaces" : "") +
              (breakLabelWords ? " break-words" : "") +
              (titleClasses ? " " + titleClasses : "")
            }
            style={{
              minWidth: minWidth + "px",
              textTransform: skipTextTransformation ? "none" : "uppercase",
            }}
            onClick={handleClick}
          >
            {showEditFlag && (
              <span className="k-icon k-font-icon k-i-edit successColor editFlag"></span>
            )}
            {skipTranslate ? labelTitle : translate(labelTitle)}
            {titlePill && titlePill}
          </span>
        )}
        {editable ? (
          !editControl ? (
            <div className="iniline-editable-labelValue">
              {!valid && (
                <span
                  // className="k-icon k-font-icon k-i-close-circle k-i-x-circle errorColor"
                  className="k-icon k-font-icon k-i-warning errorColor"
                  title={translate(baseMessages.FIELD_IS_REQUIRED)}
                ></span>
              )}
              <AppInput
                onChange={handleInputChanged}
                defaultValue={labelValue}
                onKeyDown={handleOnKeyDown}
                className="inline-editable-label-value-input"
                onBlur={() => setEditable(false)}
              />
              {showSaveButton && (
                <AppButton
                  onClick={handleSaveChanges}
                  className="buttons-container-button inline-card-button"
                  iconClass="k-icon k-font-icon k-i-arrow-chevron-down"
                  title={translate(baseStrings.SAVE)}
                />
              )}
            </div>
          ) : (
            <div className="iniline-editable-labelValue">
              {!valid && (
                <span
                  // className="k-icon k-font-icon k-i-close-circle k-i-x-circle errorColor"
                  className="k-icon k-font-icon k-i-warning errorColor"
                  title={translate(baseMessages.FIELD_IS_REQUIRED)}
                ></span>
              )}
              {editControl}
              {showSaveButton && (
                <AppButton
                  onClick={handleSaveChanges}
                  className="buttons-container-button inline-card-button"
                  iconClass="k-icon k-font-icon k-i-arrow-chevron-down"
                  title={translate(baseStrings.SAVE)}
                />
              )}
            </div>
          )
        ) : (
          <span
            className={
              "labelValue" +
              (breakValueWhiteSpaces ? " break-white-spaces" : "") +
              (breakValueWords ? " break-words" : "") +
              (valueClasses ? " " + valueClasses : "")
            }
            onClick={handleValueClick}
            ref={anchor}
            style={minValueWidth ? { minWidth: minValueWidth + "px" } : {}}
          >
            {type === "phone" && labelValue && (
              <FaPhone color="green" style={{ margin: "0px 2px" }} />
            )}
            {type === "link" ? (
              <span className="hyperLink">{labelValue}</span>
            ) : defaultOnNoValue && uiHelper.isNullOrEmptyString(labelValue) ? (
              defaultOnNoValue
            ) : (
              <span className={labelType + "-color"}>
                {type === "numeric" && labelValue
                  ? formatNumber(
                      parseFloat(labelValue),
                      ignoreDecimals ? "n0" : "n2"
                    )
                  : labelValue}
              </span>
            )}
            {onRemoveValue && !uiHelper.isNullOrEmptyString(labelValue) && (
              <span
                className="k-icon k-font-icon k-i-close errorColor"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveValue(e);
                }}
              ></span>
            )}
            {tooltipEnabled && (
              <Popover
                className="app-pill-notification"
                show={showTitleNotificationPopup}
                anchor={anchor.current && anchor.current}
                position={"bottom"}
                callout={true}
                style={fitTooltipWidthContent ? {} : { maxWidth: "200px" }}
              >
                <span className="pill-notification-text">{labelValue}</span>
              </Popover>
            )}
          </span>
        )}
      </StackLayout>
    </Label>
  );
}
