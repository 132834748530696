import apiClient from "../common/api/client";
// import FormData, { getHeaders } from "form-data";
import endpoints from "./endpoints";

const endpoint = endpoints.ATTACHMENTS;
const attachment_types_endpoint = endpoints.ATTACHMENT_TYPES;

const route_download = "/download";

const uploadAttachment = (attachmentInfo, formData, onUploadProgress) => {
  return apiClient.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: attachmentInfo,
    onUploadProgress: (progress) =>
      onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
  });
};

// onUploadProgress: data => {
//   //Set the progress value to show the progress bar
//   setProgress(Math.round((100 * data.loaded) / data.total))
// },

const downloadAttachment = (attachmentID, onDownloadProgress) => {
  return apiClient.get(endpoint + route_download + "/" + attachmentID, null, {
    "Content-Type": "text/html",
    Accept: "*/*",
    "Accept-Encoding": "gzip, deflate, br",
    responseType: "arraybuffer",
    //"X-HTTP-Method-Override": "GET",
    onDownloadProgress: (progress) =>
      // onDownloadProgress(progress.loaded / progress.total),
      onDownloadProgress(Math.round((100 * progress.loaded) / progress.total)),
  });
};

const getAttachmentTypes = (moduleCode, attachmentGroupCode) => {
  return apiClient.post(
    endpoint + attachment_types_endpoint,
    JSON.stringify({
      ModuleCode: moduleCode,
      AttachmentGroupCode: attachmentGroupCode,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getAttachment = (attachmentID) => {
  return apiClient.get(endpoint + "/" + attachmentID);
};

const getAttachments = (criteria) => {
  return apiClient.post(endpoint, JSON.stringify(criteria), {
    headers: {
      "X-HTTP-Method-Override": "GET",
    },
  });
};

const attachmentsApi = {
  uploadAttachment,
  downloadAttachment,
  getAttachmentTypes,
  getAttachment,
  getAttachments,
};

export default attachmentsApi;

// module.exports = typeof self == "object" ? self.FormData : window.FormData;
