import { useContext } from "react";
import strings from "../../../base/strings";
import AppCard from "../../../common/components/cards/AppCard";
import AppCardImage from "../../../common/components/cards/AppCardImage";
import LabelText from "../../../common/components/Labels/LabelText";
import AppStackLayout from "../../../common/components/stack/AppStackLayout";
import { LocalizationContext } from "../../../common/context/localizationContext";
import styleConstants from "../../../common/constants/styleConstants";
import AttachmentModel from "../../../interfaces/attachments/AttachmentModel";
import AppButton from "../../../common/components/buttons/AppButton";

interface Props {
  attachment: AttachmentModel | null;
  setErrorMessage: (message: string) => void;
  onDeleteAttachment: (attachmentID: number) => void;
  minWidth?: number;
}

const AttachmentCard = ({
  attachment,
  onDeleteAttachment,
  minWidth,
}: Props) => {
  const { translate } = useContext(LocalizationContext);

  const MIN_IMAGE_COMPONENT_WIDTH = minWidth || 300;
  const AVATAR_WIDTH = 100;

  const handleDeleteAttachment = () => {
    if (!attachment) return;
    onDeleteAttachment(attachment.ID);
  };

  return attachment ? (
    <AppStackLayout
      orientation="horizontal"
      align={{ horizontal: "start" }}
      gap={0}
    >
      <AppCard>
        <AppStackLayout
          orientation="horizontal"
          align={{ horizontal: "start" }}
        >
          <AppCardImage
            image={attachment.Preview}
            altText={""}
            listView
            hideDescriptionWrapper
            flatAvatar
          />
          <AppStackLayout
            orientation="vertical"
            align={{ horizontal: "start" }}
            style={{
              width: MIN_IMAGE_COMPONENT_WIDTH,
              paddingBottom: styleConstants.GAP_HUGE,
            }}
          >
            <LabelText
              labelTitle={translate(strings.DATE)}
              labelValue={attachment.CreationDate}
              skipTranslate
              tooltipEnabled
              disableFlex
              valueClasses="long-text"
              skipTextTransformation
              minWidth={AVATAR_WIDTH}
            />
            <LabelText
              labelTitle={translate(strings.BY)}
              labelValue={attachment.CreatedByUser.Fullname}
              skipTranslate
              tooltipEnabled
              disableFlex
              valueClasses="long-text"
              skipTextTransformation
              minWidth={AVATAR_WIDTH}
            />
          </AppStackLayout>
        </AppStackLayout>
      </AppCard>
      <AppStackLayout align={{ vertical: "top", horizontal: "center" }}>
        <AppButton
          key={translate(strings.DELETE)}
          icon="trash"
          title={translate(strings.DELETE)}
          additionalClass="icononly transparent-background"
          onClick={handleDeleteAttachment}
        />
      </AppStackLayout>
    </AppStackLayout>
  ) : null;
};

export default AttachmentCard;
