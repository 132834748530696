import { CSSProperties, ReactNode, useContext } from "react";
import { Buffer } from "buffer";
import { LocalizationContext } from "../../context/localizationContext";
import { BadgeContainer } from "@progress/kendo-react-indicators";
import { Avatar } from "@progress/kendo-react-layout";

interface Props {
  image?: Uint8Array | null;
  altText: string;
  width?: string;
  height?: string;
  children?: ReactNode;
  listView?: boolean;
  hideDescriptionWrapper?: boolean;
  additionalWrapperStyle?: CSSProperties;
  flatAvatar?: boolean;
  handleImageClick?: () => void;
}
export default function AppCardImage({
  image,
  altText,
  width = "100px",
  height = "100px",
  children,
  listView = false,
  hideDescriptionWrapper = false,
  additionalWrapperStyle = {},
  flatAvatar = false,
  handleImageClick,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  return (
    <div
      className="cardBodyRowWithImage"
      style={{ padding: 0, ...additionalWrapperStyle }}
    >
      <BadgeContainer>
        <Avatar
          type={image ? "image" : "icon"}
          size={"large"}
          style={{ width, height }}
          className={
            (listView ? "cardBodyImage-listView" : "cardBodyImage") +
            (flatAvatar ? " no-round" : "")
          }
        >
          {image ? (
            <img
              alt={translate(altText)}
              src={`data:image/png;base64,${Buffer.from(image).toString(
                "base64"
              )}`}
              // className="cardBodyImage"
              // style={{ width: width, height: height }}
              onClick={handleImageClick}
            />
          ) : (
            <span
              className="k-icon k-font-icon k-i-user k-icon-32"
              // style={{ width: width, height: height }}
              // style={{ width: width, height: height }}
            ></span>
          )}
        </Avatar>
      </BadgeContainer>
      {!hideDescriptionWrapper && (
        <div className="cardBodyRowImageDescriptionWrapper">{children}</div>
      )}
    </div>
  );
}
