import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CUSTOMERS;

const route_customer = "/customer";
const route_customer_opening_balance = "/customer-opening-balance";
const route_customer_attachment = "/customer-attachment";
const route_bank_account = "/bank-account";
const route_direct_payment_recipient = "/direct-payment-recipient";
const act_customers = "/customers";
const act_create_new_customer = "/create-new-customer";
const act_update_customer_basic_info = "/update-customer-basic-info";
const act_update_customer_legal_info = "/update-customer-legal-info";
const act_delete = "/delete";
const act_customer_opening_balances = "/customer-opening-balances";
const act_add_customer_opening_balance = "/add-customer-opening-balance";
const act_customer_attachments = "/customer-attachments";
const act_add_customer_bank_account = "/add-customer-bank-account";
const act_update_customer_bank_account = "/update-customer-bank-account";
const act_delete_customer_bank_account = "/delete-customer-bank-account";
const act_add_customer_direct_payment_recipient =
  "/add-customer-direct-payment-recipient";
const act_update_customer_direct_payment_recipient =
  "/update-customer-direct-payment-recipient";
const act_delete_customer_direct_payment_recipient =
  "/delete-customer-direct-payment-recipient";
const act_download_customer_balance_detail_report =
  "/download-customer-balance-detail-report";
const act_download_transaction_list_by_customer_report =
  "/download-transaction-list-by-customer-report";
const act_download_customer_statement_of_account_report =
  "/download-customer-statement-of-account-report";

const getCustomer = (customerID) => apiClient.get(endpoint + "/" + customerID);

const getCustomers = (criteria, paging) => {
  return apiClient.post(
    endpoint + act_customers,
    JSON.stringify({ ...paging, ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewCustomer = (customer) => {
  return apiClient.post(
    endpoint + act_create_new_customer,
    JSON.stringify({ ...customer })
  );
};

const updateCustomerBasicInfo = (customerID, customer) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_update_customer_basic_info,
    JSON.stringify({ ...customer })
  );
};

const deleteCustomer = (customerID) => {
  return apiClient.delete(
    endpoint + route_customer + "/" + customerID + act_delete
  );
};

const getCustomerOpeningBalances = (customerID) =>
  apiClient.get(
    endpoint + route_customer + "/" + customerID + act_customer_opening_balances
  );

const addCustomerOpeningBalance = (customerID, customerOpeningBalance) => {
  return apiClient.post(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_add_customer_opening_balance,
    JSON.stringify({ ...customerOpeningBalance })
  );
};

const deleteCustomerOpeningBalance = (customerID, customerOpeningBalanceID) => {
  return apiClient.delete(
    endpoint +
      route_customer +
      "/" +
      customerID +
      route_customer_opening_balance +
      "/" +
      customerOpeningBalanceID +
      act_delete
  );
};

const updateCustomerLegalInfo = (customerID, criteria) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_update_customer_legal_info,
    JSON.stringify({ ...criteria })
  );
};

const getCustomerAttachments = (customerID) =>
  apiClient.get(
    endpoint + route_customer + "/" + customerID + act_customer_attachments
  );

const deleteCustomerAttachment = (customerID, customerAttachmentID) => {
  return apiClient.delete(
    endpoint +
      route_customer +
      "/" +
      customerID +
      route_customer_attachment +
      "/" +
      customerAttachmentID +
      act_delete
  );
};

const addCustomerAttachment = (customerID, attachmentID) => {
  return apiClient.post(
    endpoint +
      route_customer +
      "/" +
      customerID +
      route_customer_attachment +
      "/" +
      attachmentID
  );
};

const addCustomerBankAccount = (customerID, bankAccount) => {
  return apiClient.post(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_add_customer_bank_account,
    JSON.stringify({ ...bankAccount })
  );
};

const updateCustomerBankAccount = (customerID, bankAccountID, criteria) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      route_bank_account +
      "/" +
      bankAccountID +
      act_update_customer_bank_account,
    JSON.stringify({ ...criteria })
  );
};

const deleteCustomerBankAccount = (customerID) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_delete_customer_bank_account
  );
};

const addCustomerDirectPaymentRecipient = (
  customerID,
  directPaymentRecipient
) => {
  return apiClient.post(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_add_customer_direct_payment_recipient,
    JSON.stringify({ ...directPaymentRecipient })
  );
};

const updateCustomerDirectPaymentRecipient = (
  customerID,
  directPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      route_direct_payment_recipient +
      "/" +
      directPaymentRecipientID +
      act_update_customer_direct_payment_recipient,
    JSON.stringify({ ...criteria })
  );
};

const deleteCustomerDirectPaymentRecipient = (customerID) => {
  return apiClient.put(
    endpoint +
      route_customer +
      "/" +
      customerID +
      act_delete_customer_direct_payment_recipient
  );
};

const getCustomerBalanceDetailReportData = (criteria) => {
  return apiClient.post(
    endpoint + act_download_customer_balance_detail_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getTransactionListByCustomerReportData = (criteria) => {
  return apiClient.post(
    endpoint + act_download_transaction_list_by_customer_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getCustomerStatementOfAccountReportData = (criteria) => {
  return apiClient.post(
    endpoint + act_download_customer_statement_of_account_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const customersApi = {
  getCustomer,
  createNewCustomer,
  updateCustomerBasicInfo,
  getCustomers,
  deleteCustomer,
  getCustomerOpeningBalances,
  addCustomerOpeningBalance,
  deleteCustomerOpeningBalance,
  updateCustomerLegalInfo,
  getCustomerAttachments,
  deleteCustomerAttachment,
  addCustomerAttachment,
  addCustomerBankAccount,
  updateCustomerBankAccount,
  deleteCustomerBankAccount,
  addCustomerDirectPaymentRecipient,
  updateCustomerDirectPaymentRecipient,
  deleteCustomerDirectPaymentRecipient,
  getCustomerBalanceDetailReportData,
  getTransactionListByCustomerReportData,
  getCustomerStatementOfAccountReportData,
};

export default customersApi;
