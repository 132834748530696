import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../common/context/localizationContext";
import LoadingPanel from "../../common/components/general/LoadingPanel";
import AppStackLayout from "../../common/components/stack/AppStackLayout";
import AppCard from "../../common/components/cards/AppCard";
import Alert from "../../common/components/general/Alert";
import modules from "../../base/modules";
import AppUpload from "../../controls/upload/AppUpload";
import AttachmentModel from "../../interfaces/attachments/AttachmentModel";
import attachmentsApi from "../../api/attachments";
import errorHandler from "../../common/utility/errorHandler";
import uiHelper from "../../common/helpers/uiHelper";
import AppButton from "../../common/components/buttons/AppButton";
import digitalPrescriptionsApi from "../../api/digitalPrescriptions";
import messages from "../../base/messages";
import { alertTypes } from "../../common/components/base/alertTypes";
import DigitalPrescriptionModel from "../../interfaces/digitalPrescriptions/DigitalPrescriptionModel";
import attachmentTypeCodes from "../../enums/attachments/attachmentTypeCodes";
import AppCardList from "../../common/components/cards/AppCardList";
import AttachmentCard from "../../controls/attachments/cards/AttachmentCard";
import styleConstants from "../../common/constants/styleConstants";
import MainAppBar from "../../common/layout/MainAppBar";
import images from "../../base/images";
import LabelText from "../../common/components/Labels/LabelText";
import useBreakpoint from "../../common/hooks/useBreakpoint";
import breakpointKeys from "../../common/constants/breakpointKeys";
import strings from "../../base/strings";
import LabelTitle from "../../common/components/Labels/LabelTitle";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ContentContainer from "../../common/components/container/ContentContainer";
import { Icon } from "@progress/kendo-react-common";
import systemSettingApi from "../../api/systemSettings";
import systemSettingsCodes from "../../enums/systemSettings/systemSettingsCodes";
import ELanguageCodes from "../../common/base/languageCodes";
import AppActionBar from "../../common/components/actionBar/AppActionBar";
import AppDropDownButton from "../../common/components/buttons/AppDropDownButton";
import icons from "../../common/constants/icons";

interface Props {}

const UploadDigitalPrescriptionImagesPage = ({}: Props) => {
  const { translate, isRTL, updateLanguage } = useContext(LocalizationContext);

  const { id1, id2 } = useParams();

  const ARABIC_LANGUAGE: {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  } = {
    ...ELanguageCodes.ARABIC,
    // Name: translate(strings.LOCAL_NAME),
  };

  const ENGLISH_LANGUAGE: {
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  } = {
    ...ELanguageCodes.ENGLISH,
    // Name: translate(strings.FOREIGN_NAME),
  };

  useDocumentTitle(strings.DIGITAL_PRESCRIPTION, id1);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [attachments, setAttachments] = useState<AttachmentModel[]>([]);
  const [attachmentIDs, setAttachmentIDs] = useState<number[]>([]);
  const [Uploading, setUploading] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<string>("");

  const [digitalPrescription, setDigitalPrescription] =
    useState<DigitalPrescriptionModel | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const [languages] = useState<
    {
      Name: string;
      Code: string;
      Localization: string;
      rtlActive: boolean;
    }[]
  >([ENGLISH_LANGUAGE, ARABIC_LANGUAGE]);

  const [selectedLanguage, setSelectedLanguage] = useState<{
    Name: string;
    Code: string;
    Localization: string;
    rtlActive: boolean;
  }>(isRTL ? ARABIC_LANGUAGE : ENGLISH_LANGUAGE);

  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    loadSettings();
    loadDigitalPrescription();
  }, [id1]);

  useEffect(() => {
    internalLoadAttachment();
  }, [attachmentIDs]);

  const loadDigitalPrescription = async () => {
    if (!id1) return;

    setLoading(true);
    const response = await digitalPrescriptionsApi.getDigitalPrescription(+id1);
    setLoading(false);
    if (!response.ok) setErrorMessage(errorHandler.getErrorMessage(response));
    else setDigitalPrescription(response.data as DigitalPrescriptionModel);
  };

  const loadSettings = async () => {
    setLoading(true);
    const response = await systemSettingApi.getSystemSettingValue(
      systemSettingsCodes.DIGITAL_PRESCRIPTION_MESSAGE_DEFAULT_LANGUAGE
    );
    if (!response.ok) {
      setErrorMessage(errorHandler.getErrorMessage(response));
    } else {
      if (response.data) {
        setSelectedLanguage(
          response.data === "LOCAL" ? ARABIC_LANGUAGE : ENGLISH_LANGUAGE
        );
        updateLanguage(
          (response.data === "LOCAL" ? ARABIC_LANGUAGE : ENGLISH_LANGUAGE).Code
        );
      }
    }
    setLoading(false);
  };

  const internalLoadAttachment = async () => {
    if (uiHelper.isNullOrEmptyArray(attachmentIDs)) return;
    setLoading(true);
    const response = await attachmentsApi.getAttachments({
      AttachmentIDs: attachmentIDs,
    });
    if (response.ok) {
      setAttachments(response.data || []);
    } else {
      setErrorMessage(errorHandler.getErrorMessage(response));
    }
    setLoading(false);
  };

  const loadAttachments = (pageNumber: number, pageSize: number) => {
    return attachmentsApi.getAttachments({
      AttachmentIDs: attachmentIDs,
      page: pageNumber,
      pageSize,
    });
  };

  const onUploadFiles = async (attachmentIDs: Array<number>) => {
    setAttachmentIDs((prev) => [...prev, ...attachmentIDs]);
  };

  const handleDeleteAttachment = async (attachmentID: number) => {
    setAttachmentIDs(
      attachmentIDs.filter(
        (currentAttachmentID) => currentAttachmentID !== attachmentID
      )
    );
    // setAttachments(attachments.filter((ata) => ata.ID !== attachmentID));
    return true;
  };

  const handleSubmitDigitalPrescription = async () => {
    if (!digitalPrescription) return;

    if (Uploading) {
      setErrorMessage(messages.ATTACHMENT_STILL_UPLOADING);
      return;
    }
    if (uiHelper.isNullOrEmptyArray(attachmentIDs)) return;
    setLoading(true);
    const response = await digitalPrescriptionsApi.submitDigitalPrescription(
      digitalPrescription.ID,
      {
        AttachmentIDs: attachmentIDs,
        ApprovalID: id2,
      }
    );
    if (response.ok) {
      setFormSubmitted(true);
      // setAlertMessage(messages.SUBMITTED_SUCCESSFULLY);
      setAttachmentIDs([]);
      setAttachments([]);
    } else {
      setErrorMessage(errorHandler.getErrorMessage(response));
    }
    setLoading(false);
  };

  const MIN_COMPONENT_WIDTH = breakpoint === breakpointKeys.MOBILE ? 310 : 500;

  return (
    <>
      <MainAppBar
        handleClick={() => {}}
        logo={images.APPLICATION_TEXT_LOGO}
        // title={title}
        showLanguage={false}
        hideUsername={false}
        hideDrawerButton={true}
        hideUserPopupMenu={true}
      />
      <AppStackLayout className="external-container">
        <ContentContainer>
          {!formSubmitted ? (
            <div className={"form-container"}>
              <AppStackLayout
                // align={{
                //   horizontal:
                //     breakpoint === breakpointKeys.MOBILE
                //       ? isRTL
                //         ? "start"
                //         : "end"
                //       : "center",
                // }}
                // style={
                //   breakpoint === breakpointKeys.MOBILE
                //     ? { minWidth: "89%" }
                //     : {}
                // }

                align={{
                  horizontal: isRTL ? "start" : "end",
                }}
                style={{
                  minWidth:
                    breakpoint === breakpointKeys.MOBILE ? "90%" : "45%",
                }}
              >
                <AppActionBar>
                  <AppDropDownButton
                    className="button"
                    icon={icons.ARROW_CHEVRON_DOWN}
                    themeColor={"primary"}
                    text={selectedLanguage.Name}
                    textField="Name"
                    items={languages}
                    onItemClick={(e) => {
                      setSelectedLanguage(
                        e.item.Code === "ar"
                          ? ARABIC_LANGUAGE
                          : ENGLISH_LANGUAGE
                      );

                      updateLanguage(e.item.Code);
                    }}
                  />
                </AppActionBar>
              </AppStackLayout>
              <AppCard
                style={{
                  margin: "0 20px",
                  paddingTop: styleConstants.GAP_LARGER,
                }}
              >
                <AppStackLayout
                  orientation="vertical"
                  gap={styleConstants.GAP_HUGE}
                  align={{ horizontal: "center" }}
                >
                  <AppStackLayout
                    orientation="horizontal"
                    switchOnMobile
                    align={{ horizontal: "center", vertical: "middle" }}
                  >
                    <Icon name="warning" themeColor="error" size="xxlarge" />
                    <LabelText
                      labelTitle={""}
                      labelValue={translate(
                        messages.WRITE_PRESCRIPTION_CODE_MESSAGE
                      )}
                      minWidth={MIN_COMPONENT_WIDTH}
                      // labelType="error"
                      breakValueWhiteSpaces
                      valueClasses="large-bold"
                    />
                  </AppStackLayout>
                  <LabelText
                    labelTitle={strings.PRESCRIPTION_CODE}
                    labelValue={digitalPrescription?.PrescriptionCode || ""}
                    minWidth={0}
                    skipTextTransformation
                    valueClasses={(breakpoint === breakpointKeys.MOBILE
                      ? "title-larger"
                      : "enforce-extreme-large"
                    ).concat(" successColor")}
                  />
                </AppStackLayout>
              </AppCard>
              <AppUpload
                batch={false}
                multiple={true}
                moduleCode={modules.DIGITAL_PRESCRIPTION}
                attachmentGroupCode={undefined}
                onUploadFiles={onUploadFiles}
                showFileList={Uploading}
                setUploading={setUploading}
                disabled={Uploading}
                customFileTypes={["png", "jpg", "jpeg"]}
                attachmentTypeCode={attachmentTypeCodes.DIGITAL_PRESCRIPTION}
              />
              <AppButton
                themeColor={"primary"}
                title={translate(strings.SUBMIT_IMAGES)}
                rounded={"full"}
                style={{ minWidth: "240px", margin: "20px 0" }}
                onClick={handleSubmitDigitalPrescription}
                disabled={
                  Uploading || uiHelper.isNullOrEmptyArray(attachmentIDs)
                }
                icon="check"
              >
                {translate(strings.SUBMIT_IMAGES)}
              </AppButton>
              {!uiHelper.isNullOrEmptyArray(attachmentIDs) && (
                <div
                  style={{
                    height: 220,
                    width: "100%",
                    margin: "0 10px",
                  }}
                >
                  <AppCardList
                    data={attachments}
                    setData={setAttachments}
                    loading={loading}
                    setLoading={setLoading}
                    fetchDataOnStartup={false}
                    loadData={loadAttachments}
                    renderComponent={(item: AttachmentModel, index) => (
                      <AttachmentCard
                        attachment={item}
                        setErrorMessage={setErrorMessage}
                        key={index}
                        onDeleteAttachment={handleDeleteAttachment}
                        minWidth={
                          // breakpoint === breakpointKeys.MOBILE ? 120 : undefined
                          breakpoint === breakpointKeys.MOBILE ? 160 : undefined
                        }
                      />
                    )}
                  />
                </div>
              )}
            </div>
          ) : (
            <AppStackLayout
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "column",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "0 10px",
                // height: "100%",
                height: "80vh",
              }}
            >
              <AppCard
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LabelTitle
                  title={translate(messages.SUBMITTED_SUCCESSFULLY)}
                  isLargerTitle={breakpoint !== breakpointKeys.MOBILE}
                  isLargeTitle={breakpoint === breakpointKeys.MOBILE}
                  additionalClasses="success-color"
                />
              </AppCard>
            </AppStackLayout>
          )}
        </ContentContainer>
      </AppStackLayout>
      <div
        style={{
          height: "29px",
          display: "flex",
          marginTop: "1px",
          flexDirection: "row-reverse",
          paddingInlineEnd: "30px",
        }}
      >
        <img src={images.POWERED_BY_TRIAMETIS} alt="Powered By Triametis" />
      </div>
      {loading && <LoadingPanel />}
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}
      {alertMessage && (
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          type={alertTypes.INFO}
        />
      )}
    </>
  );
};

export default UploadDigitalPrescriptionImagesPage;
