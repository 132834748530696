export enum colorCategory {
  NONE,
  SUCCESS,
  ERROR,
  WARNING,
  DISABLED,
  UNDER_PROCESSING,
  NEEDS_SUBMISSION,
  REJECTED,
  //to remove
  ORANGE,
  YELLOW,
  LINK,
  PURPLE,
  BLUE,
  TRANSPARENT,
  GRAY,
  DARK_RED,
  WHITE,
  DARK_GREEN,
  PRIMARY,
}
