import { Dispatch, SetStateAction, useContext } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import GroupAutoComplete from "../../controls/users/base/GroupAutoComplete";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppProviderGroupSearchFieldKeys = {
  SecurityGroupID: "SecurityGroupID",
};
export default function AppProviderGroupSearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "ID",
  setErrorMessage,
}: Props) {
  const { translate } = useContext(LocalizationContext);

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <GroupAutoComplete
        columns={[]}
        dataList={[]}
        loadDataOnOpen
        placeholder={translate(strings.SECURITY_GROUP)}
        className="search-component"
        searchCriteria={{ IsProviderGroup: true }}
        onChange={(event) => {
          if (event.value)
            setSearchFieldValues(
              AppProviderGroupSearchFieldKeys.SecurityGroupID,
              event.value.ID
            );
          else
            removeSearchFieldValue(
              AppProviderGroupSearchFieldKeys.SecurityGroupID
            );
        }}
      />
    </div>
  );
}
