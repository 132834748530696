import { useContext } from "react";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import BillPaymentModel from "../../../interfaces/billPayments/BillPaymentModel";
import billPaymentsApi from "../../../api/billPayments";
import { pageDefaultSize } from "../../../common/constants/appConstants";

interface Props extends MultiColumnComboBoxProps {
  dataList: Array<BillPaymentModel>;
  loadDataOnOpen?: boolean;
}
export default function BillPaymentAutoComplete({
  dataList,
  loadDataOnOpen,
  columns,
  ...rest
}: Props) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "100px" },
    { field: "Bank", header: translate(strings.BANK), width: "150px" },
    {
      field: "PaymentMethodModel",
      header: translate(strings.PAYMENT_METHOD),
      width: "100px",
    },
    {
      field: "BillPaymentStatusModel",
      header: translate(strings.STATUS),
      width: "150px",
    },
    {
      field: "ProviderPaymentVoucherGroup",
      header: translate(strings.PVP_GROUP),
      width: "150px",
    },
    {
      field: "ReferenceNumber",
      header: translate(strings.REFERENCE_NO),
      width: "150px",
    },
    {
      field: "CreationDate",
      header: translate(strings.CREATION_DATE),
      width: "150px",
    },
    {
      field: "TotalAmount",
      header: translate(strings.TOTAL_AMOUNT),
      width: "150px",
    },
    {
      field: "PaidDate",
      header: translate(strings.PAID_DATE),
      width: "150px",
    },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return billPaymentsApi.getBillPayments({
      BillPaymentIDorReferenceNumber: inputString,
      Page: 0,
      PageSize: pageDefaultSize,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<BillPaymentModel>
      dataList={dataList}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      charNumber={3}
      loadDataOnOpen={loadDataOnOpen}
      {...rest}
    />
  );
}
