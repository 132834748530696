export default Object.freeze({
  ADMIN_PORTAL: "/admin",
  CLIENT_PORTAL: "/client",
  PROVIDER_PORTAL: "/provider",
  INSURED_PORTAL: "/insured",
  TPA_PORTAL: "/tpa",
  INSURANCE_COMPANY_PORTAL: "/insurance",
  REINSURER_PORTAL: "/reinsurer",
  PAYER_PORTAL: "/payer",
});
