import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.BILL_PAYMENTS;

const route_bill_payment_details = "/bill-payment-details";
const route_bill_payments = "/bill-payments";
const route_bill_payment = "/bill-payment";
const route_bill_payment_recipient = "/bill-payment-recipient";
const route_bill_payment_detail = "/bill-payment-detail";
const route_bank = "/bank";
const route_bill_payment_processing_workspaces =
  "/bill-payment-processing-workspaces";

const act_bill_payment_recipients = "/bill-payment-recipients";
const act_create_bill_payment = "/create-bill-payment";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_delete = "/delete";
const act_set_payment_amount = "/set-payment-amount";
const act_recalculate = "/recalculate";
const act_update_bill_payment_bank = "/update-bill-payment-bank";
const act_send = "/send";
const act_unsend = "/unsend";
const act_update_bill_payment = "/update-bill-payment";
const act_update_reference_number = "/update-reference-number";
const act_set_bill_payment_processed = "/set-bill-payment-processed";
const act_unset_bill_payment_processed = "/unset-bill-payment-processed";
const act_unpay = "/unpay";
const act_create_pay_bill_payment_task = "/create-pay-bill-payment-task";
const act_detach_bill_payment_task = "/detach-bill-payment-task";
const act_add_bill_payment_recipient = "/add-bill-payment-recipient";
const act_update_bill_payment_recipient = "/update-bill-payment-recipient";
const act_set_bill_payment_recipient_processed =
  "/set-bill-payment-recipient-processed";
const act_unset_bill_payment_recipient_processed =
  "/unset-bill-payment-recipient-processed";
const act_add_bill_payment_details = "/add-bill-payment-details";
const act_bill_payment_authorized_signatories =
  "/bill-payment-authorized-signatories";
const route_bill_payment_authorized_signatory =
  "/bill-payment-authorized-signatory";
const act_bill_payment_pending_bills = "/bill-payment-pending-bills";
const act_bill_payment_recipient_payment_processing_history =
  "/bill-payment-recipient-payment-processing-history";
const act_add_bill_payment_authorized_signatories =
  "/add-bill-payment-authorized-signatories";
const act_generate_bill_payments = "/generate-bill-payments";
const act_generate_cheque_bill_payments = "/generate-cheque-bill-payments";
const act_generate_cash_bill_payments = "/generate-cash-bill-payments";
const act_pending_broker_payments_count = "/pending-broker-payments-count";
const act_bill_payment_source_bank_accounts =
  "/bill-payment-source-bank-accounts";
const route_download_provider_bill_payment_cheque_detail_report =
  "/download-provider-bill-payment-cheque-detail-report";

const act_download_bill_payment_processing_workspace_report =
  "/download-bill-payment-processing-workspace-report";
const act_import_bill_payment_processing_workspace_drafts =
  "/import-bill-payment-processing-workspace-drafts";
const act_check_reject_duplicates = "/check-reject-duplicates";
const act_commit = "/commit";
const act_workspace_summary = "/workspace-summary";

const act_download_bill_payment_recipients_report =
  "/download-bill-payment-recipients-report";
const act_download_bill_payment_details_report =
  "/download-bill-payment-details-report";
const act_download_provider_payment_volume_report =
  "/download-provider-payment-volume-report";

const getBillPaymentDetails = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_bill_payment_details,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getBillPayments = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_bill_payments,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getBillPaymentRecipients = (criteria) => {
  return apiClient.post(
    endpoint + act_bill_payment_recipients,
    JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createBillPayment = (billPayment) => {
  return apiClient.post(
    endpoint + act_create_bill_payment,
    JSON.stringify({ ...billPayment })
  );
};

const getBillPayment = (billPaymentID) => {
  return apiClient.get(endpoint + route_bill_payment + "/" + billPaymentID);
};

const checkinBillPayment = (billPaymentID) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_checkin
  );
};

const checkoutBillPayment = (billPaymentID) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_checkout
  );
};

const deleteBillPayment = (billPaymentID) => {
  return apiClient.delete(
    endpoint + route_bill_payment + "/" + billPaymentID + act_delete
  );
};

const setBillPaymentDetailPayment = (
  billPaymentRecipientID,
  billPaymentDetailID,
  paymentAmount
) => {
  return apiClient.put(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      route_bill_payment_detail +
      "/" +
      billPaymentDetailID +
      act_set_payment_amount,
    JSON.stringify({ Amount: paymentAmount })
  );
};

const recalculateBillPayment = (billPaymentID) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_recalculate
  );
};

const updateBillPaymentBank = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_update_bill_payment_bank,
    JSON.stringify({ ...criteria })
  );
};

const sendBillPayment = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_send,
    JSON.stringify({ ...criteria })
  );
};

const unSendBillPayment = (billPaymentID) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_unsend
  );
};

const updateBillPayment = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_update_bill_payment,
    JSON.stringify({ ...criteria })
  );
};

const updateBillPaymentReferenceNumber = (billPaymentID, referenceNumber) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_update_reference_number,
    JSON.stringify({ ReferenceNumber: referenceNumber })
  );
};

const setBillPaymentProcessed = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_set_bill_payment_processed,
    JSON.stringify({
      ...criteria,
    })
  );
};

const unSetBillPaymentProcessed = (billPaymentID) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_unset_bill_payment_processed
  );
};

const unPayBillPayment = (billPaymentID) => {
  return apiClient.put(
    endpoint + route_bill_payment + "/" + billPaymentID + act_unpay
  );
};

const createPayBillPaymentTask = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_create_pay_bill_payment_task,
    JSON.stringify({ ...criteria })
  );
};

const detachBillPaymentTask = (billPaymentID) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_detach_bill_payment_task
  );
};

const addBillPaymentRecipient = (billPaymentID, criteria) => {
  return apiClient.post(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_add_bill_payment_recipient,
    JSON.stringify({ ...criteria })
  );
};

const deleteBillPaymentRecipient = (billPaymentRecipientID) => {
  return apiClient.delete(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_delete
  );
};

const updateBillPaymentRecipient = (billPaymentRecipientID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_update_bill_payment_recipient,
    JSON.stringify({ ...criteria })
  );
};

const setBillPaymentRecipientProcessed = (
  billPaymentID,
  billPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_set_bill_payment_recipient_processed,
    JSON.stringify({ ...criteria })
  );
};

const unsetBillPaymentRecipientProcessed = (
  billPaymentID,
  billPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_unset_bill_payment_recipient_processed
  );
};

const deleteBillPaymentDetail = (
  billPaymentRecipientID,
  billPaymentDetailID
) => {
  return apiClient.delete(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      route_bill_payment_detail +
      "/" +
      billPaymentDetailID +
      act_delete
  );
};

const addBillPaymentDetails = (billPaymentRecipientID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_add_bill_payment_details,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getBillPaymentAuthorizedSignatories = (billPaymentID) => {
  return apiClient.get(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_bill_payment_authorized_signatories
  );
};

const deleteBillPaymentAuthorizedSignatory = (
  billPaymentID,
  billPaymentAuthorizedSignatoryID
) => {
  return apiClient.delete(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      route_bill_payment_authorized_signatory +
      "/" +
      billPaymentAuthorizedSignatoryID +
      act_delete
  );
};

const getBillPaymentPendingBills = (billPaymentID) => {
  return apiClient.get(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_bill_payment_pending_bills
  );
};

const getBillPaymentRecipientPaymentProcessingHistory = (
  billPaymentRecipientID
) => {
  return apiClient.get(
    endpoint +
      route_bill_payment_recipient +
      "/" +
      billPaymentRecipientID +
      act_bill_payment_recipient_payment_processing_history
  );
};

const addBillPaymentAuthorizedSignatories = (billPaymentID, criteria) => {
  return apiClient.put(
    endpoint +
      route_bill_payment +
      "/" +
      billPaymentID +
      act_add_bill_payment_authorized_signatories,
    JSON.stringify({
      ...criteria,
    })
  );
};

const generateBillPayments = (criteria) => {
  return apiClient.put(
    endpoint + act_generate_bill_payments,
    JSON.stringify({ ...criteria })
  );
};

const generateChequeBillPayments = (criteria) => {
  return apiClient.put(
    endpoint + act_generate_cheque_bill_payments,
    JSON.stringify({ ...criteria })
  );
};

const generateCashBillPayments = (criteria) => {
  return apiClient.put(
    endpoint + act_generate_cash_bill_payments,
    JSON.stringify({ ...criteria })
  );
};

const getPendingBrokerPaymentsCount = () => {
  return apiClient.get(endpoint + act_pending_broker_payments_count);
};

const getBillPaymentSourceBankAccounts = (bankID) =>
  apiClient.get(
    endpoint + route_bank + "/" + bankID + act_bill_payment_source_bank_accounts
  );

const getProviderBillPaymentChequeDetailReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_bill_payment_cheque_detail_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createBillPaymentProcessingWorkspace = (billPaymentID, criteria) => {
  return apiClient.post(
    endpoint + route_bill_payment_processing_workspaces,
    JSON.stringify({ ...criteria, BillPaymentID: billPaymentID })
  );
};

const importBillPaymentProcessingWorkspaceDrafts = (
  billPaymentID,
  billPaymentProcessingWorkspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint +
      route_bill_payment_processing_workspaces +
      act_import_bill_payment_processing_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        BillPaymentID: billPaymentID,
        BillPaymentProcessingWorkspaceID: billPaymentProcessingWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};
const getBillPaymentProcessingWorkspaces = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_bill_payment_processing_workspaces,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getBillPaymentProcessingWorkspaceSummary = (
  billPaymentProcessingWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_bill_payment_processing_workspaces +
      "/" +
      billPaymentProcessingWorkspaceID +
      act_workspace_summary
  );
};

const deleteBillPaymentProcessingWorkspace = (
  billPaymentProcessingWorkspaceID
) => {
  return apiClient.delete(
    endpoint +
      route_bill_payment_processing_workspaces +
      "/" +
      billPaymentProcessingWorkspaceID
  );
};

const checkAndRejectDuplicateBillPaymentProcessingDrafts = (
  billPaymentProcessingWorkspaceID,
  statusCode
) => {
  return apiClient.get(
    endpoint +
      route_bill_payment_processing_workspaces +
      "/" +
      billPaymentProcessingWorkspaceID +
      act_check_reject_duplicates +
      "/" +
      statusCode
  );
};

const commitBillPaymentProcessingWorkspace = (
  billPaymentProcessingWorkspaceID
) => {
  return apiClient.put(
    endpoint +
      route_bill_payment_processing_workspaces +
      "/" +
      billPaymentProcessingWorkspaceID +
      act_commit
  );
};

const downloadBillPaymentProcessingWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_bill_payment_processing_workspaces +
      act_download_bill_payment_processing_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getBillPaymentRecipientsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_bill_payment_recipients_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getBillPaymentDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_bill_payment_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderPaymentVolumeReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_provider_payment_volume_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const billPaymentsApi = {
  getBillPaymentDetails,
  getBillPayments,
  getBillPaymentRecipients,
  createBillPayment,
  getBillPayment,
  checkinBillPayment,
  checkoutBillPayment,
  deleteBillPayment,
  setBillPaymentDetailPayment,
  recalculateBillPayment,
  updateBillPaymentBank,
  sendBillPayment,
  unSendBillPayment,
  updateBillPayment,
  updateBillPaymentReferenceNumber,
  setBillPaymentProcessed,
  unSetBillPaymentProcessed,
  unPayBillPayment,
  createPayBillPaymentTask,
  detachBillPaymentTask,
  addBillPaymentRecipient,
  deleteBillPaymentRecipient,
  updateBillPaymentRecipient,
  setBillPaymentRecipientProcessed,
  unsetBillPaymentRecipientProcessed,
  deleteBillPaymentDetail,
  addBillPaymentDetails,
  getBillPaymentAuthorizedSignatories,
  deleteBillPaymentAuthorizedSignatory,
  getBillPaymentPendingBills,
  getBillPaymentRecipientPaymentProcessingHistory,
  addBillPaymentAuthorizedSignatories,
  generateBillPayments,
  generateChequeBillPayments,
  generateCashBillPayments,
  getPendingBrokerPaymentsCount,
  getBillPaymentSourceBankAccounts,
  getProviderBillPaymentChequeDetailReport,
  getBillPaymentProcessingWorkspaces,
  createBillPaymentProcessingWorkspace,
  deleteBillPaymentProcessingWorkspace,
  getBillPaymentProcessingWorkspaceSummary,
  downloadBillPaymentProcessingWorkspaceReport,
  importBillPaymentProcessingWorkspaceDrafts,
  checkAndRejectDuplicateBillPaymentProcessingDrafts,
  commitBillPaymentProcessingWorkspace,
  getBillPaymentRecipientsReport,
  getBillPaymentDetailsReport,
  getProviderPaymentVolumeReport,
};

export default billPaymentsApi;
