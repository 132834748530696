import endpoints from "../../../api/endpoints";
import strings from "../../../base/strings";

const services = {
  columns: [
    {
      field: "LocalName",
      header: strings.LOCAL_NAME,
      width: "150px",
    },
    {
      field: "ForeignName",
      header: strings.FOREIGN_NAME,
      width: "150px",
    },
    // {
    //   field: "Name",
    //   header: strings.NAME,
    //   width: "250px",
    // },
    {
      field: "SpecializedCode",
      header: strings.SPECIALIZED_CODE,
      width: "130px",
    },
    { field: "DefaultPrice", header: strings.DEFAULT_PRICE, width: "100px" },
    // { field: "DoseUnitModel", header: strings.DOSE_UNIT, width: "100px" },
    // { field: "Potency", header: strings.POTENCY, width: "100px" },
    {
      field: "MedicineInformation",
      header: strings.MEDICINE_INFORMATION,
      width: "100px",
    },
    {
      field: "MedicinePackagingType",
      header: strings.PACKAGING_TYPE,
      width: "100px",
    },
    {
      field: "MedicineDosageForm",
      header: strings.DOSAGE_FORM,
      width: "100px",
    },
    { field: "IsImported", header: strings.IMPORTED, width: "75px" },
    //
  ],
  autoCompleteEndpoint: endpoints.SERVICES,
  autoCompleteSearchKey: "ServiceNameOrCode",
};

export default services;
