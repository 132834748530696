import ETenant from "./ETenant";
import ETheme from "./ETheme";

const EImageConstants = {
  APPLICATION_LOGO:
    process.env.REACT_APP_TENANT === ETenant.TPA &&
    process.env.REACT_APP_THEME === ETheme.DEFAULT
      ? process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/etpa.png"
      : process.env.REACT_APP_TENANT === ETenant.TPA &&
          process.env.REACT_APP_THEME === ETheme.CUSTOM_TPA
        ? process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/etpa-green.png"
        : process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/epayer.png",
  LOGO: process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/IMPA_New_Logo.png",
  APPLICATION_TEXT_LOGO:
    process.env.REACT_APP_TENANT === ETenant.TPA
      ? process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/etpa_text.png"
      : process.env.REACT_APP_ROUTER_BASENAME + "/assets/img/epayer_text.png",
  POWERED_BY_TRIAMETIS:
    process.env.REACT_APP_ROUTER_BASENAME +
    "/assets/img/powered_by_triametis_logo.png",
};

export default EImageConstants;
