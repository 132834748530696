import React, { cloneElement, useContext, useState, useEffect } from "react";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxOpenEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../common/context/localizationContext";
import { AutoCompleteProps } from "../../common/ui/AutoCompleteProps";
import FormMultiColumnComboBox from "../../common/components/formComponents/formMultiColumnComboBox";
import Alert from "../../common/components/general/Alert";
import AppMultiColumnComboBox from "../../common/components/inputs/AppMultiColumnComboBox";
import uiHelper from "../../common/helpers/uiHelper";

export default function AppAutoComplete<T>({
  dataList,
  loadDataOnOpen,
  isFormComponent = true,
  columns,
  searchCriteria,
  charNumber = 2,
  textFieldDisplay,
  getData,
  customRender,
  setByDefaultIfOnlyOneItem,
  filterData,
  onChange,
  ...rest
}: AutoCompleteProps<T>) {
  //#region  Variables

  const { getName } = useContext(LocalizationContext);

  const [data, setData] = useState<T[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  //#endregion

  //#region Effects

  useEffect(() => {
    if (dataList && dataList.length > 0) setData(dataList);
  }, [dataList]);

  //#endregion

  //#region Events

  let interval: NodeJS.Timeout | undefined;
  const handleOnFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (interval) clearInterval(interval);

    interval = setInterval(async () => {
      const chars = event.filter.value;
      if (chars && chars.length >= charNumber) {
        setIsFiltered(true);
        loadData(chars.trim());
      } else setData(dataList);
      clearInterval(interval);
    }, 1000);
  };

  const handleOpen = (event: ComboBoxOpenEvent) => {
    !isFiltered && loadDataOnOpen && (!data || data.length === 0) && loadData();
  };

  const itemRender = (
    li: React.ReactElement<
      HTMLLIElement,
      string | React.JSXElementConstructor<any>
    >,
    itemProps: ListItemProps
  ) => {
    const children = customRender
      ? customRender(li, itemProps)
      : columns.map((col, i) => {
          return (
            <span
              className="k-table-td"
              style={{
                width: col.width,
              }}
              key={col.width + i}
            >
              {/* {
                // itemProps.dataItem is Approval instance of cardList data
                itemProps.dataItem[col.field] instanceof Object
                  ? getName(itemProps.dataItem[col.field])
                    ? getName(itemProps.dataItem[col.field])
                    : itemProps.dataItem[col.field].ID
                  : itemProps.dataItem[col.field]
              } */}
              {uiHelper.getFieldValue(itemProps.dataItem, col.field) instanceof
              Object
                ? getName(uiHelper.getFieldValue(itemProps.dataItem, col.field))
                  ? getName(
                      uiHelper.getFieldValue(itemProps.dataItem, col.field)
                    )
                  : uiHelper.getFieldValue(itemProps.dataItem, col.field).ID
                : uiHelper.getFieldValue(itemProps.dataItem, col.field)}
            </span>
          );
        });

    // const { style = {} }: any = li.props;
    // style.textAlign = "center !important";

    return cloneElement(li, { ...li.props /*style*/ }, children);
  };

  //#endregion

  //#region  private Members

  const loadData = async (inputString?: string) => {
    if (filterData) {
      if (inputString) setData(filterData(inputString));
    } else if (getData) {
      setLoading(true);

      const response = await getData(inputString, searchCriteria);

      if (response.ok) {
        const cards = response.data;
        if (cards) setData(cards);
        else setData([]);
      }
      setLoading(false);
    }
  };

  //#endregion

  return isFormComponent ? (
    <FormMultiColumnComboBox
      filterable={true}
      dataList={data}
      textField={textFieldDisplay}
      columns={columns}
      onFilterChange={handleOnFilterChange}
      itemRender={itemRender}
      onOpen={handleOpen}
      // opened={true}
      // popupSettings={{ popupClass: "dropDownSettingsPopup",className: "dropDownSettingsPopup" }}
      // header={<div style={{ textAlign: "center" }}></div>}
      loadingData={loading}
      setByDefaultIfOnlyOneItem={setByDefaultIfOnlyOneItem}
      onChange={(event: ComboBoxChangeEvent) => {
        if (!event.value) setIsFiltered(false);
        if (onChange) onChange(event);
      }}
      {...rest}
    />
  ) : (
    <>
      <AppMultiColumnComboBox
        filterable={true}
        dataList={data}
        textField={textFieldDisplay}
        columns={columns}
        onFilterChange={handleOnFilterChange}
        itemRender={itemRender}
        loadingData={loading}
        onOpen={handleOpen}
        setByDefaultIfOnlyOneItem={setByDefaultIfOnlyOneItem}
        onChange={(event: ComboBoxChangeEvent) => {
          if (!event.value) setIsFiltered(false);
          if (onChange) onChange(event);
        }}
        {...rest}
      />
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}
    </>
  );
}
