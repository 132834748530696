import { getter } from "@progress/kendo-react-common";
import { AmountCurrencyValue } from "../panels/AppAmountCurrencyPanel";
// import { FieldRenderProps } from '@progress/kendo-react-form';
import baseMessages from "../../base/baseMessages";
import { dummyItemID } from "../../constants/appConstants";
import uiHelper from "../../helpers/uiHelper";

const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const phoneRegex: RegExp = /^[0-9 ()+-]+$/;
const numberRegex: RegExp = /^[0-9\b]+$/;
const percentageRegex: RegExp = /^((\d{0,2}(\.\d{1,4})?)|100)$/;
//const amountRegex: RegExp = /^[0-9]*([.][0-9]*)?$/;
const amountRegex: RegExp = /^[0-9]*(\.\d{1,2})?$/;
const pos_neg_numberRegExp = /^-?[1-9]+([0-9]+)*$/;
const pos_neg_amountRegex: RegExp = /^-?[0-9]*(\.\d{1,2})?$/;
const usernameRegex: RegExp = /^[a-zA-Z][a-zA-Z0-9._-]{1,14}[a-zA-Z0-9]$/;

// export const termsValidator = (value: string) =>
//   value ? "" : "It's required to agree with Terms and Conditions.";

export const emailValidator = (value: string) =>
  !value
    ? baseMessages.EMAIL_IS_REQUIRED
    : emailRegex.test(value)
      ? ""
      : baseMessages.INVALID_EMAIL_FORMAT;

export const userNameValidator = (value: string) =>
  !value
    ? baseMessages.USERNAME_REQUIRED
    : // : value.length < 2
      // ? "User name should be at least 2 characters long."
      "";

export const requiredPhoneNumber = (value: string) =>
  !value
    ? baseMessages.PHONE_IS_REQUIRED
    : phoneRegex.test(value)
      ? ""
      : baseMessages.INVALID_PHONE_NUMBER;

export const phoneValidator = (value: string) => {
  if (value)
    if (!phoneRegex.test(value)) return baseMessages.INVALID_PHONE_NUMBER;
};

export const numberValidator = (value: string) =>
  value ? (numberRegex.test(value) ? "" : baseMessages.INVALID_NUMBER) : "";

export const positiveNegativeNumberValidator = (value: string) =>
  value
    ? pos_neg_numberRegExp.test(value)
      ? ""
      : baseMessages.INVALID_NUMBER
    : "";

export const positiveNegativeAmountValidator = (value: string) =>
  value
    ? pos_neg_amountRegex.test(value)
      ? ""
      : baseMessages.INVALID_AMOUNT
    : "";
export const amountValidator = (value: string) =>
  value ? (amountRegex.test(value) ? "" : baseMessages.INVALID_AMOUNT) : "";

export const requiredNotZeroAmountValidator = (value: string) =>
  !uiHelper.isNullOrEmptyNumber(Number.parseFloat(value))
    ? amountRegex.test(value)
      ? ""
      : baseMessages.INVALID_AMOUNT
    : baseMessages.FIELD_IS_REQUIRED;

export const percentageValidator = (value: string) =>
  value
    ? percentageRegex.test(value)
      ? ""
      : baseMessages.INVALID_PERCENTAGE
    : "";
export const requiredPercentageValidator = (value: string) =>
  value === undefined || value === null
    ? baseMessages.FIELD_IS_REQUIRED
    : percentageRegex.test(value)
      ? ""
      : baseMessages.INVALID_PERCENTAGE;

export const requiredNumberValidator = (value: string) =>
  value === undefined || value === null
    ? baseMessages.FIELD_IS_REQUIRED
    : numberRegex.test(value)
      ? ""
      : baseMessages.INVALID_NUMBER;

export const requiredNotZeroNumberValidator = (value: string) =>
  uiHelper.isNullOrEmptyNumber(Number.parseInt(value))
    ? baseMessages.FIELD_IS_REQUIRED
    : numberRegex.test(value)
      ? ""
      : baseMessages.INVALID_NUMBER;

export const requiredValidator = (value: string) =>
  value ? "" : baseMessages.FIELD_IS_REQUIRED;

export const requiredNotEmptyValidator = (value: string) =>
  value &&
  !uiHelper.isNullOrEmptyString(value) &&
  !uiHelper.isNullOrEmptyString(value.trim())
    ? ""
    : baseMessages.FIELD_IS_REQUIRED;

export const passwordValidator = (value: string) =>
  value && value.length >= 4
    ? ""
    : baseMessages.PASSWORD_AT_LEAST_FOUR_CHARACTERS;

export const requiredPasswordValidator = (value: string) =>
  !value
    ? baseMessages.PASSWORD_IS_REQUIRED
    : value.length >= 4
      ? ""
      : baseMessages.PASSWORD_AT_LEAST_FOUR_CHARACTERS;

export const addressValidator = (value: string) =>
  value ? "" : baseMessages.ADDRESS_IS_REQUIRED;

export const simpleDropDownRequiredValidator = (value: string) =>
  value ? "" : baseMessages.FIELD_IS_REQUIRED;

export const requiredItem = (value: any) => {
  if (!value) return baseMessages.FIELD_IS_REQUIRED;
  if (typeof value === "object") {
    if (Object.keys(value).length === 0) return baseMessages.FIELD_IS_REQUIRED;
    if (value["ID"] && value["ID"] === -1)
      return baseMessages.FIELD_IS_REQUIRED;
  }
};

export const requiredItem_Custom = (value: any) => {
  if (!value) return baseMessages.FIELD_IS_REQUIRED;
  if (typeof value === "object") {
    if (Object.keys(value).length === 0) return baseMessages.FIELD_IS_REQUIRED;
    if (!value["ID"] || value["ID"] === -1) {
      return baseMessages.FIELD_IS_REQUIRED;
    }
  }
};

export const requiredAmountAndCurrency = (value: AmountCurrencyValue) => {
  if (!value || !value.amount || !value.currency)
    return baseMessages.FIELD_IS_REQUIRED;
  if (value && value.currency && value.currency.ID === dummyItemID)
    return baseMessages.CURRENCY_IS_REQUIRED;
};

export const requiredAmountCurrency = (value: AmountCurrencyValue) => {
  if (!value || !value.amount || !value.currency)
    return baseMessages.FIELD_IS_REQUIRED;
};

export const AmountCurrencyValidator = (value: AmountCurrencyValue) => {
  if (value && (!value.currency || value.currency.ID <= 0)) {
    return baseMessages.CURRENCY_IS_REQUIRED;
  }
};
export const checkboxValidator = (value: any) =>
  !value ? baseMessages.FIELD_IS_REQUIRED : "";

export const rangeAmountsValidator = (value: {
  ITEM_1: number;
  ITEM_2: number;
}) => {
  if (!value) return "";
  if (value.ITEM_1 === 0 && value.ITEM_2 === 0) return "";

  if (value.ITEM_2 < value.ITEM_1) return baseMessages.INVALID_AMOUNT_RANGE;

  return "";
};

export const requiredValidCaptcha = (value: {
  captchaText: string;
  userInput: string;
}) => {
  if (!value || !value.userInput) return baseMessages.FIELD_IS_REQUIRED;
  if (value && value.captchaText !== value.userInput)
    return baseMessages.INVALID_DATA;
};

export const requiredUsernameValidator = (value: string) =>
  !value
    ? baseMessages.USERNAME_REQUIRED
    : usernameRegex.test(value)
      ? ""
      : baseMessages.INVALID_USERNAME;

// Check https://www.telerik.com/kendo-react-ui/components/form/api/FieldValidatorType/
//  export const radioGroupValidator = (value: any, message?: string | undefined) =>
// export const radioGroupValidator = (value: any, message?: string, valueGetter?: () => void, fieldProps?: FieldRenderProps) =>
//     !value ? message? message :"an option must be set" : "";

export const radioGroupValidator = (value: any) =>
  !value ? baseMessages.FIELD_IS_REQUIRED : "";

export const radioGroupValidatorCustomMessage = (
  value: any,
  message: string
) => (!value ? message : "");

const userNameGetter = getter("username");
const emailGetter = getter("email");

export const formValidator = (values: any) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: baseMessages.FILL_IN_THE_FOLLOWING_FIELDS,
    username: !userName ? baseMessages.USERNAME_REQUIRED : "",
    email:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : baseMessages.REQUIRED_EMAIL_FORMAT,
  };
};

const authenticationPasswordGetter = getter("authenticationPassword");
const confirmationPasswordGetter = getter("confirmationPassword");

export const validateFormPasswordMatching = (values: any) => {
  const authenticationPassword: string = authenticationPasswordGetter(values);
  const confirmationPassword: string = confirmationPasswordGetter(values);

  if (
    authenticationPassword &&
    confirmationPassword &&
    // authenticationPassword !== "" &&
    authenticationPassword === confirmationPassword
  ) {
    return { confirmationPassword: "" };
  }
  if (authenticationPassword === "" && confirmationPassword === "") {
    return { confirmationPassword: "FIELD_IS_REQUIRED" };
  }

  return {
    // VALIDATION_SUMMARY: "Invalid Password.",
    // ["user.firstName"]:
    //   "Please check the validation summary for more information.",
    confirmationPassword:
      "CONFIRMATION_PASSWORD_MISMATCH_AUTHENTICATION_PASSWORD",
  };
};
