import { Navigate, Route, Routes } from "react-router-dom";
import baseRoutes from "../../common/base/baseRoutes";
import ProtectedLogin from "../../common/routes/ProtectedLogin";
import { lazy, Suspense } from "react";
import routes from "../../base/routes";
import SuspenseComponent from "../../pages/general/SuspenseComponent";
import portals from "../../base/portals";
import ProtectedRoute from "../../common/routes/ProtectedRoute";

interface Props {}
export default function AppPayerTenantRoutes({}: Props) {
  const ErrorPage = lazy(() => import("../../pages/general/ErrorPage"));
  const PayerAdminPortal = lazy(() => import("../payer/PayerAdminPortal"));
  const Activation = lazy(
    () => import("../../pages/authentication/Activation")
  );
  const LogIn = lazy(() => import("../../pages/authentication/LogIn"));
  return (
    <Routes>
      <Route
        path={baseRoutes.LOGIN}
        element={<ProtectedLogin path={baseRoutes.LOGIN} component={LogIn} />}
      />
      <Route
        path={routes.ACTIVATE_ACCOUNT}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <Activation />
          </Suspense>
        }
      />

      <Route
        path={portals.PAYER_PORTAL + "/*"}
        element={<ProtectedRoute component={PayerAdminPortal} />}
      />
      <Route
        path={baseRoutes.HOME}
        element={<Navigate to={baseRoutes.LOGIN} />}
      />
      <Route
        path={baseRoutes.ERROR_PAGE}
        element={
          <Suspense fallback={<SuspenseComponent />}>
            <ErrorPage />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
      {/** Always keep at the end*/}
    </Routes>
  );
}
