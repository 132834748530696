import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CLIENTS;

const route_client_companies = "/client-companies";
const route_client_person = "/client-person";
const route_client_figures = "/client-figures";
const route_client_company_organization_branches =
  "/client-company-organization-branches";
const route_client_collection_agents = "/client-collection-agents";
const route_client_sales_agents = "/client-sales-agents";
const route_client_corporate_relation_officers =
  "/client-corporate-relation-officers";
const route_client_types = "/client-types";

const act_add_client_company = "/add-client-company";
const act_add_client_person = "/add-client-person";
const act_update = "/update";
const act_set_vip = "/set-vip";
const act_unset_vip = "/unset-vip";
const act_set_bill_generation = "/set-bill-generation";
const act_agents = "/agents";
const act_cancel = "/cancel";
const act_reinstate = "/reinstate";
const route_client_company_organization_branch =
  "/client-organization-branches";

const getClientCompanies = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_client_companies,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClients = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClientTypes = () => {
  return apiClient.get(endpoint + route_client_types);
};

const getClientFigures = () => {
  return apiClient.get(endpoint + route_client_figures);
};

const getClientCompanyOrganizationBranches = (clientCompanyID) => {
  return apiClient.get(
    endpoint +
      "/" +
      clientCompanyID +
      route_client_company_organization_branches
  );
};

const addClientCompany = (data) => {
  return apiClient.post(endpoint + act_add_client_company, data);
};

const addClientPerson = (data) => {
  return apiClient.post(endpoint + act_add_client_person, data);
};

const addClientCompanyOrganizationBranch = (
  clientCompanyID,
  organizationBranchID
) => {
  return apiClient.post(
    endpoint +
      "/" +
      clientCompanyID +
      route_client_company_organization_branches +
      "/" +
      organizationBranchID
  );
};

const setPayerClientBranchCode = (
  clientCompanyID,
  clientCompanyOrganizationBranchID,
  data
) => {
  return apiClient.put(
    endpoint +
      "/" +
      clientCompanyID +
      route_client_company_organization_branches +
      "/" +
      clientCompanyOrganizationBranchID,
    data
  );
};

const removeClientCompanyOrganizationBranch = (
  clientCompanyID,
  clientCompanyOrganizationBranchID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      clientCompanyID +
      route_client_company_organization_branches +
      "/" +
      clientCompanyOrganizationBranchID
  );
};

const updateClientCompany = (clientCompanyID, data) => {
  return apiClient.put(
    endpoint + route_client_companies + "/" + clientCompanyID + act_update,
    data
  );
};

const deleteClientCompany = (clientCompanyID) => {
  return apiClient.delete(
    endpoint + route_client_companies + "/" + clientCompanyID
  );
};

const setClientCompanyAsVIP = (clientCompanyID, data) => {
  return apiClient.put(
    endpoint + route_client_companies + "/" + clientCompanyID + act_set_vip,
    data
  );
};

const unSetClientCompanyVIP = (clientCompanyID) => {
  return apiClient.put(
    endpoint + route_client_companies + "/" + clientCompanyID + act_unset_vip
  );
};

const setClientCompanyBillGeneration = (clientCompanyID, data) => {
  return apiClient.put(
    endpoint +
      route_client_companies +
      "/" +
      clientCompanyID +
      act_set_bill_generation,
    data
  );
};

const getClientRelatedAgents = (data) => {
  return apiClient.post(
    endpoint + act_agents,
    JSON.stringify({
      ...data,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addClientCollectionAgent = (data) => {
  return apiClient.post(endpoint + route_client_collection_agents, data);
};

const updateClientCollectionAgent = (clientCollectionAgentID, data) => {
  return apiClient.put(
    endpoint +
      route_client_collection_agents +
      "/" +
      clientCollectionAgentID +
      act_update,
    data
  );
};

const deleteClientCollectionAgent = (clientCollectionAgentID) => {
  return apiClient.delete(
    endpoint + route_client_collection_agents + "/" + clientCollectionAgentID
  );
};

const cancelClientCollectionAgent = (clientCollectionAgentID, data) => {
  return apiClient.put(
    endpoint +
      route_client_collection_agents +
      "/" +
      clientCollectionAgentID +
      act_cancel,
    data
  );
};

const reinstateClientCollectionAgent = (clientCollectionAgentID) => {
  return apiClient.put(
    endpoint +
      route_client_collection_agents +
      "/" +
      clientCollectionAgentID +
      act_reinstate
  );
};

const addClientSalesAgent = (data) => {
  return apiClient.post(endpoint + route_client_sales_agents, data);
};

const updateClientSalesAgent = (clientSalesAgentID, data) => {
  return apiClient.put(
    endpoint +
      route_client_sales_agents +
      "/" +
      clientSalesAgentID +
      act_update,
    data
  );
};

const deleteClientSalesAgent = (clientSalesAgentID) => {
  return apiClient.delete(
    endpoint + route_client_sales_agents + "/" + clientSalesAgentID
  );
};

const cancelClientSalesAgent = (clientSalesAgentID, data) => {
  return apiClient.put(
    endpoint +
      route_client_sales_agents +
      "/" +
      clientSalesAgentID +
      act_cancel,
    data
  );
};

const reinstateClientSalesAgent = (clientSalesAgentID) => {
  return apiClient.put(
    endpoint +
      route_client_sales_agents +
      "/" +
      clientSalesAgentID +
      act_reinstate
  );
};

const addClientCorporateRelationOfficer = (data) => {
  return apiClient.post(
    endpoint + route_client_corporate_relation_officers,
    data
  );
};

const updateClientCorporateRelationOfficer = (
  clientCorporateRelationOfficerID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_client_corporate_relation_officers +
      "/" +
      clientCorporateRelationOfficerID +
      act_update,
    data
  );
};

const deleteClientCorporateRelationOfficer = (
  clientCorporateRelationOfficerID
) => {
  return apiClient.delete(
    endpoint +
      route_client_corporate_relation_officers +
      "/" +
      clientCorporateRelationOfficerID
  );
};

const cancelClientCorporateRelationOfficer = (
  clientCorporateRelationOfficerID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_client_corporate_relation_officers +
      "/" +
      clientCorporateRelationOfficerID +
      act_cancel,
    data
  );
};

const reinstateClientCorporateRelationOfficer = (
  clientCorporateRelationOfficerID
) => {
  return apiClient.put(
    endpoint +
      route_client_corporate_relation_officers +
      "/" +
      clientCorporateRelationOfficerID +
      act_reinstate
  );
};

const updateClientPerson = (clientPersonID, data) => {
  return apiClient.put(
    endpoint + route_client_person + "/" + clientPersonID + act_update,
    data
  );
};

const deleteClientPerson = (clientPersonID) => {
  return apiClient.delete(
    endpoint + route_client_person + "/" + clientPersonID
  );
};

const setClientPersonAsVIP = (clientPersonID, data) => {
  return apiClient.put(
    endpoint + route_client_person + "/" + clientPersonID + act_set_vip,
    data
  );
};

const unSetClientPersonVIP = (clientPersonID) => {
  return apiClient.put(
    endpoint + route_client_person + "/" + clientPersonID + act_unset_vip
  );
};

const setClientPersonBillGeneration = (clientPersonID, data) => {
  return apiClient.put(
    endpoint +
      route_client_person +
      "/" +
      clientPersonID +
      act_set_bill_generation,
    data
  );
};

const getClientOrganizationBranches = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_client_company_organization_branch,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const clientsApi = {
  getClientCompanies,
  getClients,
  getClientFigures,
  getClientCompanyOrganizationBranches,
  addClientCompany,
  addClientPerson,
  addClientCompanyOrganizationBranch,
  setPayerClientBranchCode,
  removeClientCompanyOrganizationBranch,
  updateClientCompany,
  deleteClientCompany,
  setClientCompanyAsVIP,
  unSetClientCompanyVIP,
  setClientCompanyBillGeneration,
  getClientRelatedAgents,
  addClientCollectionAgent,
  updateClientCollectionAgent,
  deleteClientCollectionAgent,
  cancelClientCollectionAgent,
  reinstateClientCollectionAgent,
  addClientSalesAgent,
  updateClientSalesAgent,
  deleteClientSalesAgent,
  cancelClientSalesAgent,
  reinstateClientSalesAgent,
  addClientCorporateRelationOfficer,
  updateClientCorporateRelationOfficer,
  deleteClientCorporateRelationOfficer,
  cancelClientCorporateRelationOfficer,
  reinstateClientCorporateRelationOfficer,
  updateClientPerson,
  deleteClientPerson,
  setClientPersonAsVIP,
  unSetClientPersonVIP,
  setClientPersonBillGeneration,
  getClientTypes,
  getClientOrganizationBranches,
};

export default clientsApi;
