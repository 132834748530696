import { Dispatch, SetStateAction, useContext } from "react";
import { alertTypes } from "../base/alertTypes";
import AppDialog from "../dialog/AppDialog";
import { DialogProps } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router-dom";
import urlBuilder from "../../helpers/urlBuilder";
import { AuthenticationContext } from "../../context/authenticationContext";
import baseRoutes from "../../base/baseRoutes";
import baseStrings from "../../base/baseStrings";
import { UserPortalContext } from "../../context/userPortalContext";
import uiHelper from "../../helpers/uiHelper";
import useLocalization from "./../../hooks/useLocalization";
import translationNamespaces from "../../enums/locales/translationNamespaces";

interface Props extends DialogProps {
  type?: alertTypes;
  message: string | string[];
  setMessage: Dispatch<SetStateAction<string>>;
  errorType?: "message" | "permission";
}
export default function Alert({
  type,
  message,
  setMessage,
  errorType = "message",
  ...rest
}: Props) {
  const { translate } = useLocalization([
    translationNamespaces.TRANSLATION,
    translationNamespaces.ERROR_CODES,
    translationNamespaces.BASE,
  ]);
  const { user, token, logOut } = useContext(AuthenticationContext);
  const { getUserPortal } = useContext(UserPortalContext);
  const navigate = useNavigate();

  const getErrorMessage = (errorMessage: string) => {
    if (uiHelper.errorCodeHasKey(errorMessage)) {
      const key = uiHelper.getErrorCodeKey(errorMessage);
      const errorCode = uiHelper.getErrorCodeValue(errorMessage);

      return translate(key) + ": " + translate(errorCode);
    } else {
      return translate(errorMessage);
    }
  };
  const getErrorMessageList = (errorMessageList: string[]) => {
    let finalMessage = "";
    errorMessageList.forEach((errorMessage) => {
      if (uiHelper.isNullOrEmptyString(finalMessage))
        finalMessage = errorMessage ? getErrorMessage(errorMessage) : "";
      else
        finalMessage = errorMessage
          ? finalMessage + "\n" + getErrorMessage(errorMessage)
          : "";
    });
    return finalMessage;
  };

  return message ? (
    <AppDialog
      title={
        type === alertTypes.INFO
          ? translate(baseStrings.INFO)
          : translate(baseStrings.ERROR)
      }
      onClose={() => {
        if (errorType === "permission")
          navigate(
            urlBuilder.buildUrl(
              getUserPortal,
              user,
              token,
              baseRoutes.NOT_FOUND
            )
          );
        else if (message === "SESSION_EXPIRED") {
          logOut();
          navigate(baseRoutes.LOGIN);
        } else if (message === "invalid_credentials") {
          logOut();
          navigate(baseRoutes.LOGIN);
        }
        setMessage("");
      }}
      closeIcon={true}
      dialogWidth="small"
      {...rest}
      //className="app-alert"
    >
      <div
        //className="alert-content"
        style={{
          whiteSpace: "break-spaces",
          padding: "10px",
          maxHeight: "50vh",
          // fontSize: "14px",
        }}
      >
        {typeof message !== "string"
          ? getErrorMessageList(message)
          : getErrorMessage(message)}
      </div>
    </AppDialog>
  ) : (
    <></>
  );
}
