import { useContext } from "react";
import { ApplicationContext } from "../../context/applicationContext";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";

export default function AppGridToolbarButton({
  title,
  disabled,
  onClick,
  iconClassName,
  children,
  buttonRef,
  ...rest
}: any) {
  const { iconFactory } = useContext(ApplicationContext);
  const { translate } = useContext(LocalizationContext);
  return (
    <button
      title={translate(title)}
      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
      onClick={onClick}
      disabled={disabled}
      ref={buttonRef}
      {...rest}
    >
      {children}
      {iconClassName && uiHelper.isCustomIcon(iconClassName) ? (
        uiHelper.renderCustomIcon(
          iconFactory,
          iconClassName,
          undefined,
          translate(title)
        )
      ) : (
        <span className={iconClassName} title={translate(title)}></span>
      )}
    </button>
  );
}
