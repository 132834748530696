import { CSSProperties, useContext, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Popover } from "@progress/kendo-react-tooltip";
import { LocalizationContext } from "../../context/localizationContext";
import PillButton from "./PillButton";
import { Breakpoints } from "../../constants/breakpoints";
import { colorCategory } from "../../base/colorCategory";
import breakpointKeys from "../../constants/breakpointKeys";
import useBreakpoint from "../../hooks/useBreakpoint";

interface Props {
  text: string;
  notificationPopupText?: string;
  notificationTitle?: string;
  notificationStatus: colorCategory;
  className?: string;
  icon?: string;
  handleClick?: () => void;
  additionalPillStyle?: CSSProperties;
}
export default function PillNotification({
  text,
  notificationStatus,
  notificationPopupText,
  notificationTitle,
  className,
  icon,
  handleClick,
  additionalPillStyle = {},
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const { breakpoint } = useBreakpoint();
  const [showTitleNotificationPopup, setShowTitleNotificationPopup] =
    useState<boolean>(false);
  const toggleTitleNotificationPopup = () => {
    setShowTitleNotificationPopup((oldValue) => !oldValue);
  };
  const anchor = useRef<HTMLElement>(null);

  const handleOnClick = () => {
    if (handleClick) handleClick();
    else if (
      breakpoint !== breakpointKeys.DESKTOP &&
      breakpoint !== breakpointKeys.HIGH_RES
    )
      toggleTitleNotificationPopup();
  };

  return (
    <Label>
      <span ref={anchor}>
        <PillButton
          pillType={notificationStatus}
          onMouseEnter={toggleTitleNotificationPopup}
          onMouseLeave={toggleTitleNotificationPopup}
          onClick={handleOnClick}
          additionalClasses={className}
          disabled={!(notificationPopupText || handleClick)}
          style={
            notificationPopupText || handleClick
              ? { cursor: "pointer", ...additionalPillStyle }
              : { ...additionalPillStyle }
          }
          icon={icon}
        >
          {translate(text)}
        </PillButton>
      </span>{" "}
      {/* <span
      className={
        titleNotificationStatus === "positive"
          ? "successItem"
          : "errorItem"
      }
      ref={anchor}
      onMouseEnter={toggleTitleNotificationPopup}
      onMouseLeave={toggleTitleNotificationPopup}
    > 
      {translate(titleNotification)}
    </span>*/}
      {notificationPopupText && (
        <Popover
          className="app-pill-notification"
          show={showTitleNotificationPopup}
          anchor={anchor.current && anchor.current}
          position={"bottom"}
          callout={true}
          style={{ maxWidth: "200px" }}
        >
          {notificationTitle && (
            <p className="pill-notification-title">
              {translate(notificationTitle)}
            </p>
          )}
          <span className="pill-notification-text">
            {notificationPopupText}
          </span>
        </Popover>
      )}
    </Label>
  );
}
