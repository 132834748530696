import { ApiResponse } from "apisauce";
import { createContext, ReactNode } from "react";
import UserModel from "../../interfaces/user/UserModel";

interface ContextProps {
  userHasAccessToCommand: (commandName: any) => Promise<ApiResponse<any, any>>;
  getUserPortal: (user: UserModel, token: string) => string;
  isAdminPortal: (user: UserModel, token: string) => boolean;
}

interface Props extends ContextProps {
  children: ReactNode;
}

export const UserPortalContext = createContext<ContextProps>({
  userHasAccessToCommand: (commandName: any) => {
    return new Promise((resolve, reject) => { });
  },
  getUserPortal: (user: UserModel, token: string) => {
    return "";
  },
  isAdminPortal: (user: UserModel, token: string) => { return false; }
});

export default function UserPortalProvider({
  userHasAccessToCommand,
  getUserPortal,
  isAdminPortal,
  children,
}: Props) {
  return (
    <UserPortalContext.Provider
      value={{
        userHasAccessToCommand,
        getUserPortal,
        isAdminPortal
      }}
    >
      {children}
    </UserPortalContext.Provider>
  );
}
