export default Object.freeze({
  APPROVAL_REQUEST_PRESCRIPTION: "APPROVAL_REQUEST_PRESCRIPTION",
  APPROVAL_REQUEST_IDENTITY_CARD: "APPROVAL_REQUEST_IDENTITY_CARD",
  APPROVAL_REQUEST_ATTACHMENT: "APPROVAL_REQUEST_ATTACHMENT",
  APPROVAL_REQUEST_ADDITIONAL: "APPROVAL_REQUEST_ADDITIONAL",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
  SERVICE_PRICE_UPDATE_REQUEST: "SERVICE_PRICE_UPDATE_REQUEST",
  ISSUANCE_ITEM_RESPONSE: "ISSUANCE_ITEM_RESPONSE",
  ISSUANCE_ITEM_RESPONSE_RECEIPT: "ISSUANCE_ITEM_RESPONSE_RECEIPT",
  ISSUANCE_ITEM_REQUEST: "ISSUANCE_ITEM_REQUEST",
  RESULT: "RESULT",
  DIGITAL_PRESCRIPTION: "DIGITAL_PRESCRIPTION",
});
