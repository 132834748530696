import apiClient from "../common/api/client";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import DigitalPrescriptionImageModel from "../interfaces/digitalPrescriptions/DigitalPrescriptionImageModel";
import DigitalPrescriptionModel from "../interfaces/digitalPrescriptions/DigitalPrescriptionModel";
import endpoints from "./endpoints";

const endpoint = endpoints.DIGITAL_PRESCRIPTIONS;
const route_digital_prescription_images = "/digital-Prescription-images";

const act_submit = "/submit";

const getDigitalPrescriptions = (
  criteria: Record<string, any>
): BaseResponse<DigitalPrescriptionModel[]> => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getDigitalPrescription = (
  digitalPrescriptionID: number
): BaseResponse<DigitalPrescriptionModel> => {
  return apiClient.get(endpoint + "/" + digitalPrescriptionID);
};

const getDigitalPrescriptionImages = (
  criteria: Record<string, any>
): BaseResponse<DigitalPrescriptionImageModel[]> => {
  return apiClient.post(
    endpoint + route_digital_prescription_images,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const createDigitalPrescription = (
  data: //DigitalPrescriptionFormData
  any
): BaseResponse<DigitalPrescriptionModel> => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...data,
    })
  );
};

const removeDigitalPrescription = (
  digitalPrescriptionID: number,
  data: Record<string, any>
): BaseResponse<null> => {
  return apiClient.put(
    endpoint + "/" + digitalPrescriptionID,
    JSON.stringify(data)
  );
};

const submitDigitalPrescription = (
  digitalPrescriptionID: number,
  data: any
): BaseResponse<DigitalPrescriptionModel> => {
  return apiClient.put(
    endpoint + "/" + digitalPrescriptionID + act_submit,
    JSON.stringify({
      ...data,
    })
  );
};

const createDigitalPrescriptionImage = (
  digitalPrescriptionID: number,
  data: //DigitalPrescriptionImageFormData
  any
): BaseResponse<DigitalPrescriptionModel> => {
  return apiClient.post(
    endpoint + "/" + digitalPrescriptionID + route_digital_prescription_images,
    JSON.stringify({
      ...data,
    })
  );
};

const removeDigitalPrescriptionImage = (
  digitalPrescriptionID: number,
  digitalPrescriptionImage: number
): BaseResponse<null> => {
  return apiClient.delete(
    endpoint +
      "/" +
      digitalPrescriptionID +
      route_digital_prescription_images +
      "/" +
      digitalPrescriptionImage
  );
};

const digitalPrescriptionsApi = {
  getDigitalPrescriptions,
  getDigitalPrescription,
  getDigitalPrescriptionImages,
  createDigitalPrescription,
  removeDigitalPrescription,
  submitDigitalPrescription,
  createDigitalPrescriptionImage,
  removeDigitalPrescriptionImage,
};

export default digitalPrescriptionsApi;
