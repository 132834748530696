import { CSSProperties, ReactElement, useContext, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";

interface Props {
  skipTranslate?: boolean;
  title: string;
  isLargeTitle?: boolean;
  isLargerTitle?: boolean;
  isExtremeLarge?: boolean;
  allowEdit?: boolean;
  renderEditComponent?: (
    setIsEditMode: (isEditMode: boolean) => void
  ) => ReactElement<any, any>;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  allowUserSelect?: boolean;
  additionalClasses?: string;
}
export default function LabelTitle({
  title,
  skipTranslate = true,
  isLargerTitle = false,
  isLargeTitle = false,
  isExtremeLarge = false,
  allowEdit = false,
  renderEditComponent,
  labelStyle,
  style,
  allowUserSelect,
  additionalClasses,
}: Props) {
  const { translate } = useContext(LocalizationContext);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const enableEditMode = () => {
    allowEdit && setIsEditMode(true);
  };

  const getClassName = () => {
    let className = isExtremeLarge
      ? "extreme-large"
      : isLargeTitle
        ? "title-large"
        : isLargerTitle
          ? "title-larger"
          : "titleItem";

    className = className + " " + additionalClasses;

    if (allowUserSelect) className = className + " allow-user-select";

    return className;
  };

  return !isEditMode ? (
    <span onClick={enableEditMode} style={style || {}}>
      <Label editorId="editorId" style={labelStyle}>
        <span onClick={enableEditMode} className={getClassName()}>
          {skipTranslate ? title : translate(title)}
        </span>
      </Label>
    </span>
  ) : renderEditComponent ? (
    renderEditComponent(setIsEditMode)
  ) : null;
}
