import { StackLayout } from "@progress/kendo-react-layout";

export default function AppActionBar({ children }: any) {
  return (
    <StackLayout
      className="primary-input"
      style={{ flex: 0 }}
      align={{ horizontal: "end", vertical: "middle" }}
    >
      {children}
    </StackLayout>
  );
}
