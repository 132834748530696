import React, { ReactNode, ReactElement, CSSProperties } from "react";
import { StackLayout } from "@progress/kendo-react-layout";
import styleConstants from "../../constants/styleConstants";
import SectionTitle from "./SectionTitle";
import { Breakpoints } from "../../constants/breakpoints";
import breakpointKeys from "../../constants/breakpointKeys";
import useBreakpoint from "../../hooks/useBreakpoint";

interface Props {
  numColumns: number;
  children: ReactNode;
  organized?: boolean;
  title?: string;
  style?: CSSProperties;
  addGroupSeparator?: boolean;
  verticalAlignment?: "top" | "middle" | "bottom" | "stretch";

  numColumnsHighRes?: number;
  numColumnsMobile?: number;
  numColumnsTablet?: number;
  ignoreSeparatorMargin?: boolean;
}

const AppFormSection = ({
  numColumns,
  children,
  organized,
  title,
  style,
  addGroupSeparator,
  verticalAlignment,

  numColumnsHighRes,
  numColumnsMobile,
  numColumnsTablet,
  ignoreSeparatorMargin = false,
}: Props) => {
  const { breakpoint } = useBreakpoint();

  const getNumberOfColumns = () => {
    if (breakpoint === breakpointKeys.HIGH_RES && numColumnsHighRes)
      return numColumnsHighRes;
    if (breakpoint === breakpointKeys.MOBILE && numColumnsMobile)
      return numColumnsMobile;
    if (breakpoint === breakpointKeys.TABLET && numColumnsTablet)
      return numColumnsTablet;
    return numColumns;
  };
  const childrenArray = React.Children.toArray(children) as ReactElement[];

  const renderChildren = () => {
    const columnsNumber = getNumberOfColumns();
    if (columnsNumber === 1 || organized) return children;

    let sumColSpans: number = 0;
    let stackLayoutArray: ReactElement[] = [];
    const returnValue: ReactElement[][] = [];

    let currentChildColSpan: number = 1;

    for (const child of childrenArray) {
      if (child.props.breakBefore) {
        while (sumColSpans !== columnsNumber) {
          stackLayoutArray.push(<div></div>);
          sumColSpans += 1;
        }
        returnValue.push([...stackLayoutArray]);
        stackLayoutArray = [];
        sumColSpans = 0;
      }

      currentChildColSpan = child.props.colSpan ? child.props.colSpan : 1;

      if (currentChildColSpan + sumColSpans <= columnsNumber) {
        stackLayoutArray.push(child);
        sumColSpans += currentChildColSpan;
      } else {
        sumColSpans += currentChildColSpan;
        returnValue.push([...stackLayoutArray]);
        stackLayoutArray = [];
        stackLayoutArray.push(child);
      }

      if (sumColSpans >= columnsNumber || child.props.breakAfter) {
        if (child.props.breakAfter && sumColSpans < columnsNumber) {
          while (sumColSpans !== columnsNumber) {
            stackLayoutArray.push(<div></div>);
            sumColSpans += 1;
          }
        }
        returnValue.push([...stackLayoutArray]);
        stackLayoutArray = [];
        sumColSpans = 0;
      }
    }

    // if we still have space for the last components so we need to add them
    if (stackLayoutArray.length > 0) returnValue.push([...stackLayoutArray]);

    return returnValue.map((stackLayoutArray: ReactElement[], index) => {
      return (
        <StackLayout
          orientation="horizontal"
          gap={styleConstants.GAP_LARGE}
          align={{ vertical: verticalAlignment || "top" }}
          key={index}
        >
          {stackLayoutArray.map((child: ReactElement, childIndex) => (
            <span
              key={index + " - " + childIndex}
              style={{ flex: child.props.colSpan ? child.props.colSpan : 1 }}
            >
              {child}
            </span>
          ))}
        </StackLayout>
      );
    });
  };

  return (
    <StackLayout
      orientation="vertical"
      gap={styleConstants.GAP_LARGE}
      style={style}
    >
      {title && <SectionTitle title={title} />}
      {addGroupSeparator && (
        <SectionTitle title={""} overrideMargin={ignoreSeparatorMargin} />
      )}
      {renderChildren()}
    </StackLayout>
  );
};

export default AppFormSection;
