export default Object.freeze({
  ENABLE_CLAIM_SERVICES_DISPENSE_VIA_DOSAGE:
    "ENABLE_CLAIM_SERVICES_DISPENSE_VIA_DOSAGE",
  ENABLE_CLAIM_SERVICES_DISPENSE_VIA_SUB_PACKS:
    "ENABLE_CLAIM_SERVICES_DISPENSE_VIA_SUB_PACKS",
  MINIMUM_PASSWORD_LENGTH: "MINIMUM_PASSWORD_LENGTH",
  PASSWORD_REGEX: "PASSWORD_REGEX",
  DEFAULT_COUNTRY: "DEFAULT_COUNTRY",
  DEFAULT_CURRENCY: "DEFAULT_CURRENCY",
  SHOW_WITHHELD_PAYMENT: "SHOW_WITHHELD_PAYMENT",
  VALIDATE_TRANSMITTAL_AMOUNT_AGAINST_PROVIDER_REQUESTED_AMOUNT:
    "VALIDATE_TRANSMITTAL_AMOUNT_AGAINST_PROVIDER_REQUESTED_AMOUNT",
  ISSUANCE_ITEM_REQUIRES_FULL_NAME: "ISSUANCE_ITEM_REQUIRES_FULL_NAME",
  SHOW_INSURED_MEDICAL_FILE_IN_PAYER_PORTAL:
    "SHOW_INSURED_MEDICAL_FILE_IN_PAYER_PORTAL",
  PROVIDER_SUBMISSION_VALIDATE_SCANNED_COPIES:
    "PROVIDER_SUBMISSION_VALIDATE_SCANNED_COPIES",
  PROVIDER_BRANCH_CAN_CREATE_SUBMISSION:
    "PROVIDER_BRANCH_CAN_CREATE_SUBMISSION",
  ENFORCE_PVP_GROUP: "ENFORCE_PVP_GROUP",
  REQUIRES_ISSUANCE_ITEM_RESPONSE_ATTACHMENT:
    "REQUIRES_ISSUANCE_ITEM_RESPONSE_ATTACHMENT",
  EXCLUSION_NAME_DEFAULT_LANGUAGE: "EXCLUSION_NAME_DEFAULT_LANGUAGE",
  ASSIGN_CHEQUE_NUMBER_TO_BILL_PAYMENT_RECIPIENT:
    "ASSIGN_CHEQUE_NUMBER_TO_BILL_PAYMENT_RECIPIENT",
  ENFORCE_BILL_PAYMENT_PER_BANK: "ENFORCE_BILL_PAYMENT_PER_BANK",
  ALLOW_CHECK_CHECKUP_ON_CREATE_PROVIDER_PRE_CLAIM_APPROVAL:
    "ALLOW_CHECK_CHECKUP_ON_CREATE_PROVIDER_PRE_CLAIM_APPROVAL",
  PROVIDER_MANAGEMENT_USER_SAME_AS_APPROVALS_USER:
    "PROVIDER_MANAGEMENT_USER_SAME_AS_APPROVALS_USER",
  DEFAULT_CHRONIC_FORM_CURRENCY_ID: "DEFAULT_CHRONIC_FORM_CURRENCY_ID",
  DEFAULT_SYSTEM_DECIMAL_ROUNDING: "DEFAULT_SYSTEM_DECIMAL_ROUNDING",
  GENERAL_DEFAULT_LANGUAGE: "GENERAL_DEFAULT_LANGUAGE",
  ENABLE_DIGITAL_PRESCRIPTION: "ENABLE_DIGITAL_PRESCRIPTION",
  DIGITAL_PRESCRIPTION_MESSAGE_DEFAULT_LANGUAGE:
    "DIGITAL_PRESCRIPTION_MESSAGE_DEFAULT_LANGUAGE",
  DIGITAL_PRESCRIPTION_RANGE_IN_DAYS: "DIGITAL_PRESCRIPTION_RANGE_IN_DAYS",
});
