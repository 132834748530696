export default Object.freeze({
  APPROVAL: "APPROVAL",
  ISSUANCE_ITEM: "ISSUANCE_ITEM",
  APPROVAL_REQUEST: "APPROVAL_REQUEST",
  REIMBURSEMENT_REQUEST: "REIMBURSEMENT_REQUEST",
  POLICY: "POLICY",
  PRESCRIPTION: "PRESCRIPTION",
  PROVIDER_TRANSMITTAL: "PROVIDER_TRANSMITTAL",
  CLAIM: "CLAIM",
  TRANSMITTALS: "TRANSMITTALS",
  APPLICATION: "APPLICATION",
  SERVICE: "SERVICE",
  PROVIDER_TRANSMITTAL_APPROVAL: "PROVIDER_TRANSMITTAL_APPROVAL",
  CHRONIC: "CHRONIC",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
  PROVIDER: "PROVIDER",
  ACCOUNTING: "ACCOUNTING",
  FILE_REVIEW: "FILE_REVIEW",
  CHRONICFORM: "CHRONICFORM",
  DIGITAL_PRESCRIPTION: "DIGITAL_PRESCRIPTION",
});
