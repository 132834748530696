import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.PROVIDERS;
const act_provider_types = "provider-types";
const act_provider_info = "/provider-info";
const route_provider_types = "/provider-types";
const route_provider_specialties = "/provider-specialties";
const route_update_provider_info = "/update-provider-info";
const route_update_provider_contact_info = "/update-provider-contact-info";
const route_update_provider_full_contact_info =
  "/update-provider-full-contact-info";
const route_provider_working_hour = "/provider-working-hour";
const route_provider_type_configuration = "/provider-type-configuration";
// const act_getProviders = "getProviders";
const route_create_new_provider = "/create-new-provider";

const route_download_provider_information_verification_report =
  "/download-provider-information-verification-report";

const route_full_provider = "/full-provider";
const route_provider_contacts = "/provider-contacts";
const rout_provider_user = "/provider-user";
const rout_provider_contracts = "/provider-contracts";
const rout_inHouse_provider_configurations = "/inHouse-provider-configurations";
const rout_provider_contract_pricing_scheme =
  "/provider-contract-pricing-scheme";
const rout_provider_contract_operations_pricing_scheme =
  "/provider-contract-operations-pricing-scheme";
const rout_provider_contract_service_discount_scheme =
  "/provider-contract-service-discount-scheme";
const rout_facility_providers = "/facility-providers";
const rout_provider_contract_operation_category_scheme =
  "/provider-contract-operation-category-scheme";
const route_provider_contract_tpa_discounts =
  "/provider-contract-tpa-discounts";
const route_provider_contract_tpa_discount_details =
  "/provider-contract-tpa-discount-details";
const rout_provider_branches = "/provider-branches";
const rout_provider_contract_early_payments =
  "/provider-contract-early-payments";
const rout_provider_contract_rebate_schemes =
  "/provider-contract-rebate-schemes";
const rout_provider_contract_taxes = "/provider-contract-taxes";
const rout_provider_contract_unit_types = "/provider-contract-unit-types";
const route_update_provider_type_Info = "/update-provider-type-info";
const route_set_default_treatment_doctor = "/set-default-treatment-doctor";
const route_un_set_default_treatment_doctor = "/unset-default-treatment-doctor";
const route_remove_from_pre_authorization_network =
  "/remove-from-preauthorization-network";
const route_add_to_pre_authorization_network =
  "/add-to-preauthorization-network";
const delete_provider = "/delete-provider";
const cancel_provider = "/cancel-provider";
const publish_provider = "/publish-provider";
const un_publish_provider = "/unpublish-provider";
const reinstate_provider = "/reinstate-provider";
const update_provider_main_branch = "/update-provider-main-branch";
const update_provider_address = "/update-provider-address";
const set_provider_vip = "/set-provider-vip";
const unset_provider_vip = "/unset-provider-vip";
const authorize_and_publish = "/authorize-and-publish";
const reset_provider_authentication = "/reset-provider-authentication";
const verify_provider = "/verify-provider";
const unverify_provider = "/unverify-provider";
const set_default_consultation_service = "/set-default-consultation-service";
const unset_default_consultation_service =
  "/unset-default-consultation-service";
const set_provider_as_policy_holder = "/set-provider-as-policy-holder";
const unset_provider_as_policy_holder = "/unset-provider-as-policy-holder";
const set_provider_as_insured = "/set-provider-as-insured";
const unset_provider_as_insured = "/unset-provider-as-insured";
const set_provider_supports_special_discounts =
  "/set-provider-supports-special-discounts";
const update_provider_name = "/update-provider-name";
const update_provider_user_full_name = "/set-provider-user-full-name";
const update_provider_license_info = "/update-provider-license-info";
const update_provider_in_house_info = "/update-provider-in-house-info";
const add_facility_provider = "/add-facility-provider";
const delete_facility_provider = "/delete-facility-provider";
const remove_facility_provider = "/remove-facility-provider";
const reinstate_facility_provider = "/reinstate-facility-provider";
const add_provider_contact = "/add-provider-contact";
const update_provider_contact = "/update-provider-contact";
const delete_provider_contact = "/delete-provider-contact";
const route_update_provider_default_currency =
  "/update-provider-default-currency";
const route_update_provider_language = "/update-provider-language";
const route_set_provider_appointment_enabled =
  "/set-provider-appointment-enabled";
const route_set_provider_deliver_drugs = "/set-provider-deliver-drugs";
const route_in_preffered_network = "/in-preffered-network";
const route_allow_chronic_dispensation_in_future =
  "/allow-chronic-dispensation-in-future";
const route_set_provider_second_opinion_referral =
  "/set-provider-second-opinion-referral";
const route_set_provider_virtual = "/set-provider-virtual";
const route_set_provider_requires_external_request =
  "/set-provider-requires-external-request";
const route_set_provider_disable_third_party_portal =
  "/set-provider-disable-third-party-portal";

const route_provider_contract = "/provider-contract";
const route_update_provider_contract = "/update-provider-contract";
const route_cancel_provider_contract = "/cancel-provider-contract";
const route_active_provider_contract = "/active-provider-contract";
const route_provider_contract_pricing_scheme =
  "/provider-contract-pricing-scheme";
const route_update_provider_contract_pricing_scheme =
  "/update-provider-contract-pricing-scheme";
const route_cancel_provider_contract_pricing_scheme =
  "/cancel-provider-contract-pricing-scheme";
const route_reinstate_provider_contract_pricing_scheme =
  "/reinstate-provider-contract-pricing-scheme";

const route_reinstate_provider_contract_operations_pricing_scheme =
  "/reinstate-provider-contract-operations-pricing-scheme";
const route_cancel_provider_contract_operations_pricing_scheme =
  "/cancel-provider-contract-operations-pricing-scheme";
const route_update_provider_contract_operations_pricing_scheme =
  "/update-provider-contract-operations-pricing-scheme";
const route_provider_contract_operations_pricing_scheme =
  "/provider-contract-operations-pricing-scheme";

const route_provider_contract_service_discount_scheme =
  "/provider-contract-service-discount-scheme";
const route_update_provider_contract_service_discount_scheme =
  "/update-provider-contract-service-discount-scheme";
const route_cancel_provider_contract_service_discount_scheme =
  "/cancel-provider-contract-service-discount-scheme/";
const route_reinstate_provider_contract_service_discount_scheme =
  "/reinstate-provider-contract-service-discount-scheme";

const route_provider_contract_operation_category_scheme =
  "/provider-contract-operation-category-scheme";
const route_update_provider_contract_operation_category_scheme =
  "/update-provider-contract-operation-category-scheme";
const route_cancel_provider_contract_operation_category_scheme =
  "/cancel-provider-contract-operation-category-scheme/";
const route_reinstate_provider_contract_operation_category_scheme =
  "/reinstate-provider-contract-operation-category-scheme";

const route_provider_contract_rebate_scheme =
  "/provider-contract-rebate-scheme";
const route_update_provider_contract_rebate_scheme =
  "/update-provider-contract-rebate-scheme";
const route_cancel_provider_contract_rebate_scheme =
  "/cancel-provider-contract-rebate-scheme";
const route_reinstate_provider_contract_rebate_scheme =
  "/reinstate-provider-contract-rebate-scheme";

const route_provider_contract_tax = "/provider-contract-tax";
const route_update_provider_contract_tax = "/update-provider-contract-tax";
const route_cancel_provider_contract_tax = "/cancel-provider-contract-tax";
const route_reinstate_provider_contract_tax =
  "/reinstate-provider-contract-tax";

const route_provider_contract_tax_exemption =
  "/provider-contract-tax-exemption";

const route_provider_contract_unit_type = "/provider-contract-unit-type";
const route_update_provider_contract_unit_type =
  "/update-provider-contract-unit-type";
const route_cancel_provider_contract_unit_type =
  "/cancel-provider-contract-unit-type";
const route_reinstate_provider_contract_unit_type =
  "/reinstate-provider-contract-unit-type";

const route_provider_contract_unit_type_mapping =
  "/provider-contract-unit-type-mapping";
const route_update_provider_contract_unit_type_mapping =
  "/update-provider-contract-unit-type-mapping";
const route_cancel_provider_contract_unit_type_mapping =
  "/cancel-provider-contract-unit-type-mapping";
const route_reinstate_provider_contract_unit_type_mapping =
  "/reinstate-provider-contract-unit-type-mapping";

const route_in_house_provider_configuration =
  "/in-house-provider-configuration";
const route_update_in_house_provider_configuration =
  "/update-in-house-provider-configuration";
const route_cancel_in_house_provider_configuration =
  "/cancel-in-house-provider-configuration";
const route_reinstate_in_house_provider_configuration =
  "/reinstate-in-house-provider-configuration";

const route_provider_contract_early_payment =
  "/provider-contract-early-payment";
const route_update_provider_contract_early_payment =
  "/update-provider-contract-early-payment";
const route_cancel_provider_contract_early_payment =
  "/cancel-provider-contract-early-payment";
const route_reinstate_provider_contract_early_payment =
  "/reinstate-provider-contract-early-payment";

const route_provider_contract_early_payment_detail =
  "/provider-contract-early-payment-detail";
const route_provider_contract_early_payment_details =
  "/provider-contract-early-payment-details";

const route_provider_groups = "/provider-groups";
const route_provider_contract_tpa_discount = "/provider-contract-tpa-discount";
const route_update_provider_contract_tpa_discount =
  "/update-provider-contract-tpa-discount";
const route_cancel_provider_contract_tpa_discount =
  "/cancel-provider-contract-tpa-discount";
const route_reinstate_provider_contract_tpa_discount =
  "/reinstate-provider-contract-tpa-discount";
const route_provider_contract_tpa_discount_detail =
  "/provider-contract-tpa-discount-detail";

const route_set_provider_follows_main_branch_contract =
  "/set-provider-follows-main-branch-contract";

const route_provider_group = "/provider-group";
const route_provider_group_members = "/provider-group-members";
const route_provider_group_member = "/provider-group-member";
const route_import_provider_group_members = "/import-provider-group-members";
const route_provider_group_member_workspace =
  "/provider-group-member-workspace";
const route_workspaces = "/workspaces";
const route_check_duplicates = "/check-duplicates";
const route_workspace = "/workspace";
const route_resolve_duplicate = "/resolve-duplicate";
const route_commit_workspace = "/commit-workspace";
const act_provider_supervision = "/provider-supervision";
const route_download_medical_network_active_members_report =
  "/download-medical-network-active-members";
const route_provider_specialty_details = "/provider-specialty-details";
const route_provider_classification_details =
  "/provider-classification-details";

const route_add_provider_specialty_detail = "/add-provider-specialty-detail";
const route_update_provider_specialty_detail =
  "/update-provider-specialty-detail";
const route_delete_provider_specialty_detail =
  "/delete-provider-specialty-detail";
const route_active_provider_specialty_detail =
  "/active-provider-specialty-detail";

const route_add_provider_classification_detail =
  "/add-provider-classification-detail";
const route_update_provider_classification_detail =
  "/update-provider-classification-detail";
const route_delete_provider_classification_detail =
  "/delete-provider-classification-detail";

const route_provider_attachments = "/provider-attachments";
const route_provider_attachment = "/provider-attachment";
const route_update_provider_attachment = "/update-provider-attachment";
const route_delete_provider_attachment = "/delete-provider-attachment";

const route_provider_service_mappings = "/provider-service-mappings";
const route_add_provider_service_mapping = "/add-provider-service-mapping";
const route_update_provider_service_mapping =
  "/update-provider-service-mapping";
const route_set_provider_follows_main_provider_service_mapping =
  "/set-provider-follows-main-provider-service-mapping";
const route_delete_provider_service_mapping =
  "/delete-provider-service-mapping";

const route_provider_service_mapping_workspaces =
  "/provider-service-mapping-workspaces";
const route_provider_service_mapping_workspace =
  "/provider-service-mapping-workspace";
const route_workspace_summary = "/workspace-summary";
const act_download_provider_service_mapping_workspace_report =
  "/download-provider-service-mapping-workspace-report";
const act_import_provider_service_mapping_workspace_drafts =
  "/import-provider-service-mapping-workspace-drafts";
const act_check_duplicate = "/check-duplicate";
const act_resolve_duplicate = "/resolve-duplicate";
const act_check_matches = "/check-matches";
const act_resolve_draft_match = "/resolve-draft-match";
const act_resolve_all_draft_matches = "/resolve-all-draft-matches";
const act_commit = "/commit";
const route_export_provider_service_mappings =
  "/export-provider-service-mappings";

const route_provider_type_list = "/provider-type-list";
const route_provider_type = "/provider-type";
const route_update_provider_type = "/update-provider-type";
const route_delete_provider_type = "/delete-provider-type";
const route_provider_type_claim_types = "/provider-type-claim-types";
const route_insurance_company_provider_type_codes =
  "/insurance-company-provider-type-codes";
const route_provider_type_claim_type = "/provider-type-claim-type";
const route_update_provider_type_claim_type =
  "/update-provider-type-claim-type";
const route_delete_provider_type_claim_type =
  "/delete-provider-type-claim-type";
const route_set_provider_type_claim_type_default_claim =
  "/set-provider-type-claim-type-default-claim";

const route_insurance_company_provider_type_code =
  "/insurance-company-provider-type-code";
const route_update_insurance_company_provider_type_code =
  "/update-insurance-company-provider-type-code";
const route_delete_insurance_company_provider_type_code =
  "/delete-insurance-company-provider-type-code";

const route_provider_classification_list = "/provider-classification-list";
const route_provider_classification = "/provider-classification";
const route_delete_provider_classification = "/delete-provider-classification";

const route_provider_specialty_list = "/provider-specialty-list";
const route_provider_specialty = "/provider-specialty";

const route_specialty_services = "/specialty-services";
const route_specialty_service = "/specialty-service";
const route_import_specialty_services = "/import-specialty-services";

const route_specialty_diagnoses = "/specialty-diagnoses";
const route_specialty_diagnosis = "/specialty-diagnosis";
const act_set_specialty_diagnosis_default = "/set-specialty-diagnosis-default";
const act_set_primary_specialty_diagnosis = "/set-primary-specialty-diagnosis";

const route_provider_category_list = "/provider-category-list";
const route_provider_category = "/provider-category";

const route_provider_group_classification_list =
  "/provider-group-classification-list";
const route_provider_group_classification = "/provider-group-classification";

const route_provider_request_assignment_rule_list =
  "/provider-request-assignment-rule-list";
const route_provider_request_assignment_rule =
  "/provider-request-assignment-rule";

const route_default_provider_contract_settings =
  "/default-provider-contract-settings";
const route_default_provider_contract_setting =
  "/default-provider-contract-setting";

const route_remove_duplicate_providers = "/remove-duplicate-providers";

const route_provider_claim_types = "/provider-cliam-types";
const route_provider_claim_type = "/provider-cliam-type";

const route_provider_offered_services = "/provider-offered-services";
const route_provider_offered_service = "/provider-offered-service";

const route_provider_web_requests = "/provider-web-requests";
const route_provider_web_request = "/provider-web-request";

const route_checkin = "/checkin";
const route_checkout = "/checkout";
const route_force_checkin = "/force-checkin";

const route_set_comment = "/set-comment";
const route_reject = "/reject";
const route_accept = "/accept";

const route_provider_bank_accounts = "/provider-bank_accounts";
const route_provider_direct_payment_recipients =
  "/provider-direct-payment-recipients";

const route_provider_bank_account = "/provider-bank_account";
const route_verify_provider_bank_account = "/verify-provider-bank_account";
const route_unverify_provider_bank_account = "/unverify-provider-bank_account";
const route_activate_provider_bank_account = "/activate-provider-bank_account";
const route_deactivate_provider_bank_account =
  "/deactivate-provider-bank_account";

const route_freeze_provider_bank_account = "/freeze-provider-bank_account";
const route_unfreeze_provider_bank_account = "/unfreeze-provider-bank_account";

const route_provider_direct_payment_recipient =
  "/provider-direct-payment-recipient";
const route_activate_provider_direct_payment_recipient =
  "/activate-provider-direct-payment-recipient";
const route_deactivate_provider_direct_payment_recipient =
  "/deactivate-provider-direct-payment-recipient";

const route_set_payment_method = "/set-payment-method";

const route_follows_main_Branch_payment_settings =
  "/follows-main-Branch-payment-settings";

const route_download_provider_information_report =
  "/download-provider-information-report";

const route_download_provider_contracts_report =
  "/download-provider-contracts-report";
const route_download_provider_contract_early_payments_report =
  "/download-provider-contract-early-payments-report";
const route_download_provider_contract_taxes_report =
  "/download-provider-contract-taxes-report";
const route_download_provider_contract_tpa_discounts_report =
  "/download-provider-contract-tpa-discounts-report";
const route_download_provider_contract_tax_exemptions_report =
  "/download-provider-contract-tax-exemptions-report";
const route_download_in_house_provider_compensations_report =
  "/download-in-house-provider-compensations-report";
const route_download_in_house_provider_configurations_report =
  "/download-in-house-provider-configurations-report";
const route_download_in_house_provider_online_activation_report =
  "/download-provider-online-activation-report";
const route_download_medical_network_provider_group_report =
  "/download-medical-network-provider-group-report";
const route_download_provider_supervisions_report =
  "/download-provider-supervisions-report";
const route_download_medical_network_report =
  "/download-medical-network-report";
const route_download_provider_with_frozen_bank_accounts_report =
  "/download-provider-with-frozen-bank-accounts-report";
const route_download_providers_payment_information_report =
  "/download-providers-payment-information-report";
const route_download_provider_statement_report =
  "/download-provider-statement-report";
const route_download_provider_comments_report =
  "/download-provider-comments-report";
const route_download_facility_providers_report =
  "/download-facility-providers-report";
const route_download_provider_group_report = "/download-provider-group-members";
const route_download_medical_network_active_members =
  "/download-medical-network-active-members";

const route_provider_communications = "/provider-communications";
const route_provider_communication = "/provider-communication";
const route_provider_message_templates = "/provider-message-templates";
const route_add_provider_message = "/add-provider-message";

const route_provider_service_pricings = "/provider-service-pricings";
const act_delete = "/delete";
const act_send_activation_sms = "/send-activation-sms";
const act_set_as_management = "/set-as-management";
const act_create_management_user = "/create-management-user";
const act_create_provider_web_react = "/create-provider-web-request";
const act_medical_network_providers = "/medical-network-providers";
const act_unset_as_management = "/unset-as-management";
const act_download_provider_prices_and_discounts_report =
  "/download-provider-prices-and-discounts-report";
const route_provider_comment = "/provider-comment";
const act_provider_comments = "/provider-comments";
const act_add_provider_comment = "/add-provider-comment";
const act_update_provider_comment = "/update-provider-comment";
const act_set_provider_under_supervision = "/set-provider-under-supervision";
const act_remove_provider_supervision = "/remove-provider-supervision";
const act_update_provider_supervision = "/update-provider-supervision";
const route_provider_supervision = "/provider-supervision";
const act_update_provider_code = "/update-provider-code";
const act_download_provider_group_member_workspace_report =
  "/download-provider-group-member-workspace-report";
const act_import_facility_providers = "/import-facility-providers";
const route_set_provider_enable_user_management =
  "/set-provider-enable-user-management";

const act_set_allow_issuing_digital_prescription =
  "/set-allow-issuing-digital-prescription";

const getProviderTypes = () => {
  return apiClient.get(endpoint + "/" + act_provider_types);
};

// const getProviders= (criteria) => {
//   return apiClient.get(endpoint ,//+ "/" + act_getProviders,
//     // JSON.stringify(criteria),
//     {params:{ProviderName: "Ali"}},
//   );
// };

const getProviders = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const downloadProviderInformationVerificationReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_information_verification_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderInfo = (providerID) =>
  apiClient.get(endpoint + act_provider_info + "/" + providerID);

const getProviderTypeSpecialties = (providerTypeID) =>
  apiClient.get(
    endpoint +
      route_provider_types +
      "/" +
      providerTypeID +
      route_provider_specialties
  );

const updateProviderInfo = (updateProviderInfoRequest) => {
  return apiClient.post(
    endpoint + route_update_provider_info,
    JSON.stringify(updateProviderInfoRequest)
  );
};

const updateProviderContactInfo = (contactInfo) => {
  return apiClient.put(
    endpoint + route_update_provider_contact_info,
    JSON.stringify(contactInfo)
  );
};

const addProviderWorkingHour = (providerID, workingHour) =>
  apiClient.post(
    endpoint + "/" + providerID + route_provider_working_hour,
    JSON.stringify(workingHour)
  );

const updateProviderWorkingHour = (providerWorkingHourID, criteria) =>
  apiClient.put(
    endpoint + route_provider_working_hour + "/" + providerWorkingHourID,
    JSON.stringify(criteria)
  );

const deleteProviderWorkingHour = (providerWorkingHourID) =>
  apiClient.delete(
    endpoint + route_provider_working_hour + "/" + providerWorkingHourID
  );

const getProviderTypeConfiguration = (providerTypeID) =>
  apiClient.get(
    endpoint + "/" + providerTypeID + route_provider_type_configuration
  );

const createNewProvider = (provider) => {
  return apiClient.post(
    endpoint + route_create_new_provider,
    JSON.stringify({ ...provider })
  );
};

const getFullProvider = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + route_full_provider);
};

const getProviderContacts = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + route_provider_contacts);
};

const getProviderUser = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + rout_provider_user);
};

const getProviderContracts = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + rout_provider_contracts);
};

const getInHouseProviderConfigurations = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_inHouse_provider_configurations
  );
};

const getFacilityProviders = (criteria) => {
  return apiClient.post(
    endpoint + rout_facility_providers,
    JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getProviderContractPricingSchemes = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_provider_contract_pricing_scheme
  );
};

const getProviderContractOperationsPricingSchemes = (providerContractID) => {
  return apiClient.get(
    endpoint +
      "/" +
      providerContractID +
      rout_provider_contract_operations_pricing_scheme
  );
};

const getProviderContractServiceDiscountSchemes = (providerContractID) => {
  return apiClient.get(
    endpoint +
      "/" +
      providerContractID +
      rout_provider_contract_service_discount_scheme
  );
};

const getProviderContractOperationCategorySchemes = (providerContractID) => {
  return apiClient.get(
    endpoint +
      "/" +
      providerContractID +
      rout_provider_contract_operation_category_scheme
  );
};

const getProviderContractTPADiscounts = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + route_provider_contract_tpa_discounts
  );
};

const getProviderContractTPADiscountDetails = (
  providerContractTPADiscountID
) => {
  return apiClient.get(
    endpoint +
      route_provider_contract_tpa_discount +
      "/" +
      providerContractTPADiscountID +
      route_provider_contract_tpa_discount_details
  );
};

const getProviderBranches = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + rout_provider_branches);
};

const getProviderContractEarlyPayments = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_provider_contract_early_payments
  );
};

const getProviderContractEarlyPaymentDetails = (
  providerContractAdvancePaymentID
) => {
  return apiClient.get(
    endpoint +
      route_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_early_payment_details
  );
};

const getProviderContractRebateSchemes = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_provider_contract_rebate_schemes
  );
};

const getProviderContractTaxes = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_provider_contract_taxes
  );
};

const getProviderContractUnitTypes = (providerContractID) => {
  return apiClient.get(
    endpoint + "/" + providerContractID + rout_provider_contract_unit_types
  );
};

const updateProviderTypeInfo = (criteria) => {
  return apiClient.put(
    endpoint + route_update_provider_type_Info,
    JSON.stringify({ ...criteria })
  );
};

const setDefaultTreatmentDoctor = (providerID, treatmentDoctorID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_default_treatment_doctor +
      "/" +
      treatmentDoctorID
  );
};

const unSetDefaultTreatmentDoctor = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + route_un_set_default_treatment_doctor
  );
};

const addProviderToPreAuthorizationNetwork = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + route_add_to_pre_authorization_network
  );
};

const removeProviderFromPreAuthorizationNetwork = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + route_remove_from_pre_authorization_network
  );
};

const deleteProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + delete_provider);
};

const cancelProvider = (providerID, reason) => {
  return apiClient.put(
    endpoint + "/" + providerID + cancel_provider,
    JSON.stringify({ Comment: reason })
  );
};

const publishProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + publish_provider);
};

const unPublishProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + un_publish_provider);
};

const reinstateProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + reinstate_provider);
};

const updateProviderMainBranch = (providerID, IsBranch, MainBranchID) => {
  return apiClient.put(
    endpoint + "/" + providerID + update_provider_main_branch,
    JSON.stringify({ IsBranch, MainBranchID })
  );
};

const updateProviderAddress = (
  providerID,
  CountryID,
  CityID,
  LocationID,
  Address,
  GeographicLatitude,
  GeographicLongitude
) => {
  return apiClient.put(
    endpoint + "/" + providerID + update_provider_address,
    JSON.stringify({
      CountryID,
      CityID,
      LocationID,
      Address,
      GeographicLatitude,
      GeographicLongitude,
    })
  );
};

const setProviderAsVIP = (providerID, CommentID, VIPReason) => {
  return apiClient.put(
    endpoint + "/" + providerID + set_provider_vip,
    JSON.stringify({
      CommentID,
      AdditionalInfo: VIPReason,
    })
  );
};

const unSetProviderVIP = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + unset_provider_vip);
};

const authorizeAndPublish = (providerID, criteria) => {
  return apiClient.put(
    endpoint + "/" + providerID + authorize_and_publish,
    JSON.stringify({ ...criteria })
  );
};

const resetProviderAuthenticationAndNotify = (providerID, providerUserID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      rout_provider_user +
      "/" +
      providerUserID +
      reset_provider_authentication
  );
};

const verifyProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + verify_provider);
};

const unVerifyProvider = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + unverify_provider);
};

const setDefaultConsultationService = (providerID, treatmentDoctorID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      set_default_consultation_service +
      "/" +
      treatmentDoctorID
  );
};

const unsetDefaultConsultationService = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + unset_default_consultation_service
  );
};

const setProviderAsPolicyHolder = (providerID, policyHeaderID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      set_provider_as_policy_holder +
      "/" +
      policyHeaderID
  );
};

const unsetProviderAsPolicyHolder = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + unset_provider_as_policy_holder
  );
};

const setProviderAsInsured = (providerID, insuredPersonInformationID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      set_provider_as_insured +
      "/" +
      insuredPersonInformationID
  );
};

const unsetProviderAsInsured = (providerID) => {
  return apiClient.put(endpoint + "/" + providerID + unset_provider_as_insured);
};

const setProviderSupportSpecialDiscounts = (providerID, supportsDiscounts) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      set_provider_supports_special_discounts +
      "/" +
      supportsDiscounts
  );
};

const updateProviderName = (providerID, NameForeign, NameLocal) => {
  return apiClient.put(
    endpoint + "/" + providerID + update_provider_name,
    JSON.stringify({
      NameForeign,
      NameLocal,
    })
  );
};

const updateProviderUserFullName = (
  providerID,
  providerUserID,
  NameForeign,
  NameLocal
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      update_provider_user_full_name +
      "/" +
      providerUserID,
    JSON.stringify({
      NewUserFirstName: NameForeign,
      NewUserLastName: NameLocal,
    })
  );
};

const updateProviderLicenseInfo = (
  providerID,
  LicenseCode,
  LicenseDate,
  ForeignDoctorName,
  LocalDoctorName,
  IdentityNumber
) => {
  return apiClient.put(
    endpoint + "/" + providerID + update_provider_license_info,
    JSON.stringify({
      LicenseCode,
      LicenseDate,
      ForeignDoctorName,
      LocalDoctorName,
      IdentityNumber,
    })
  );
};

const updateProviderFullContactInfo = (providerID, contactInfo) => {
  return apiClient.put(
    endpoint + "/" + providerID + route_update_provider_full_contact_info,
    JSON.stringify(contactInfo)
  );
};

const updateProviderInHouseInfo = (providerID, inHouseInfo) => {
  return apiClient.put(
    endpoint + "/" + providerID + update_provider_in_house_info,
    JSON.stringify(inHouseInfo)
  );
};
const addFacilityProvider = (providerID, facilityProviderInfo) => {
  return apiClient.put(
    endpoint + "/" + providerID + add_facility_provider,
    JSON.stringify(facilityProviderInfo)
  );
};

const deleteFacilityProvider = (providerID, facilityProviderID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      delete_facility_provider +
      "/" +
      facilityProviderID
  );
};

const removeFacilityProvider = (providerID, facilityProviderID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      remove_facility_provider +
      "/" +
      facilityProviderID
  );
};

const reinstateFacilityProvider = (providerID, facilityProviderID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      reinstate_facility_provider +
      "/" +
      facilityProviderID
  );
};

const addProviderContact = (providerID, contactInfo) => {
  return apiClient.put(
    endpoint + "/" + providerID + add_provider_contact,
    JSON.stringify(contactInfo)
  );
};

const updateProviderContact = (providerContactID, contactID, contactInfo) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerContactID +
      update_provider_contact +
      "/" +
      contactID,
    JSON.stringify(contactInfo)
  );
};

const deleteProviderContact = (providerID, providerContactID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      delete_provider_contact +
      "/" +
      providerContactID
  );
};

const updateProviderDefaultCurrency = (providerID, currencyID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_default_currency +
      "/" +
      currencyID
  );
};

const updateProviderLanguage = (providerID, languageID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_language +
      "/" +
      languageID
  );
};

const setProviderAppointmentEnabled = (providerID, appointmentEnabled) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_appointment_enabled +
      "/" +
      appointmentEnabled
  );
};

const setProviderDeliverDrugs = (providerID, deliverDrugs) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_deliver_drugs +
      "/" +
      deliverDrugs
  );
};

const setProviderInPrefferedNetwork = (providerID, inPrefferedNetwork) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_in_preffered_network +
      "/" +
      inPrefferedNetwork
  );
};

const setProviderAllowChronicDispensationInFuture = (
  providerID,
  allowChronicDispensationInFuture
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_allow_chronic_dispensation_in_future +
      "/" +
      allowChronicDispensationInFuture
  );
};

const setProviderRequiresExternalRequestID = (providerID, deliverDrugs) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_requires_external_request +
      "/" +
      deliverDrugs
  );
};

const setProviderDisableThirdPartyPortal = (providerID, deliverDrugs) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_disable_third_party_portal +
      "/" +
      deliverDrugs
  );
};

const setProviderSecondOpinionReferral = (
  providerID,
  secondOpinionReferral
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_second_opinion_referral +
      "/" +
      secondOpinionReferral
  );
};

const setProviderVirtual = (providerID, isVirtual) => {
  return apiClient.put(
    endpoint + "/" + providerID + route_set_provider_virtual + "/" + isVirtual
  );
};

const addProviderContractPricingScheme = (
  providerID,
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_pricing_scheme,
    JSON.stringify({ ...criteria })
  );
};
const updateProviderContractPricingScheme = (
  providerID,
  providerContractID,
  providerContractPricingSchemeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_pricing_scheme,
    JSON.stringify({
      ProviderContractPricingSchemeID: providerContractPricingSchemeID,
      ...criteria,
    })
  );
};

const cancelProviderContractPricingScheme = (
  providerID,
  providerContractID,
  providerContractPricingSchemeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_pricing_scheme +
      "/" +
      providerContractPricingSchemeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractPricingScheme = (
  providerID,
  providerContractID,
  providerContractPricingSchemeID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_pricing_scheme +
      "/" +
      providerContractPricingSchemeID
  );
};

const removeProviderContractPricingScheme = (
  providerID,
  providerContractID,
  providerContractPricingSchemeID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_pricing_scheme +
      "/" +
      providerContractPricingSchemeID
  );
};

const addProviderContractOperationsPricingScheme = (
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_operations_pricing_scheme,
    JSON.stringify({ ...criteria })
  );
};
const updateProviderContractOperationsPricingScheme = (
  providerContractID,
  providerContractOperationsPricingSchemeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_operations_pricing_scheme +
      "/",
    JSON.stringify({
      ProviderContractOperationsPricingSchemeID:
        providerContractOperationsPricingSchemeID,
      ...criteria,
    })
  );
};

const cancelProviderContractOperationsPricingScheme = (
  providerContractID,
  providerContractPricingSchemeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_operations_pricing_scheme +
      "/" +
      providerContractPricingSchemeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractOperationsPricingScheme = (
  providerContractID,
  providerContractPricingSchemeID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_operations_pricing_scheme +
      "/" +
      providerContractPricingSchemeID
  );
};

const deleteProviderContractOperationsPricingScheme = (
  providerContractID,
  providerContractPricingSchemeID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_operations_pricing_scheme +
      "/" +
      providerContractPricingSchemeID
  );
};

const addProviderContractServiceDiscountScheme = (
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_service_discount_scheme,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractServiceDiscountScheme = (
  providerContractID,
  providerContractServiceDiscountSchemeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_service_discount_scheme +
      "/",
    JSON.stringify({
      ProviderContractServiceDiscountSchemeID:
        providerContractServiceDiscountSchemeID,
      ...criteria,
    })
  );
};

const cancelProviderContractServiceDiscountScheme = (
  providerContractID,
  providerContractServiceDiscountSchemeSchemeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_service_discount_scheme +
      "/" +
      providerContractServiceDiscountSchemeSchemeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractServiceDiscountScheme = (
  providerContractID,
  providerContractServiceDiscountSchemeSchemeID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_service_discount_scheme +
      "/" +
      providerContractServiceDiscountSchemeSchemeID
  );
};

const deleteProviderContractServiceDiscountScheme = (
  providerContractID,
  providerContractServiceDiscountSchemeSchemeID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_service_discount_scheme +
      "/" +
      providerContractServiceDiscountSchemeSchemeID
  );
};

const addProviderContractOperationCategoryScheme = (
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_operation_category_scheme,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractOperationCategoryScheme = (
  providerContractID,
  providerContractOperationCategorySchemeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_operation_category_scheme +
      "/",
    JSON.stringify({
      ProviderContractOperationCategorySchemeID:
        providerContractOperationCategorySchemeID,
      ...criteria,
    })
  );
};

const cancelProviderContractOperationCategoryScheme = (
  providerContractID,
  providerContractOperationCategorySchemeSchemeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_operation_category_scheme +
      "/" +
      providerContractOperationCategorySchemeSchemeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractOperationCategoryScheme = (
  providerContractID,
  providerContractOperationCategorySchemeSchemeID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_operation_category_scheme +
      "/" +
      providerContractOperationCategorySchemeSchemeID
  );
};

const deleteProviderContractOperationCategoryScheme = (
  providerContractID,
  providerContractOperationCategorySchemeSchemeID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_operation_category_scheme +
      "/" +
      providerContractOperationCategorySchemeSchemeID
  );
};

const addProviderContractRebateScheme = (
  providerID,
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_rebate_scheme,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractRebateScheme = (
  providerID,
  providerContractID,
  providerContractRebateSchemeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_rebate_scheme,
    JSON.stringify({
      ProviderContractRebateSchemeID: providerContractRebateSchemeID,
      ...criteria,
    })
  );
};

const cancelProviderContractRebateScheme = (
  providerID,
  providerContractID,
  providerContractRebateSchemeSchemeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_rebate_scheme +
      "/" +
      providerContractRebateSchemeSchemeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractRebateScheme = (
  providerID,
  providerContractID,
  providerContractRebateSchemeSchemeID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_rebate_scheme +
      "/" +
      providerContractRebateSchemeSchemeID
  );
};

const deleteProviderContractRebateScheme = (
  providerID,
  providerContractID,
  providerContractRebateSchemeSchemeID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_rebate_scheme +
      "/" +
      providerContractRebateSchemeSchemeID
  );
};

const addProviderContractTax = (providerContractID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tax,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractTax = (
  providerContractID,
  providerContractTaxID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_tax +
      "/" +
      providerContractTaxID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContractTax = (
  providerContractID,
  providerContractTaxID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_tax +
      "/" +
      providerContractTaxID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractTax = (
  providerContractID,
  providerContractTaxID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_tax +
      "/" +
      providerContractTaxID
  );
};

const deleteProviderContractTax = (
  providerContractID,
  providerContractTaxID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tax +
      "/" +
      providerContractTaxID
  );
};

const addProviderContractTaxExemption = (providerContractID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tax_exemption,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractTaxExemption = (
  providerContractID,
  providerContractTaxExemptionID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tax_exemption +
      "/" +
      providerContractTaxExemptionID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderContractTaxExemption = (
  providerContractID,
  providerContractTaxExemptionID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tax_exemption +
      "/" +
      providerContractTaxExemptionID
  );
};

const addProviderContractUnitType = (
  providerID,
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_unit_type,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractUnitType = (
  providerID,
  providerContractID,
  providerContractUnitTypeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_unit_type +
      "/" +
      providerContractUnitTypeID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContractUnitType = (
  providerID,
  providerContractID,
  providerContractUnitTypeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_unit_type +
      "/" +
      providerContractUnitTypeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractUnitType = (
  providerID,
  providerContractID,
  providerContractUnitTypeID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_unit_type +
      "/" +
      providerContractUnitTypeID
  );
};

const deleteProviderContractUnitType = (
  providerID,
  providerContractID,
  providerContractUnitTypeID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_unit_type +
      "/" +
      providerContractUnitTypeID
  );
};

const addProviderContractUnitTypeMapping = (
  providerID,
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_unit_type_mapping,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractUnitTypeMapping = (
  providerID,
  providerContractID,
  providerContractUnitTypeID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_unit_type_mapping +
      "/" +
      providerContractUnitTypeID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContractUnitTypeMapping = (
  providerID,
  providerContractID,
  providerContractUnitTypeID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_unit_type_mapping +
      "/" +
      providerContractUnitTypeID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractUnitTypeMapping = (
  providerID,
  providerContractID,
  providerContractUnitTypeID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_unit_type_mapping +
      "/" +
      providerContractUnitTypeID
  );
};

const deleteProviderContractUnitTypeMapping = (
  providerID,
  providerContractID,
  providerContractUnitTypeID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_unit_type_mapping +
      "/" +
      providerContractUnitTypeID
  );
};

const addInHouseProviderConfiguration = (providerContractID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_in_house_provider_configuration,
    JSON.stringify({ ...criteria })
  );
};

const updateInHouseProviderConfiguration = (
  providerContractID,
  inHouseProviderConfigurationID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_in_house_provider_configuration +
      "/" +
      inHouseProviderConfigurationID,
    JSON.stringify({ ...criteria })
  );
};

const cancelInHouseProviderConfiguration = (
  providerContractID,
  inHouseProviderConfigurationID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_in_house_provider_configuration +
      "/" +
      inHouseProviderConfigurationID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateInHouseProviderConfiguration = (
  providerContractID,
  inHouseProviderConfigurationID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_in_house_provider_configuration +
      "/" +
      inHouseProviderConfigurationID
  );
};

const deleteInHouseProviderConfiguration = (
  providerContractID,
  inHouseProviderConfigurationID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_in_house_provider_configuration +
      "/" +
      inHouseProviderConfigurationID
  );
};

const addProviderContractAdvancePayment = (providerContractID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_early_payment,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractAdvancePayment = (
  providerContractID,
  providerContractAdvancePaymentID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContractAdvancePayment = (
  providerContractID,
  providerContractAdvancePaymentID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractAdvancePayment = (
  providerContractID,
  providerContractAdvancePaymentID
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID
  );
};

const deleteProviderContractAdvancePayment = (
  providerContractID,
  providerContractAdvancePaymentID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID
  );
};

const addProviderContractEarlyPaymentDetail = (
  providerContractID,
  providerContractAdvancePaymentID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_early_payment_detail,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractEarlyPaymentDetail = (
  providerContractID,
  providerContractAdvancePaymentID,
  providerContractAdvancePaymentDetailID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_early_payment_detail +
      "/" +
      providerContractAdvancePaymentDetailID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderContractEarlyPaymentDetail = (
  providerContractID,
  providerContractAdvancePaymentID,
  providerContractAdvancePaymentDetailID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_early_payment +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_early_payment_detail +
      "/" +
      providerContractAdvancePaymentDetailID
  );
};

const getProviderGroups = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_groups,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderContractTPADiscount = (
  providerID,
  providerContractID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tpa_discount,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractTPADiscount = (
  providerID,
  providerContractID,
  providerContractTPADiscountID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_update_provider_contract_tpa_discount +
      "/" +
      providerContractTPADiscountID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContractTPADiscount = (
  providerID,
  providerContractID,
  providerContractTPADiscountID,
  cancellationDate
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_cancel_provider_contract_tpa_discount +
      "/" +
      providerContractTPADiscountID,
    JSON.stringify({ Date: cancellationDate })
  );
};

const reinstateProviderContractTPADiscount = (
  providerID,
  providerContractID,
  providerContractTPADiscountID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_reinstate_provider_contract_tpa_discount +
      "/" +
      providerContractTPADiscountID
  );
};

const deleteProviderContractTPADiscount = (
  providerID,
  providerContractID,
  providerContractTPADiscountID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tpa_discount +
      "/" +
      providerContractTPADiscountID
  );
};

const addProviderContractTPADiscountDetail = (
  providerID,
  providerContractID,
  providerContractAdvancePaymentID,
  criteria
) => {
  return apiClient.post(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tpa_discount +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_tpa_discount_detail,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContractTPADiscountDetail = (
  providerID,
  providerContractID,
  providerContractAdvancePaymentID,
  providerContractTPADiscountDetailID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tpa_discount +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_tpa_discount_detail +
      "/" +
      providerContractTPADiscountDetailID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderContractTPADiscountDetail = (
  providerID,
  providerContractID,
  providerContractAdvancePaymentID,
  providerContractTPADiscountDetailID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID +
      route_provider_contract_tpa_discount +
      "/" +
      providerContractAdvancePaymentID +
      route_provider_contract_tpa_discount_detail +
      "/" +
      providerContractTPADiscountDetailID
  );
};

const addProviderContract = (providerID, criteria) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_contract,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderContract = (providerID, providerContractID, criteria) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_contract +
      "/" +
      providerContractID,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderContract = (providerID, providerContractID, criteria) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_cancel_provider_contract +
      "/" +
      providerContractID,
    JSON.stringify({ ...criteria })
  );
};

const activeProviderContract = (providerID, providerContractID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_active_provider_contract +
      "/" +
      providerContractID
  );
};

const deleteProviderContract = (providerID, providerContractID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_contract +
      "/" +
      providerContractID
  );
};

const setProviderFollowsMainBranchContract = (
  providerID,
  isProviderFollowsMainBranchContract
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_follows_main_branch_contract +
      "/" +
      isProviderFollowsMainBranchContract
  );
};

const addProviderGroup = (criteria) => {
  return apiClient.post(
    endpoint + route_provider_group,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderGroup = (providerGroupID, criteria) => {
  return apiClient.put(
    endpoint + route_provider_group + "/" + providerGroupID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderGroup = (providerGroupID) => {
  return apiClient.delete(
    endpoint + route_provider_group + "/" + providerGroupID
  );
};

const addProviderGroupMembers = (criteria) => {
  return apiClient.post(
    endpoint + route_provider_group_member,
    JSON.stringify({ ...criteria })
  );
};

const cancelProviderGroupMember = (
  providerGroupID,
  providerGroupMemberID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_group +
      "/" +
      providerGroupID +
      route_provider_group_member +
      "/" +
      providerGroupMemberID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderGroupMember = (providerGroupID, providerGroupMemberID) => {
  return apiClient.delete(
    endpoint +
      route_provider_group +
      "/" +
      providerGroupID +
      route_provider_group_member +
      "/" +
      providerGroupMemberID
  );
};

const getProviderGroupMembers = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_group + route_provider_group_members,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderGroupMemberWorkspace = (providerGroupID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_group +
      "/" +
      providerGroupID +
      route_provider_group_member_workspace,
    JSON.stringify({ ...criteria })
  );
};

const importProviderGroupMembers = (
  providerGroupMemberWorkspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint + route_provider_group + route_import_provider_group_members,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        ProviderGroupMemberWorkspaceID: providerGroupMemberWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const getProviderGroupMemberWorkspaces = (searchCriteria) => {
  return apiClient.post(
    endpoint +
      route_provider_group +
      route_provider_group_member +
      route_workspaces,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const checkDuplicateProviderGroupMemberDrafts = (
  providerGroupMemberWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_provider_group +
      route_provider_group_member +
      "/" +
      providerGroupMemberWorkspaceID +
      route_check_duplicates
  );
};

const getProviderGroupMemberWorkspaceSummary = (
  providerGroupMemberWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_provider_group +
      route_provider_group_member +
      "/" +
      providerGroupMemberWorkspaceID +
      route_workspace
  );
};

const resolveDuplicateProviderGroupMemberDraft = (
  providerGroupMemberWorkspaceID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_group +
      route_provider_group_member +
      "/" +
      providerGroupMemberWorkspaceID +
      route_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderGroupMemberWorkspace = (providerGroupMemberWorkspaceID) => {
  return apiClient.put(
    endpoint +
      route_provider_group +
      route_provider_group_member +
      "/" +
      providerGroupMemberWorkspaceID
  );
};

const commitProviderGroupMemberWorkspace = (
  providerGroupID,
  providerGroupMemberWorkspaceID
) => {
  return apiClient.put(
    endpoint +
      route_provider_group +
      "/" +
      providerGroupID +
      route_provider_group_member +
      "/" +
      providerGroupMemberWorkspaceID +
      route_commit_workspace
  );
};

const getProviderSupervision = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + act_provider_supervision);
};

const downloadMedicalNetworkActiveMembers = (criteria) => {
  return apiClient.post(
    endpoint + route_download_medical_network_active_members_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderTypeSpecialities = (providerTypeCode) => {
  return apiClient.get(
    endpoint +
      route_provider_types +
      "/" +
      providerTypeCode +
      route_provider_specialties
  );
};

const getProviderSpecialtyDetails = (providerTypeID) => {
  return apiClient.get(
    endpoint + route_provider_specialty_details + "/" + providerTypeID
  );
};

const getProviderClassificationDetails = (providerTypeID) => {
  return apiClient.get(
    endpoint + route_provider_classification_details + "/" + providerTypeID
  );
};

const addProviderSpecialtyDetail = (providerID, providerSpecialtyDetail) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_add_provider_specialty_detail,
    JSON.stringify(providerSpecialtyDetail)
  );
};

const updateProviderSpecialtyDetail = (
  providerID,
  providerSpecialtyDetailID,
  providerSpecialtyDetail
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_specialty_detail +
      "/" +
      providerSpecialtyDetailID,
    JSON.stringify(providerSpecialtyDetail)
  );
};

const deleteProviderSpecialtyDetail = (
  providerID,
  providerSpecialtyDetailID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_delete_provider_specialty_detail +
      "/" +
      providerSpecialtyDetailID
  );
};

const activateProviderSpecialtyDetail = (
  providerID,
  providerSpecialtyDetailID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_active_provider_specialty_detail +
      "/" +
      providerSpecialtyDetailID
  );
};

const addProviderClassificationDetail = (
  providerID,
  providerClassificationDetail
) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_add_provider_classification_detail,
    JSON.stringify(providerClassificationDetail)
  );
};

const updateProviderClassificationDetail = (
  providerID,
  providerClassificationDetailID,
  providerClassificationDetail
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_classification_detail +
      "/" +
      providerClassificationDetailID,
    JSON.stringify(providerClassificationDetail)
  );
};

const deleteProviderClassificationDetail = (
  providerID,
  providerClassificationDetailID
) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_delete_provider_classification_detail +
      "/" +
      providerClassificationDetailID
  );
};

const getProviderAttachments = (providerID) => {
  return apiClient.get(
    endpoint + "/" + providerID + route_provider_attachments
  );
};

const addProviderAttachment = (providerID, attachmentID) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_attachment + "/" + attachmentID
  );
};

const updateProviderAttachment = (providerID, attachmentID, criteria) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_attachment +
      "/" +
      attachmentID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteProviderAttachment = (providerID, providerAttachmentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_delete_provider_attachment +
      "/" +
      providerAttachmentID
  );
};

const getProviderServiceMappings = (providerID, searchCriteria) => {
  return apiClient.post(
    endpoint + route_provider_service_mappings + "/" + providerID,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderServiceMapping = (providerID, providerServiceMapping) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_add_provider_service_mapping,
    JSON.stringify({
      ...providerServiceMapping,
    })
  );
};

const updateProviderServiceMapping = (
  providerID,
  providerServiceMappingID,
  providerServiceMapping
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_update_provider_service_mapping +
      "/" +
      providerServiceMappingID,
    JSON.stringify({
      ...providerServiceMapping,
    })
  );
};

const followsMainProviderServiceMapping = (
  providerID,
  followsMainProviderServiceMapping
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_follows_main_provider_service_mapping +
      "/" +
      followsMainProviderServiceMapping
  );
};

const deleteProviderServiceMapping = (
  providerID,
  providerServiceMappingIDs
) => {
  return apiClient.delete(
    endpoint + "/" + providerID + route_delete_provider_service_mapping,
    { headers: { "Content-Type": "application/json; charset=utf-8" } },
    { data: { providerServiceMappingIDs } }
  );
};

const createProviderServiceMappingWorkspace = (
  providerID,
  providerServiceMappingWorkspace
) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_service_mapping_workspace,
    JSON.stringify({
      ...providerServiceMappingWorkspace,
    })
  );
};

const getProviderServiceMappingWorkspaces = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_provider_service_mapping_workspaces,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getProviderServiceMappingWorkspaceSummary = (
  providerServiceMappingWorkspaceID
) => {
  return apiClient.get(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      providerServiceMappingWorkspaceID +
      route_workspace_summary
  );
};

const deleteProviderServiceMappingWorkspace = (
  providerServiceMappingWorkspaceID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      providerServiceMappingWorkspaceID
  );
};

const downloadProviderServiceMappingWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_service_mapping_workspace +
      act_download_provider_service_mapping_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const importProviderServiceMappingWorkspaceDrafts = (
  ProviderServiceMappingWorkspaceID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint +
      route_provider_service_mapping_workspace +
      act_import_provider_service_mapping_workspace_drafts,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        ProviderServiceMappingWorkspaceID,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const checkDuplicateProviderServiceMappingDrafts = (
  workspaceID,
  statusCode
) => {
  return apiClient.get(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_check_duplicate +
      "/" +
      statusCode
  );
};

const resolveDuplicateProviderServiceMapping = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_resolve_duplicate,
    JSON.stringify({ ...criteria })
  );
};

const checkProviderServiceMappingDraftMatches = (workspaceID, statusCode) => {
  return apiClient.get(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_check_matches +
      "/" +
      statusCode
  );
};

const resolveAllProviderServiceMappingDraftMatches = (workspaceID) => {
  return apiClient.put(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_resolve_all_draft_matches
  );
};

const resolveProviderServiceMappingDraftMatch = (workspaceID, criteria) => {
  return apiClient.put(
    endpoint +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_resolve_draft_match,
    JSON.stringify({ ...criteria })
  );
};

const commitProviderServiceMappingWorkspace = (providerID, workspaceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_service_mapping_workspace +
      "/" +
      workspaceID +
      act_commit
  );
};

const exportProviderServiceMappings = (providerID) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_export_provider_service_mappings,
    JSON.stringify({}),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderTypeList = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_type_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderType = (providerType) => {
  return apiClient.post(
    endpoint + route_provider_type,
    JSON.stringify({
      ...providerType,
    })
  );
};

const updateProviderType = (providerTypeID, providerType) => {
  return apiClient.put(
    endpoint + route_update_provider_type + "/" + providerTypeID,
    JSON.stringify({
      ...providerType,
    })
  );
};

const getProviderType = (providerTypeID) => {
  return apiClient.get(endpoint + route_provider_type + "/" + providerTypeID);
};

const deleteProviderType = (providerTypeID) => {
  return apiClient.delete(
    endpoint + route_delete_provider_type + "/" + providerTypeID
  );
};

const getProviderTypeClaimFormTypes = (providerTypeID) => {
  return apiClient.get(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_provider_type_claim_types
  );
};

const getProviderTypeSpecialitiesByProviderTypeID = (providerTypeID) => {
  return apiClient.get(
    endpoint +
      route_provider_types +
      "/" +
      providerTypeID +
      route_provider_specialties
  );
};

const getInsuranceCompanyProviderTypeCodes = (providerTypeID) => {
  return apiClient.get(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_insurance_company_provider_type_codes
  );
};

const addProviderTypeClaimType = (providerTypeID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_provider_type_claim_type,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderTypeClaimType = (providerTypeID, claimTypeID, criteria) => {
  return apiClient.put(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_update_provider_type_claim_type +
      "/" +
      claimTypeID,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderTypeClaimType = (providerTypeClaimTypeID) => {
  return apiClient.delete(
    endpoint +
      route_provider_type +
      route_delete_provider_type_claim_type +
      "/" +
      providerTypeClaimTypeID
  );
};

const setProviderTypeClaimTypeDefaultClaim = (
  providerTypeID,
  providerTypeClaimTypeID,
  isDefault
) => {
  return apiClient.put(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_set_provider_type_claim_type_default_claim +
      "/" +
      providerTypeClaimTypeID +
      "/" +
      isDefault
  );
};

const addInsuranceCompanyProviderTypeCode = (providerTypeID, criteria) => {
  return apiClient.post(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_insurance_company_provider_type_code,
    JSON.stringify({ ...criteria })
  );
};

const updateInsuranceCompanyProviderTypeCode = (
  providerTypeID,
  insuranceCompanyID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_update_insurance_company_provider_type_code +
      "/" +
      insuranceCompanyID,
    JSON.stringify({ ...criteria })
  );
};

const deleteInsuranceCompanyProviderTypeCode = (
  providerTypeID,
  insuranceCompanyproviderTypeID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_type +
      "/" +
      providerTypeID +
      route_delete_insurance_company_provider_type_code +
      "/" +
      insuranceCompanyproviderTypeID
  );
};

const getProviderClassificationList = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_classification_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderClassification = (providerClassification) => {
  return apiClient.post(
    endpoint + route_provider_classification,
    JSON.stringify({
      ...providerClassification,
    })
  );
};

const updateProviderClassification = (
  providerClassificationID,
  providerClassification
) => {
  return apiClient.put(
    endpoint + route_provider_classification + "/" + providerClassificationID,
    JSON.stringify({
      ...providerClassification,
    })
  );
};

const deleteProviderClassification = (providerClassificationID) => {
  return apiClient.delete(
    endpoint +
      route_delete_provider_classification +
      "/" +
      providerClassificationID
  );
};

const getProviderSpecialties = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_specialty_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewProviderSpecialty = (providerSpecialty) => {
  return apiClient.post(
    endpoint + route_provider_specialty,
    JSON.stringify({
      ...providerSpecialty,
    })
  );
};

const updateProviderSpecialty = (providerSpecialtyID, providerSpecialty) => {
  return apiClient.put(
    endpoint + route_provider_specialty + "/" + providerSpecialtyID,
    JSON.stringify({
      ...providerSpecialty,
    })
  );
};

const deleteProviderSpecialty = (providerSpecialtyID) => {
  return apiClient.delete(
    endpoint + route_provider_specialty + "/" + providerSpecialtyID
  );
};

const getSpecialtyServices = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_specialty + route_specialty_services,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addSpecialtyService = (providerSpecialtyID, serviceID) => {
  return apiClient.post(
    endpoint +
      route_provider_specialty +
      "/" +
      providerSpecialtyID +
      route_specialty_service +
      "/" +
      serviceID
  );
};

const deleteSpecialtyService = (specialtyServiceID) => {
  return apiClient.delete(
    endpoint +
      route_provider_specialty +
      route_specialty_service +
      "/" +
      specialtyServiceID
  );
};

const importSpecialtyServices = (
  providerSpecialtyID,
  formData,
  onUploadProgress
) => {
  return apiClient.post(
    endpoint +
      route_provider_specialty +
      "/" +
      providerSpecialtyID +
      route_import_specialty_services,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const getSpecialtyDiagnoses = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_specialty + route_specialty_diagnoses,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addSpecialtyDiagnosis = (providerSpecialtyID, diagnosisID) => {
  return apiClient.post(
    endpoint +
      route_provider_specialty +
      "/" +
      providerSpecialtyID +
      route_specialty_diagnosis +
      "/" +
      diagnosisID
  );
};

const deleteSpecialtyDiagnosis = (specialtyDiagnosisID) => {
  return apiClient.delete(
    endpoint +
      route_provider_specialty +
      route_specialty_diagnosis +
      "/" +
      specialtyDiagnosisID
  );
};

const setSpecialtyDiagnosisDefault = (
  providerSpecialtyID,
  specialtyDiagnosisID,
  isDefault
) => {
  return apiClient.put(
    endpoint +
      route_provider_specialty +
      "/" +
      providerSpecialtyID +
      route_specialty_diagnosis +
      "/" +
      specialtyDiagnosisID +
      act_set_specialty_diagnosis_default +
      "/" +
      isDefault
  );
};

const setPrimarySpecialtyDiagnosis = (
  providerSpecialtyID,
  specialtyDiagnosisID,
  isPrimary
) => {
  return apiClient.put(
    endpoint +
      route_provider_specialty +
      "/" +
      providerSpecialtyID +
      route_specialty_diagnosis +
      "/" +
      specialtyDiagnosisID +
      act_set_primary_specialty_diagnosis +
      "/" +
      isPrimary
  );
};

const getProviderCategoryList = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_category_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderCategory = (providerCategory) => {
  return apiClient.post(
    endpoint + route_provider_category,
    JSON.stringify({
      ...providerCategory,
    })
  );
};

const updateProviderCategory = (providerCategoryID, providerCategory) => {
  return apiClient.put(
    endpoint + route_provider_category + "/" + providerCategoryID,
    JSON.stringify({
      ...providerCategory,
    })
  );
};

const deleteProviderCategory = (providerCategoryID) => {
  return apiClient.delete(
    endpoint + route_provider_category + "/" + providerCategoryID
  );
};

const getProviderGroupClassificationList = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_group_classification_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderGroupClassification = (providerGroupClassification) => {
  return apiClient.post(
    endpoint + route_provider_group_classification,
    JSON.stringify({
      ...providerGroupClassification,
    })
  );
};

const updateProviderGroupClassification = (
  providerGroupClassificationID,
  providerGroupClassification
) => {
  return apiClient.put(
    endpoint +
      route_provider_group_classification +
      "/" +
      providerGroupClassificationID,
    JSON.stringify({
      ...providerGroupClassification,
    })
  );
};

const deleteProviderGroupClassification = (providerGroupClassificationID) => {
  return apiClient.delete(
    endpoint +
      route_provider_group_classification +
      "/" +
      providerGroupClassificationID
  );
};

const getProviderRequestAssignmentRuleList = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_request_assignment_rule_list,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderRequestAssignmentRule = (providerRequestAssignmentRule) => {
  return apiClient.post(
    endpoint + route_provider_request_assignment_rule,
    JSON.stringify({
      ...providerRequestAssignmentRule,
    })
  );
};

const updateProviderRequestAssignmentRule = (
  providerRequestAssignmentRuleID,
  providerRequestAssignmentRule
) => {
  return apiClient.put(
    endpoint +
      route_provider_request_assignment_rule +
      "/" +
      providerRequestAssignmentRuleID,
    JSON.stringify({
      ...providerRequestAssignmentRule,
    })
  );
};

const deleteProviderRequestAssignmentRule = (
  providerRequestAssignmentRuleID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_request_assignment_rule +
      "/" +
      providerRequestAssignmentRuleID
  );
};

const getDefaultProviderContractSettings = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_default_provider_contract_settings,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addDefaultProviderContractSetting = (defaultProviderContractSetting) => {
  return apiClient.post(
    endpoint + route_default_provider_contract_setting,
    JSON.stringify({
      ...defaultProviderContractSetting,
    })
  );
};

const updateDefaultProviderContractSetting = (
  defaultProviderContractSettingID,
  defaultProviderContractSetting
) => {
  return apiClient.put(
    endpoint +
      route_default_provider_contract_setting +
      "/" +
      defaultProviderContractSettingID,
    JSON.stringify({
      ...defaultProviderContractSetting,
    })
  );
};

const deleteDefaultProviderContractSetting = (
  defaultProviderContractSettingID
) => {
  return apiClient.delete(
    endpoint +
      route_default_provider_contract_setting +
      "/" +
      defaultProviderContractSettingID
  );
};

const removeDuplicateProviders = (criteria) => {
  return apiClient.put(
    endpoint + route_remove_duplicate_providers,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getProviderClaimTypes = (providerID) => {
  return apiClient.get(
    endpoint + "/" + providerID + route_provider_claim_types
  );
};

const addProviderClaimType = (providerID, claimTypeID) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_claim_type + "/" + claimTypeID
  );
};

const removeProviderClaimType = (providerID, claimTypeID) => {
  return apiClient.delete(
    endpoint + "/" + providerID + route_provider_claim_type + "/" + claimTypeID
  );
};

const getProviderOfferedServices = (providerID) => {
  return apiClient.get(
    endpoint + "/" + providerID + route_provider_offered_services
  );
};

const addProviderOfferedService = (providerID, offeredServiceIDs) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_offered_service,
    JSON.stringify({
      OfferedServiceIDs: offeredServiceIDs,
    })
  );
};

const removeProviderOfferedServices = (providerID, offeredServiceIDs) => {
  return apiClient.delete(
    endpoint + "/" + providerID + route_provider_offered_service,
    { headers: { "Content-Type": "application/json; charset=utf-8" } },
    { data: { offeredServiceIDs } }
  );
};

const getProviderWebRequests = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_web_requests,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getProviderWebRequest = (providerWebRequestID) => {
  return apiClient.get(
    endpoint + route_provider_web_request + "/" + providerWebRequestID
  );
};

const checkoutProviderWebRequest = (providerWebRequestID) => {
  return apiClient.put(endpoint + "/" + providerWebRequestID + route_checkout);
};

const checkinProviderWebRequest = (providerWebRequestID) => {
  return apiClient.put(endpoint + "/" + providerWebRequestID + route_checkin);
};

const forceCheckinProviderWebRequest = (providerWebRequestID) => {
  return apiClient.put(
    endpoint + "/" + providerWebRequestID + route_force_checkin
  );
};

const setProviderWebRequestComment = (providerWebRequestID, text) => {
  return apiClient.put(
    endpoint + "/" + providerWebRequestID + route_set_comment,
    JSON.stringify({
      Text: text,
    })
  );
};

const rejectWebRequest = (providerWebRequestID, commentID, text) => {
  return apiClient.put(
    endpoint + "/" + providerWebRequestID + route_reject,
    JSON.stringify({
      AdditionalInfo: text,
      CommentID: commentID,
    })
  );
};

const acceptWebRequestWithMatch = (
  providerWebRequestID,
  providerMatchID,
  propertiesToOverride
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerWebRequestID +
      route_accept +
      "/" +
      providerMatchID,
    JSON.stringify({
      ...propertiesToOverride,
    })
  );
};

const acceptWebRequest = (providerWebRequestID) => {
  return apiClient.put(endpoint + "/" + providerWebRequestID + route_accept);
};

const getProviderBankAccounts = (providerID) => {
  return apiClient.get(
    endpoint + "/" + providerID + route_provider_bank_accounts
  );
};

const providerDirectPaymentRecipients = (providerID) => {
  return apiClient.get(
    endpoint + "/" + providerID + route_provider_direct_payment_recipients
  );
};

const addProviderBankAccount = (providerBankAccount) => {
  return apiClient.post(
    endpoint + route_provider_bank_account,
    JSON.stringify({
      ...providerBankAccount,
    })
  );
};

const updateProviderBankAccount = (
  providerBankAccountID,
  providerBankAccount
) => {
  return apiClient.put(
    endpoint + route_provider_bank_account + "/" + providerBankAccountID,
    JSON.stringify({
      ...providerBankAccount,
    })
  );
};

const deleteProviderBankAccount = (providerBankAccountID) => {
  return apiClient.delete(
    endpoint + route_provider_bank_account + "/" + providerBankAccountID
  );
};

const verifyProviderBankAccount = (providerBankAccountID) => {
  return apiClient.put(
    endpoint + route_verify_provider_bank_account + "/" + providerBankAccountID
  );
};

const unverifyProviderBankAccount = (providerBankAccountID) => {
  return apiClient.put(
    endpoint +
      route_unverify_provider_bank_account +
      "/" +
      providerBankAccountID
  );
};

const activateProviderBankAccount = (
  providerID,
  providerBankAccountID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_activate_provider_bank_account +
      "/" +
      providerBankAccountID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deactivateProviderBankAccount = (providerID, providerBankAccountID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_deactivate_provider_bank_account +
      "/" +
      providerBankAccountID
  );
};

const freezeProviderBankAccount = (
  providerID,
  providerBankAccountID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_freeze_provider_bank_account +
      "/" +
      providerBankAccountID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const unfreezeProviderBankAccount = (
  providerID,
  providerBankAccountID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_unfreeze_provider_bank_account +
      "/" +
      providerBankAccountID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const addProviderDirectPaymentRecipient = (providerDirectPaymentRecipient) => {
  return apiClient.post(
    endpoint + route_provider_direct_payment_recipient,
    JSON.stringify({
      ...providerDirectPaymentRecipient,
    })
  );
};

const updateProviderDirectPaymentRecipient = (
  providerDirectPaymentRecipientID,
  providerDirectPaymentRecipient
) => {
  return apiClient.put(
    endpoint +
      route_provider_direct_payment_recipient +
      "/" +
      providerDirectPaymentRecipientID,
    JSON.stringify({
      ...providerDirectPaymentRecipient,
    })
  );
};

const deleteProviderDirectPaymentRecipient = (
  providerDirectPaymentRecipientID
) => {
  return apiClient.delete(
    endpoint +
      route_provider_direct_payment_recipient +
      "/" +
      providerDirectPaymentRecipientID
  );
};

const activateProviderDirectPaymentRecipient = (
  providerID,
  providerDirectPaymentRecipientID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_activate_provider_direct_payment_recipient +
      "/" +
      providerDirectPaymentRecipientID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deactivateProviderDirectPaymentRecipient = (
  providerID,
  providerDirectPaymentRecipientID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_deactivate_provider_direct_payment_recipient +
      "/" +
      providerDirectPaymentRecipientID
  );
};

const setProviderPaymentMethodType = (providerID, paymentMethodTypeID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_payment_method +
      "/" +
      paymentMethodTypeID
  );
};

const setProviderFollowsMainBranchPaymentSettings = (
  providerID,
  followsMainBranchPaymentSettings
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_follows_main_Branch_payment_settings +
      "/" +
      followsMainBranchPaymentSettings
  );
};

const getProviderInformationReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_information_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderContractsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_contracts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderContractEarlyPaymentsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_contract_early_payments_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderContractTaxesReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_contract_taxes_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderContractTPADiscountsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_contract_tpa_discounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderContractTaxExemptionsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_contract_tax_exemptions_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getInHouseProviderCompensationsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_in_house_provider_compensations_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getInHouseProviderConfigurationsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_in_house_provider_configurations_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderOnlineActivationReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_in_house_provider_online_activation_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getMedicalNetworkProviderGroupReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_medical_network_provider_group_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderSupervisionsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_supervisions_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getMedicalNetworkReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_medical_network_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderWithFrozenBankAccountsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_with_frozen_bank_accounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProvidersPaymentInformationReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_providers_payment_information_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderStatementReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_statement_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderCommentsReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_comments_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getFacilityProvidersReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_facility_providers_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderGroupMembersReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_provider_group_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getMedicalNetworkActiveMembersReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_medical_network_active_members,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderCommunications = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider_communications,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addProviderCommunication = (providerCommunication) => {
  return apiClient.post(
    endpoint + route_provider_communication,
    JSON.stringify({
      ...providerCommunication,
    })
  );
};

const updateProviderCommunication = (
  providerCommunicationID,
  providerCommunication
) => {
  return apiClient.put(
    endpoint + route_provider_communication + "/" + providerCommunicationID,
    JSON.stringify({
      ...providerCommunication,
    })
  );
};

const deleteProviderCommunication = (providerCommunicationID) => {
  return apiClient.delete(
    endpoint + route_provider_communication + "/" + providerCommunicationID
  );
};

const getProviderMessageTemplates = () => {
  return apiClient.get(endpoint + route_provider_message_templates);
};

const addProviderMessage = (providerMessage) => {
  return apiClient.post(
    endpoint + route_provider_communication + route_add_provider_message,
    JSON.stringify({
      ...providerMessage,
    })
  );
};

const getProviderServicePricings = (providerID, searchCriteria) => {
  return apiClient.post(
    endpoint + "/" + providerID + route_provider_service_pricings,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const deleteProviderUser = (providerID, providerUserID, deletionReason) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      rout_provider_user +
      "/" +
      providerUserID +
      act_delete,
    JSON.stringify({
      Comment: deletionReason,
    })
  );
};

const sendProviderActivationSMS = (providerID, providerUserID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      rout_provider_user +
      "/" +
      providerUserID +
      act_send_activation_sms
  );
};

const setProviderUserAsManagement = (providerID, providerUserID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      rout_provider_user +
      "/" +
      providerUserID +
      act_set_as_management
  );
};

const unSetProviderUserAsManagement = (providerID, providerUserID) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      rout_provider_user +
      "/" +
      providerUserID +
      act_unset_as_management
  );
};

const createProviderManagementUser = (providerID) => {
  return apiClient.put(
    endpoint + "/" + providerID + act_create_management_user
  );
};

const createProviderWebRequest = (providerWebRequest) => {
  return apiClient.post(
    endpoint + act_create_provider_web_react,
    JSON.stringify({ ...providerWebRequest })
  );
};

const getMedicalNetworkProviders = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + act_medical_network_providers,
    JSON.stringify({
      ...searchCriteria,
      ...paging,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const downloadProviderPricesAndDiscountsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_provider_prices_and_discounts_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getProviderComments = (providerID) => {
  return apiClient.get(endpoint + "/" + providerID + act_provider_comments);
};

const addProviderComment = (providerID, providerComment) => {
  return apiClient.post(
    endpoint + "/" + providerID + act_add_provider_comment,
    JSON.stringify({ ...providerComment })
  );
};

const updateProviderComment = (providerID, providerCommentID, criteria) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_comment +
      "/" +
      providerCommentID +
      act_update_provider_comment,
    JSON.stringify({ ...criteria })
  );
};

const deleteProviderComment = (providerID, providerCommentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      providerID +
      route_provider_comment +
      "/" +
      providerCommentID +
      act_delete
  );
};

const setProviderUnderSupervision = (providerID, providerSupervision) => {
  return apiClient.post(
    endpoint + "/" + providerID + act_set_provider_under_supervision,
    JSON.stringify({ ...providerSupervision })
  );
};

const removeProviderSupervision = (providerID, criteria) => {
  return apiClient.put(
    endpoint + "/" + providerID + act_remove_provider_supervision,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderSupervision = (
  providerID,
  providerSupervisionID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_provider_supervision +
      "/" +
      providerSupervisionID +
      act_update_provider_supervision,
    JSON.stringify({ ...criteria })
  );
};

const updateProviderCode = (providerID, providerCode) => {
  return apiClient.put(
    endpoint + "/" + providerID + act_update_provider_code,
    JSON.stringify({ ProviderCode: providerCode })
  );
};

const downloadProviderGroupMemberWorkspaceReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_provider_group_member_workspace_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};
const importFacilityProviders = (providerID, formData, onUploadProgress) => {
  return apiClient.post(
    endpoint + "/" + providerID + act_import_facility_providers,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progress) =>
        onUploadProgress(Math.round((100 * progress.loaded) / progress.total)),
    }
  );
};

const setProviderEnableUserManagement = (providerID, enableUserManagement) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      route_set_provider_enable_user_management +
      "/" +
      enableUserManagement
  );
};

const setProvideAllowIssuingDigitalPrescription = (
  providerID,
  allowIssuingDigitalPrescription
) => {
  return apiClient.put(
    endpoint +
      "/" +
      providerID +
      act_set_allow_issuing_digital_prescription +
      "/" +
      allowIssuingDigitalPrescription
  );
};
const providersApi = {
  getProviderTypes,
  getFullProvider,
  getProviderContacts,
  getFacilityProviders,
  getProviderUser,
  getProviderContracts,
  getInHouseProviderConfigurations,
  getProviderContractPricingSchemes,
  getProviderContractOperationsPricingSchemes,
  getProviderContractServiceDiscountSchemes,
  getProviderContractOperationCategorySchemes,
  getProviderContractTPADiscounts,
  getProviderContractTPADiscountDetails,
  getProviderBranches,
  getProviders,
  downloadProviderInformationVerificationReport,
  getProviderInfo,
  getProviderTypeSpecialties,
  updateProviderInfo,
  updateProviderContactInfo,
  addProviderWorkingHour,
  updateProviderWorkingHour,
  deleteProviderWorkingHour,
  getProviderTypeConfiguration,
  createNewProvider,
  getProviderContractEarlyPayments,
  getProviderContractEarlyPaymentDetails,
  getProviderContractRebateSchemes,
  getProviderContractTaxes,
  getProviderContractUnitTypes,
  updateProviderTypeInfo,
  setDefaultTreatmentDoctor,
  unSetDefaultTreatmentDoctor,
  addProviderToPreAuthorizationNetwork,
  resetProviderAuthenticationAndNotify,
  removeProviderFromPreAuthorizationNetwork,
  deleteProvider,
  cancelProvider,
  publishProvider,
  unPublishProvider,
  reinstateProvider,
  updateProviderMainBranch,
  updateProviderAddress,
  setProviderAsVIP,
  unSetProviderVIP,
  authorizeAndPublish,
  verifyProvider,
  unVerifyProvider,
  setDefaultConsultationService,
  unsetDefaultConsultationService,
  setProviderAsPolicyHolder,
  unsetProviderAsPolicyHolder,
  setProviderSupportSpecialDiscounts,
  setProviderAsInsured,
  unsetProviderAsInsured,
  updateProviderName,
  updateProviderUserFullName,
  updateProviderLicenseInfo,
  updateProviderFullContactInfo,
  updateProviderInHouseInfo,
  addFacilityProvider,
  deleteFacilityProvider,
  removeFacilityProvider,
  reinstateFacilityProvider,
  addProviderContact,
  updateProviderContact,
  deleteProviderContact,
  updateProviderDefaultCurrency,
  updateProviderLanguage,
  setProviderAppointmentEnabled,
  setProviderDeliverDrugs,
  setProviderSecondOpinionReferral,
  setProviderVirtual,
  addProviderContractPricingScheme,
  updateProviderContractPricingScheme,
  cancelProviderContractPricingScheme,
  reinstateProviderContractPricingScheme,
  removeProviderContractPricingScheme,
  addProviderContractOperationsPricingScheme,
  updateProviderContractOperationsPricingScheme,
  cancelProviderContractOperationsPricingScheme,
  reinstateProviderContractOperationsPricingScheme,
  deleteProviderContractOperationsPricingScheme,
  addProviderContractServiceDiscountScheme,
  updateProviderContractServiceDiscountScheme,
  cancelProviderContractServiceDiscountScheme,
  reinstateProviderContractServiceDiscountScheme,
  deleteProviderContractServiceDiscountScheme,
  addProviderContractOperationCategoryScheme,
  updateProviderContractOperationCategoryScheme,
  cancelProviderContractOperationCategoryScheme,
  reinstateProviderContractOperationCategoryScheme,
  deleteProviderContractOperationCategoryScheme,
  addProviderContractRebateScheme,
  updateProviderContractRebateScheme,
  reinstateProviderContractRebateScheme,
  cancelProviderContractRebateScheme,
  deleteProviderContractRebateScheme,
  addProviderContractTax,
  updateProviderContractTax,
  cancelProviderContractTax,
  reinstateProviderContractTax,
  deleteProviderContractTax,
  addProviderContractTaxExemption,
  updateProviderContractTaxExemption,
  deleteProviderContractTaxExemption,
  addProviderContractUnitType,
  updateProviderContractUnitType,
  cancelProviderContractUnitType,
  reinstateProviderContractUnitType,
  deleteProviderContractUnitType,
  addProviderContractUnitTypeMapping,
  updateProviderContractUnitTypeMapping,
  cancelProviderContractUnitTypeMapping,
  reinstateProviderContractUnitTypeMapping,
  deleteProviderContractUnitTypeMapping,
  addInHouseProviderConfiguration,
  updateInHouseProviderConfiguration,
  cancelInHouseProviderConfiguration,
  reinstateInHouseProviderConfiguration,
  deleteInHouseProviderConfiguration,
  addProviderContractAdvancePayment,
  updateProviderContractAdvancePayment,
  cancelProviderContractAdvancePayment,
  reinstateProviderContractAdvancePayment,
  deleteProviderContractAdvancePayment,
  addProviderContractEarlyPaymentDetail,
  updateProviderContractEarlyPaymentDetail,
  deleteProviderContractEarlyPaymentDetail,
  getProviderGroups,
  addProviderContractTPADiscount,
  updateProviderContractTPADiscount,
  reinstateProviderContractTPADiscount,
  cancelProviderContractTPADiscount,
  deleteProviderContractTPADiscount,
  addProviderContractTPADiscountDetail,
  updateProviderContractTPADiscountDetail,
  deleteProviderContractTPADiscountDetail,
  addProviderContract,
  updateProviderContract,
  cancelProviderContract,
  activeProviderContract,
  deleteProviderContract,
  setProviderFollowsMainBranchContract,
  addProviderGroup,
  updateProviderGroup,
  deleteProviderGroup,
  getProviderGroupMembers,
  addProviderGroupMembers,
  cancelProviderGroupMember,
  deleteProviderGroupMember,
  importProviderGroupMembers,
  addProviderGroupMemberWorkspace,
  getProviderGroupMemberWorkspaces,
  checkDuplicateProviderGroupMemberDrafts,
  getProviderGroupMemberWorkspaceSummary,
  resolveDuplicateProviderGroupMemberDraft,
  deleteProviderGroupMemberWorkspace,
  commitProviderGroupMemberWorkspace,
  setProviderInPrefferedNetwork,
  setProviderAllowChronicDispensationInFuture,
  getProviderSupervision,
  setProviderRequiresExternalRequestID,
  setProviderDisableThirdPartyPortal,
  downloadMedicalNetworkActiveMembers,
  getProviderTypeSpecialities,
  getProviderSpecialtyDetails,
  getProviderClassificationDetails,
  addProviderSpecialtyDetail,
  updateProviderSpecialtyDetail,
  deleteProviderSpecialtyDetail,
  activateProviderSpecialtyDetail,
  addProviderClassificationDetail,
  updateProviderClassificationDetail,
  deleteProviderClassificationDetail,
  getProviderAttachments,
  addProviderAttachment,
  updateProviderAttachment,
  deleteProviderAttachment,
  getProviderServiceMappings,
  addProviderServiceMapping,
  updateProviderServiceMapping,
  followsMainProviderServiceMapping,
  deleteProviderServiceMapping,
  createProviderServiceMappingWorkspace,
  getProviderServiceMappingWorkspaces,
  getProviderServiceMappingWorkspaceSummary,
  deleteProviderServiceMappingWorkspace,
  downloadProviderServiceMappingWorkspaceReport,
  importProviderServiceMappingWorkspaceDrafts,
  checkDuplicateProviderServiceMappingDrafts,
  resolveDuplicateProviderServiceMapping,
  checkProviderServiceMappingDraftMatches,
  resolveProviderServiceMappingDraftMatch,
  resolveAllProviderServiceMappingDraftMatches,
  commitProviderServiceMappingWorkspace,
  exportProviderServiceMappings,
  getProviderTypeList,
  addProviderType,
  updateProviderType,
  getProviderType,
  deleteProviderType,
  getProviderTypeClaimFormTypes,
  getProviderTypeSpecialitiesByProviderTypeID,
  getInsuranceCompanyProviderTypeCodes,
  addProviderTypeClaimType,
  updateProviderTypeClaimType,
  deleteProviderTypeClaimType,
  setProviderTypeClaimTypeDefaultClaim,
  addInsuranceCompanyProviderTypeCode,
  updateInsuranceCompanyProviderTypeCode,
  deleteInsuranceCompanyProviderTypeCode,
  getProviderClassificationList,
  addProviderClassification,
  updateProviderClassification,
  deleteProviderClassification,
  getProviderSpecialties,
  createNewProviderSpecialty,
  updateProviderSpecialty,
  deleteProviderSpecialty,
  getSpecialtyServices,
  addSpecialtyService,
  deleteSpecialtyService,
  importSpecialtyServices,
  getSpecialtyDiagnoses,
  addSpecialtyDiagnosis,
  deleteSpecialtyDiagnosis,
  setSpecialtyDiagnosisDefault,
  setPrimarySpecialtyDiagnosis,
  getProviderCategoryList,
  addProviderCategory,
  updateProviderCategory,
  deleteProviderCategory,
  getProviderGroupClassificationList,
  addProviderGroupClassification,
  updateProviderGroupClassification,
  deleteProviderGroupClassification,
  getProviderRequestAssignmentRuleList,
  addProviderRequestAssignmentRule,
  updateProviderRequestAssignmentRule,
  deleteProviderRequestAssignmentRule,
  getDefaultProviderContractSettings,
  addDefaultProviderContractSetting,
  updateDefaultProviderContractSetting,
  deleteDefaultProviderContractSetting,
  removeDuplicateProviders,
  getProviderClaimTypes,
  addProviderClaimType,
  removeProviderClaimType,
  getProviderOfferedServices,
  addProviderOfferedService,
  removeProviderOfferedServices,
  getProviderWebRequests,
  getProviderWebRequest,
  checkoutProviderWebRequest,
  checkinProviderWebRequest,
  forceCheckinProviderWebRequest,
  setProviderWebRequestComment,
  rejectWebRequest,
  acceptWebRequestWithMatch,
  acceptWebRequest,
  getProviderBankAccounts,
  providerDirectPaymentRecipients,
  addProviderBankAccount,
  updateProviderBankAccount,
  deleteProviderBankAccount,
  verifyProviderBankAccount,
  unverifyProviderBankAccount,
  activateProviderBankAccount,
  deactivateProviderBankAccount,
  freezeProviderBankAccount,
  unfreezeProviderBankAccount,
  addProviderDirectPaymentRecipient,
  updateProviderDirectPaymentRecipient,
  deleteProviderDirectPaymentRecipient,
  activateProviderDirectPaymentRecipient,
  deactivateProviderDirectPaymentRecipient,
  setProviderPaymentMethodType,
  setProviderFollowsMainBranchPaymentSettings,
  getProviderInformationReport,
  getProviderContractsReport,
  getProviderContractEarlyPaymentsReport,
  getProviderContractTaxesReport,
  getProviderContractTPADiscountsReport,
  getProviderContractTaxExemptionsReport,
  getInHouseProviderCompensationsReport,
  getInHouseProviderConfigurationsReport,
  getProviderOnlineActivationReport,
  getMedicalNetworkProviderGroupReport,
  getProviderSupervisionsReport,
  getMedicalNetworkReport,
  getProviderWithFrozenBankAccountsReport,
  getProvidersPaymentInformationReport,
  getProviderStatementReport,
  getProviderCommentsReport,
  getFacilityProvidersReport,
  getProviderGroupMembersReport,
  getMedicalNetworkActiveMembersReport,
  getProviderCommunications,
  addProviderCommunication,
  updateProviderCommunication,
  deleteProviderCommunication,
  getProviderMessageTemplates,
  addProviderMessage,
  getProviderServicePricings,
  deleteProviderUser,
  sendProviderActivationSMS,
  setProviderUserAsManagement,
  createProviderManagementUser,
  createProviderWebRequest,
  getMedicalNetworkProviders,
  unSetProviderUserAsManagement,
  downloadProviderPricesAndDiscountsReport,
  getProviderComments,
  addProviderComment,
  updateProviderComment,
  deleteProviderComment,
  setProviderUnderSupervision,
  removeProviderSupervision,
  updateProviderSupervision,
  updateProviderCode,
  downloadProviderGroupMemberWorkspaceReport,
  importFacilityProviders,
  setProviderEnableUserManagement,
  setProvideAllowIssuingDigitalPrescription,
};

export default providersApi;
