import React, {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  GridCellProps,
  GridExpandChangeEvent,
  GridItemChangeEvent,
  GridProps,
  GridRowClickEvent,
  GridToolbar,
  GridSelectionChangeEvent,
  GridNoRecords,
  GridDataStateChangeEvent,
  GridRowDoubleClickEvent,
  GridEvent,
  getSelectedState,
  GridColumn,
} from "@progress/kendo-react-grid";
import { DataResult, process, State } from "@progress/kendo-data-query";
// import "../../styles/appGrid.scss";
import AppCommandCell from "./AppCommandCell";
import { LocalizationContext } from "../../context/localizationContext";
import LoadingPanel from "../general/LoadingPanel";
import AppGridToolbarButton from "./AppGridToolbarButton";
import {
  AppGridColumnPropsWithType,
  AppGridColumnProps,
} from "../../ui/AppGridColumnProps";
import appGridColumnsHelper from "../../helpers/appGridColumnsHelper";

import BusinessObjectModel from "../../interfaces/BusinessObjectModel";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
  ExcelExportColumnProps,
} from "@progress/kendo-react-excel-export";
import { ApiResponse } from "apisauce";
import errorHandler from "../../utility/errorHandler";
import Alert from "../general/Alert";
import { Tooltip } from "@progress/kendo-react-tooltip";
import baseStrings from "../../base/baseStrings";
import appGridConstants from "../../constants/appGridConstants";
import { getter } from "@progress/kendo-react-common";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import { DrawerContext } from "./../../context/drawerContext";
import {
  getGroupIds,
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";

interface Props<T> extends GridProps {
  columns: AppGridColumnPropsWithType[];
  gridData: T[];
  singleCellEdit?: boolean;
  onSingleCellEdit?: (
    dataYtemID: number,
    fieldName: string,
    newValue: string
  ) => void;
  showEditButton?: boolean;
  showAddButton?: boolean;
  showToolbar?: boolean;
  showDeleteButton?: boolean;
  editForm?: ReactElement;
  addForm?: ReactElement;
  // loadMoreData?: (
  //   pageNumber: number,
  //   pageSize: number,
  //   criteria?: any
  // ) => Promise<[any]>;
  onAddItem?: (dataItem: T) => any;
  onEditItem?: (dataItem: T[], dataItemID: number) => any;
  onDeleteItem?: (dataItemID: number) => Promise<boolean>;
  // errorMessage?: string;
  // setErrorMessage?: (message: string) => void;
  onConfirmAdd?: (dataItem: T) => Promise<boolean>;
  handleOnRowClick?: (e: GridRowClickEvent) => void;
  // toolbarActions?: Array<any>;
  toolbarActions?: JSX.Element;
  handleOnSelectionChanged?: (e: GridSelectionChangeEvent) => void;
  defaultBindingModel?: Partial<T>;
  defaultNewItem?: Partial<T>;
  showRefresh?: boolean;
  handleRefresh?: () => any;
  showCounter?: boolean;
  handleAddInExternalForm?: () => void;
  handleOnExpandChange?: (dataItem: T) => void;
  enableRowSelection?: boolean;
  showScroll?: boolean;
  editInPlace?: boolean;
  showAddForm?: boolean;
  setShowAddForm?: (showform: boolean) => void;
  showEditForm?: boolean;
  setShowEditForm?: (showEdirForm: boolean) => void;
  setEditedDataItem?: (businessObject: T) => void;
  showExport?: boolean;
  loadOnDemand?: {
    pageSize: number;
    setGridData: (data: T[]) => void;
    loadData: (
      // for scrolling (pagination)
      pageNumber: number,
      pageSize: number,
      criterias: any
    ) => Promise<ApiResponse<any, any>>;
    externalDataHandling: boolean; //if True then need to pass resetPageNumber, if false then need to pass initial data
    reset: boolean;
    setReset: Dispatch<SetStateAction<boolean>>;
    searchCriteria?: any;
  };
  noRecordsMessage?: string;
  gridID?: string;
  showTooltip?: boolean;
  showMultipleSelectionColumn?: boolean;
  selectionState?: {
    [id: string]: boolean | number[];
  };
  setSelectionState?: (selectionState: {
    [id: string]: boolean | number[];
  }) => void;
  selectionDataItemKey?: string;
  shouldRestoreExpandedItems?: boolean;

  lockMultipleSelectionColumn?: boolean;
  excelFileName?: string;
  enableExportExcelColumnGrouping?: {
    title: string;
    headerCellOptions?: any;
  };
  exportExcelAuthor?: string;
  customeExcelExport?: {
    excelExport: () => void;
    excelExportRef: MutableRefObject<ExcelExport | null>;
    showExportRef?: MutableRefObject<HTMLButtonElement | null>;
  };
  disableMultiSelectionColumn?: boolean;
  selectedRowIndex?: number | null;
  showRowMenuColumn?: boolean;
  menu?: {
    getMenuItems: (dataItem: any) => any[];
    handleMenuItemClick: (event: DropDownButtonItemClickEvent) => void;
  };
  collapseGroupsByDefault?: boolean;
  // showCustomCell?: boolean;
  // renderActionsCommandButton?: (dataItem: T) => JSX.Element;
  loadOnScroll?: boolean;
  customClassName?: string;
}

// const ADJUST_PADDING: number = 4;
const COLUMN_MIN: number = 4;
const EXPAND_CELL_WIDTH: number = 32;
const COMMAND_CELL_WIDTH: number = 70;
const SELECTION_CELL_WIDTH: number = 50;
const SCROLLER_PADDING: number = 3;

export default function AppGrid<T extends BusinessObjectModel>({
  gridData,
  detail,
  dataItemKey,
  filterable = true,
  sortable = true,
  groupable = true,
  singleCellEdit = false,
  onSingleCellEdit,
  showEditButton = false,
  showAddButton = false,
  showDeleteButton = false,
  showToolbar = true,
  editForm,
  addForm,
  onAddItem,
  onEditItem,
  onDeleteItem,
  onConfirmAdd,
  handleOnRowClick,
  toolbarActions,
  handleOnSelectionChanged,
  defaultBindingModel,
  defaultNewItem,
  showRefresh = true,
  handleRefresh,
  showCounter = false,
  group,
  handleAddInExternalForm,
  handleOnExpandChange,
  columns,
  enableRowSelection,
  showScroll,
  editInPlace,
  showAddForm = false,
  setShowAddForm,
  showEditForm,
  setShowEditForm,
  setEditedDataItem,
  showExport,
  loadOnDemand,
  noRecordsMessage = baseStrings.NO_RECORDS_TO_DISPLAY,
  gridID = "app-grid",
  showTooltip = true,
  showMultipleSelectionColumn,
  selectionState = {},
  setSelectionState,
  selectionDataItemKey = "ID",
  shouldRestoreExpandedItems = true,
  // showCustomCell,
  // renderActionsCommandButton,
  lockMultipleSelectionColumn = false,
  excelFileName,
  enableExportExcelColumnGrouping,
  exportExcelAuthor,
  customeExcelExport,
  disableMultiSelectionColumn = false,
  selectedRowIndex,
  showRowMenuColumn,
  menu,
  collapseGroupsByDefault = false,
  loadOnScroll = true,
  customClassName,
  ...rest
}: Props<T>) {
  const idGetter = getter(selectionDataItemKey);

  const [, setEditID] = useState<number | null>(null);
  const [dataState, setDataState] = useState<State>({
    group,
  });
  const [resultState, setResultState] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [data, setData] = useState<T[]>([]);
  const { translate, isRTL } = useContext(LocalizationContext);
  const drawerContext = useContext(DrawerContext);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [childrenColumns, setchildrenColumns] =
    useState<AppGridColumnPropsWithType[]>();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [endOfData, setEndOfData] = useState(false);

  const minGridWidth = React.useRef<number>(0);
  const minWidthColumns = React.useRef<number>(0); //No. of columns have minWidth without Width or MaxWidth
  // const fixedColumns = React.useRef<number>(0);
  const fixedGridWidth = React.useRef<number>(0);
  const gridHeaderWrap = React.useRef<any>(null);
  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  const gridRef = React.useRef<any>();
  const exportRef = React.useRef<ExcelExport | null>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [collapsedState, setCollapsedState] = useState<any[]>([]);

  const grid = React.useRef<any>(); // any;
  let tempData: T[];

  useEffect(() => {
    grid.current = document.querySelector("#" + gridID + ".k-grid");
    gridHeaderWrap.current = document.querySelector(
      "#" + gridID + " .k-grid-header-wrap"
    ); //.k-grid
    window.addEventListener("resize", handleResize);

    minGridWidth.current = 0;
    minWidthColumns.current = 0;
    fixedGridWidth.current = 0;
    // fixedColumns.current = 0;

    columns.map((column) => {
      const sumMinWidth =
        minGridWidth.current +
        (column.minWidth && !column.maxWidth && !column.width && !column.hidden
          ? column.minWidth
          : 0);
      const sumFixedWidth =
        fixedGridWidth.current +
        (!column.hidden
          ? column.maxWidth
            ? column.maxWidth
            : column.width
              ? parseInt(column.width)
              : 0
          : 0);

      minWidthColumns.current +=
        column.minWidth && !column.maxWidth && !column.width && !column.hidden
          ? 1
          : 0;

      // fixedColumns.current +=
      //   column.maxWidth || (column.width && !column.hidden) ? 1 : 0;

      minGridWidth.current = sumMinWidth;
      fixedGridWidth.current = sumFixedWidth;
      return null;
    });
    setGridCurrent(
      gridHeaderWrap.current.offsetWidth -
      (showScroll === true ? SCROLLER_PADDING : 0)
    );
    setApplyMinWidth(
      gridHeaderWrap.current.offsetWidth <
      minGridWidth.current + fixedGridWidth.current
    );
    // eslint-disable-next-line

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!gridHeaderWrap.current) return;
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(gridHeaderWrap.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [drawerContext?.drawerExpanded]);

  const restoreExpandedItems = (data: T[]) => {
    // if the data is updated externally, this method will preserve the expanded items so they do not collapse automatically.
    if (resultState && resultState.data) {
      const newData = data.map((item, index) => ({
        ...item,
        ...(shouldRestoreExpandedItems
          ? { expanded: resultState.data.at(index)?.expanded }
          : {}),
      }));
      updateGridData(newData, {});
    } else updateGridData(data, {});
  };

  const processWithGroups = (data: T[], dataState: State) => {
    const newDataState = process(data, dataState);
    if (collapseGroupsByDefault)
      setGroupIds({
        data: newDataState.data,
        group: dataState.group,
      });
    return newDataState;
  };

  useEffect(() => {
    /** This is used when the grid data is passed to the grid and handled outside the grid (like the tracked views)*/
    if (gridData) {
      //if (loadOnDemand && !loadOnDemand.externalDataHandling) return;

      restoreExpandedItems(gridData);
      setIsAddingNewItem(false);
    }

    if (!loadOnDemand || !loadOnDemand.externalDataHandling) return;
    if (loadOnDemand.reset) {
      setCurrentPageNumber();
      scrollBackToTop();
      loadOnDemand.setReset(false);
    }
    // eslint-disable-next-line
  }, [gridData]);

  useEffect(() => {
    /** This is used when we change group data for some specific states and rerender the grid */
    if (group && gridData) {
      updateGridData(gridData, { group });
    }
    // eslint-disable-next-line
  }, [group]);

  const loadInitialPageData = async () => {
    if (!loadOnDemand) return;
    await getData(0, loadOnDemand.pageSize, loadOnDemand.searchCriteria);
    loadOnDemand.setReset(false);
    scrollBackToTop();
  };
  useEffect(() => {
    /** This is used when the grid data is only handled inside the grid (like the search) */
    if (!loadOnDemand?.externalDataHandling && loadOnDemand?.reset) {
      setEndOfData(false);
      loadInitialPageData();
    }
    // eslint-disable-next-line
  }, [loadOnDemand?.reset]);

  useEffect(() => {
    if (selectedRowIndex !== undefined && selectedRowIndex !== null)
      scrollIntoSelectedRowIndex();
    // eslint-disable-next-line
  }, [selectedRowIndex]);

  const setCurrentPageNumber = () => {
    if (!loadOnDemand) return;
    if (!gridData || gridData.length === 0) setPageNumber(0);
    else {
      const numPages = gridData.length / loadOnDemand.pageSize;
      setPageNumber(Math.ceil(numPages));
      if (gridData.length % loadOnDemand.pageSize !== 0) setEndOfData(true);
      else setEndOfData(false);
    }
  };

  const scrollBackToTop = () => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({ rowIndex: 0 });
    }
  };

  const scrollIntoSelectedRowIndex = () => {
    if (gridRef.current) {
      gridRef.current.scrollIntoView({ rowIndex: selectedRowIndex });
    }
  };

  const generateId = (data: T[]) =>
    data.reduce((acc: any, current: any) => Math.max(acc, current.ID), 0) +
    new Date().getMilliseconds();

  const insertItem = (data: T[], item: any) => {
    item.ID = generateId(data);
    item.inEdit = false;
    return data;
  };

  const getData = async (page: number, pageSize: number, criterias: Record<string, any>) => {
    if (!loadOnDemand) return;

    setLoading(true);

    const response = await loadOnDemand.loadData(page, pageSize, criterias);

    if (response.ok) {
      if (response.data !== null) {
        if (page !== 0) {
          loadOnDemand.setGridData([...gridData, ...response.data]);
        } else loadOnDemand.setGridData([...response.data]);
        setPageNumber(page + 1);
        if (response.data.length < pageSize) setEndOfData(true);
      } else {
        if (page === 0) loadOnDemand.setGridData([]);
        else setEndOfData(true);
      }
    } else {
      if (setErrorMessage)
        setErrorMessage(errorHandler.getErrorMessage(response));
    }
    setLoading(false);
    return response.data;
  };

  const handleScroll = (event: GridEvent) => {
    if (!loadOnDemand) return;
    if (!loadOnScroll && pageNumber > 0) return;
    const e = event.nativeEvent;
    if (
      !loading &&
      e.target.scrollTop + 5 >= e.target.scrollHeight - e.target.clientHeight &&
      e.target.scrollTop
    ) {
      if (!endOfData) {
        getData(
          pageNumber,
          loadOnDemand.pageSize,
          loadOnDemand.searchCriteria
        );
      }
    }
  };

  const updateItem = (data: T[], item: T) => {
    const index = data.findIndex((record) => record.ID === item.ID);
    data[index] = item;
    return data;
  };

  const deleteItem = (data: T[], item: T) => {
    const index = data.findIndex((record) => record.ID === item.ID);
    data.splice(index, 1);
    return data;
  };
  const handleResize = () => {
    if (
      gridHeaderWrap.current.offsetWidth <
      minGridWidth.current + fixedGridWidth.current &&
      !applyMinWidth
    ) {
      setApplyMinWidth(true);
    } else if (
      gridHeaderWrap.current.offsetWidth >
      minGridWidth.current + fixedGridWidth.current
    ) {
      setGridCurrent(
        gridHeaderWrap.current.offsetWidth -
        (showScroll === true ? SCROLLER_PADDING : 0)
      );
      setApplyMinWidth(false);
    }

    const currentVisibleColumns = columns.filter(
      (c: any) => !(c.props && c.props.minGridWidth > grid.current.offsetWidth)
    );
    if (currentVisibleColumns.length !== columns.length) {
      setchildrenColumns(currentVisibleColumns);
    }
    // else {
    //   setchildrenColumns(children);
    // }
  };

  const toggleAddDialog = () => {
    if (!editInPlace) {
      setOpenForm(true);
    }
    setShowAddForm && setShowAddForm(true);
  };

  const CommandCell = (props: GridCellProps) => (
    <AppCommandCell<T>
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={appGridConstants.EDIT_FIELD}
      isEditable={showEditButton}
      isDeletable={showDeleteButton}
      editForm={editForm}
      singleCellEdit={singleCellEdit}
      setShowForm={setShowEditForm}
      setEditedDataItem={setEditedDataItem}
      // isCustom={showCustomCell}
      // renderActionsCommandButton={renderActionsCommandButton}
      menu={menu}
    />
  );

  const updateGridData = (newData: T[], newState: State = {}) => {
    const currentState =
      Object.keys(newState).length === 0 ? dataState : newState;
    if (Object.keys(newState).length !== 0) {
      setDataState(newState);
    }
    const result = processWithGroups(newData, currentState);
    setResultState({ ...result });
    if (collapseGroupsByDefault)
      setCollapsedState(
        getGroupIds({
          data: result.data,
        })
      );
    setData([...newData]);
  };

  const add = async (dataItem: any) => {
    dataItem.inEdit = true;
    dataItem.isNewItem = true;
    dataItem.IsValid = true;

    if (onConfirmAdd) {
      if (!dataItem.IsValid) {
        setIsAddingNewItem(false);
        return;
      }
      const succeed = await onConfirmAdd(dataItem);
      if (succeed === true) {
        const newData = insertItem(data, dataItem);
        updateGridData(newData);
      } else {
        discard(dataItem);
      }
    } else {
      const newData = insertItem(data, dataItem);
      updateGridData(newData);
    }
    setIsAddingNewItem(false);
  };

  const remove = async (dataItem: any) => {
    const newData = deleteItem(data, dataItem);
    if (onDeleteItem) {
      const succeed = await onDeleteItem(dataItem.ID);
      if (succeed) updateGridData(newData);
    } else updateGridData(newData);
  };

  const discard = (dataItem: any) => {
    const newData = [...data];
    newData.splice(0, 1);
    updateGridData(newData);
    setIsAddingNewItem(false);
  };

  const enterEdit = (dataItem: any) => {
    const newData = data.map((item) =>
      item.ID === dataItem.ID
        ? { ...item, inEdit: true, isNewItem: false }
        : item
    );
    tempData = data;
    updateGridData(newData);
  };

  const update = (dataItem: T) => {
    dataItem.inEdit = false;
    dataItem.isNewItem = false;
    const newData = updateItem(data, dataItem);
    if (onEditItem) {
      onEditItem(newData, dataItem.ID);
    }
    updateGridData(newData);
  };

  function cancel(dataItem: T) {
    const originalItem = tempData.find((p) => p.ID === dataItem.ID);
    const newData = data.map((item) =>
      item.ID === originalItem?.ID ? originalItem : item
    );
    updateGridData(newData);
  }

  const rowClick = (event: GridRowClickEvent) => {
    tempData = data;
    setEditID(event.dataItem.ID);
    const dataItem = data.find((item) => {
      return item.ID === event.dataItem.ID;
    });

    if (!dataItem) return;

    const newData = updateItem(data, {
      ...dataItem,
      inEdit: true,
    });
    if (onEditItem) {
      onEditItem(newData, dataItem.ID);
    }
    updateGridData(newData);

    // if (onEditItem) {
    //   onEditItem(dataItem, event.dataItem.ID);
    // }
    // updateGridData(
    //   data.map((item: any) => ({
    //     ...item,
    //     inEdit: item.ID === editID,
    //   }))
    // );
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.ID;
    const field = event.field || "";
    const newData = data.map((item) =>
      item.ID === inEditID ? { ...item, [field]: event.value } : item
    );
    if (onSingleCellEdit) {
      onSingleCellEdit(inEditID, field, event.value);
    }
    updateGridData(newData);
  };

  const closeEdit = (event: GridRowDoubleClickEvent) => {
    setEditID(null);
  };

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    updateGridData(data, e.dataState);
  };

  const onGroupExpandChange = React.useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
      expandChange(event);
    },
    [collapsedState]
  );
  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    if (handleOnExpandChange) handleOnExpandChange(event.dataItem);

    setResultState({ ...resultState, data: [...resultState.data] });
  };

  const addNew = () => {
    if (handleAddInExternalForm && !editInPlace) handleAddInExternalForm();
    else if (!addForm) {
      setIsAddingNewItem(true);
      const defaultItem = defaultNewItem || {};
      const newItem = {
        ...defaultBindingModel,
        ...defaultItem,
        inEdit: true,
        Discontinued: false,
        //ID: -1,
        // ID:
        //   data.reduce((acc, item) => (acc = acc > item.ID ? acc : item.ID), 0) +
        //   new Date().getMilliseconds(),
        isNewItem: true,
      };

      if (onAddItem) {
        onAddItem(newItem as T);
      }
      updateGridData([newItem as T, ...data]);
    }
  };

  const refreshGrid = () => {
    // updateGridData(gridData, {});
    if (handleRefresh) handleRefresh();
  };

  const excelExportColumn: any = gridRef.current?.columns?.filter(
    (item: any) =>
      item.field !== appGridConstants.SELECTED_FIELD &&
      item.excludeFromExport !== true
  );

  const excelExport = () => {
    if (exportRef.current !== null) {
      // exportRef.current.save(resultState.data, gridRef.current.columns);

      enableExportExcelColumnGrouping
        ? exportRef.current.save()
        : exportRef.current.save(resultState.data, excelExportColumn);
    }
  };

  //According to: https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
  const setWidth = (
    column: AppGridColumnProps,
    minWidth: number | undefined,
    originalWidth: number | undefined,
    maxWidth: number | undefined
  ) => {
    if (!minWidth && !maxWidth && !originalWidth) return "auto";

    let width;
    let availableAdditionalWidth;
    if (!originalWidth) {
      if (minWidth === undefined) minWidth = 0;

      availableAdditionalWidth =
        gridCurrent -
        fixedGridWidth.current -
        (showDeleteButton || showEditButton || (showAddButton && !addForm)
          ? COMMAND_CELL_WIDTH
          : 0) -
        (showMultipleSelectionColumn ? SELECTION_CELL_WIDTH : 0) -
        (detail ? EXPAND_CELL_WIDTH : 0) -
        minGridWidth.current -
        // (!showScroll || showScroll === true ? 17 : 0);//because I am using .k-grid-header-wrap part of k-gird - scroll
        (group ? group?.length * EXPAND_CELL_WIDTH : 0) - //because each group descriptor add its expand cell (32 px)
        (showRowMenuColumn && !(showAddButton && !addForm) ? 80 : 0);
      if (availableAdditionalWidth < 0) availableAdditionalWidth = 0;

      if (availableAdditionalWidth > 0) {
        width = applyMinWidth
          ? minWidth
          : minWidth + availableAdditionalWidth / minWidthColumns.current;
        if (width < COLUMN_MIN) {
          width = COLUMN_MIN;
        }
        // else width -= ADJUST_PADDING;
      } else width = minWidth;
    } else {
      width = originalWidth;
    }
    if (maxWidth && width > maxWidth) {
      width = maxWidth;
    }
    return Number.isFinite(width) ? width : "auto";
  };

  const onSelectionChange = (event: any) => {
    if (isAddingNewItem) return;

    const newSelectedState = getSelectedState({
      event,
      selectedState: selectionState,
      dataItemKey: selectionDataItemKey,
    });
    if (event && event.dataItem && idGetter(event.dataItem))
      setSelectionState && setSelectionState(newSelectedState);
  };
  const onHeaderSelectionChange = (event: any) => {
    if (isAddingNewItem) return;

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState: any = {};
    event.dataItems.forEach((item: any) => {
      if (!item.isNewItem) newSelectedState[idGetter(item)] = checked;
    });
    setSelectionState && setSelectionState(newSelectedState);
  };

  const GridComponent = () => {
    return (
      <Grid
        id={gridID}
        ref={gridRef}
        className={
          (showScroll === true
            ? "grid-scroll"
            : showScroll === false
              ? "no-grid-scroll"
              : "") + (isRTL ? " hierarchy-rtl" : "")
        }
        style={{ height: "100%" }}
        editField={appGridConstants.EDIT_FIELD}
        onRowClick={
          singleCellEdit
            ? (e) => {
              rowClick(e);
              if (handleOnRowClick) {
                handleOnRowClick(e);
              }
            }
            : handleOnRowClick
              ? (e) => {
                handleOnRowClick(e);
              }
              : () => { }
        }
        onRowDoubleClick={closeEdit}
        onItemChange={itemChange}
        resizable={true}
        dataItemKey={dataItemKey}
        onExpandChange={
          collapseGroupsByDefault ? onGroupExpandChange : expandChange
        }
        expandField={appGridConstants.EXPANDED_FIELD}
        selectedField={appGridConstants.SELECTED_FIELD}
        data={
          collapseGroupsByDefault
            ? setExpandedState({
              data: resultState.data,
              collapsedIds: collapsedState,
            })
            : resultState.data
        }
        filterable={filterable}
        sortable={sortable}
        groupable={groupable}
        onDataStateChange={onDataStateChange}
        fixedScroll={true}
        navigatable={true}
        detail={detail}
        onScroll={loadOnDemand ? handleScroll : () => { }}
        onSelectionChange={
          disableMultiSelectionColumn === false ? onSelectionChange : () => { }
        }
        onHeaderSelectionChange={
          disableMultiSelectionColumn === false
            ? onHeaderSelectionChange
            : () => { }
        }
        {...dataState}
        {...rest}
      >
        <GridNoRecords>
          <div
            onClick={() => {
              if (showAddButton) return addNew();
            }}
          >
            {translate(noRecordsMessage)}
          </div>
        </GridNoRecords>
        {showToolbar && (
          <GridToolbar>
            {showAddButton && (
              // <button
              //   title={translate(strings.ADD)}
              //   className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              //   onClick={addForm ? () => toggleAddDialog() : addNew}
              //   disabled={isAddingNewItem}
              // >
              //   {openForm && addForm}
              //   <span className="k-icon k-font-icon k-i-plus"></span>
              // </button>
              <AppGridToolbarButton
                title={baseStrings.ADD}
                onClick={addForm ? toggleAddDialog : addNew}
                disabled={isAddingNewItem}
                iconClassName={"k-icon k-font-icon k-i-plus"}
              ></AppGridToolbarButton>
            )}
            {showRefresh && (
              <AppGridToolbarButton
                title={baseStrings.REFRESH}
                onClick={refreshGrid}
                iconClassName={"k-icon k-font-icon k-i-refresh"}
              />
            )}
            {showExport && (
              <AppGridToolbarButton
                title={baseStrings.EXPORT}
                onClick={
                  customeExcelExport
                    ? customeExcelExport.excelExport
                    : excelExport
                }
                iconClassName={"k-icon k-font-icon k-i-file-excel"}
                buttonRef={customeExcelExport?.showExportRef}

              // iconClassName={"k-icon k-font-icon k-i-file-bac k-flip-v"}
              />
            )}
            {toolbarActions}
          </GridToolbar>
        )}

        {showDeleteButton || showEditButton || (showAddButton && !addForm)
          ? appGridColumnsHelper.getCommandColumn({
            cell: CommandCell,
          })
          : ""}

        {/* Not used and corrupt Exporting when undefined*/}
        {/* {childrenColumns} */}
        {showMultipleSelectionColumn && selectionState && (
          <GridColumn
            field={appGridConstants.SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              resultState.data.findIndex((item: any) => {
                if (!item.isNewItem) return !selectionState[idGetter(item)];
              }) === -1
            }
            locked={lockMultipleSelectionColumn}
            filterable={false}
          />
        )}
        {showRowMenuColumn &&
          !(showAddButton && !addForm) &&
          appGridColumnsHelper.getColumn(
            {
              columnType: "dropdownMenu",
              field: "",
              title: "",
              menu,
              width: "80",
              hidden: !showRowMenuColumn,
            },
            0
          )}
        {columns &&
          columns?.map((column, index) => {
            if (!column.hidden) {
              const rowClick = enableRowSelection ? { handleOnRowClick } : {};
              const widthProp = {
                width:
                  setWidth(
                    column,
                    column.minWidth,
                    column.width ? parseInt(column.width) : undefined,
                    column.maxWidth
                  ) + "",
              };
              return appGridColumnsHelper.getColumn(
                { ...column, ...rowClick, ...widthProp },
                index
              );
            }
            return null;
          })}
      </Grid>
    );
  };

  return (
    <div style={{ height: "100%" }} className={customClassName || "app-grid"}>
      {loading && <div>{<LoadingPanel />}</div>}
      {errorMessage && (
        <Alert message={errorMessage} setMessage={setErrorMessage} />
      )}

      {showExport && (
        <ExcelExport
          ref={exportRef}
          fileName={excelFileName}
          creator={exportExcelAuthor}
        />
      )}

      {showExport && customeExcelExport && (
        <ExcelExport
          ref={customeExcelExport?.excelExportRef}
          fileName={excelFileName}
          creator={exportExcelAuthor}
          data={resultState.data}
        >
          {excelExportColumn?.map((item: any, index: any) => (
            <ExcelExportColumn
              key={index}
              field={item.field}
              title={item.title}
              width={item.minWidth}
            />
          ))}
        </ExcelExport>
      )}
      {showExport && enableExportExcelColumnGrouping && (
        <ExcelExport
          ref={
            customeExcelExport ? customeExcelExport.excelExportRef : exportRef
          }
          fileName={excelFileName}
          data={resultState.data}
          creator={exportExcelAuthor}
        >
          <ExcelExportColumnGroup
            title={enableExportExcelColumnGrouping?.title}
            headerCellOptions={
              enableExportExcelColumnGrouping?.headerCellOptions
            }
          >
            {excelExportColumn?.map((item: any, index: any) => (
              <ExcelExportColumn
                key={index}
                field={item.field}
                title={item.title}
                width={item.minWidth}
              // cellOptions={{ textAlign: "center" }}
              // headerCellOptions={{ textAlign: "center" }}
              />
            ))}
          </ExcelExportColumnGroup>
        </ExcelExport>
      )}
      {showTooltip ? (
        <Tooltip openDelay={100} position="bottom" anchorElement="target">
          {GridComponent()}
        </Tooltip>
      ) : (
        <>{GridComponent()}</>
      )}
      {openForm && showAddForm ? addForm : <></>}
      {showEditForm ? editForm : <></>}
      <br />
      {showCounter && (
        <span>
          {translate(baseStrings.SHOWING)}: {resultState.data.length} {translate(baseStrings.ITEMS)}
        </span>
      )}
    </div>
  );
}
