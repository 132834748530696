import ELanguageCodes from "../base/languageCodes";
import { LanguageObj } from "../context/localizationContext";
import BaseUserModel from "../interfaces/BaseUserModel";

const getUserName = (userObject: BaseUserModel | null) => {
  if (!userObject) return "";
  return userObject ? userObject.FirstName + " " + userObject.LastName : "";
};

const getCompanyName = (language?: LanguageObj) => {
  if (language?.code.Code === ELanguageCodes.ARABIC.Code)
    return process.env.REACT_APP_COMPANY_NAME_AR;
  return process.env.REACT_APP_COMPANY_NAME_EN;
};
const baseUserHelper = {
  getUserName,
  getCompanyName,
};

export default baseUserHelper;
