import { useContext } from "react";
import { LocalizationContext } from "../context/localizationContext";
import { StackLayout } from "@progress/kendo-react-layout";
import { Icon } from "@progress/kendo-react-common";
import baseStrings from "./../base/baseStrings";
import { useLocation } from "react-router-dom";

interface Props {
  commandName?: string;
}
export default function PermissionDeniedPage({ commandName }: Props) {
  const { translate } = useContext(LocalizationContext);
  const location = useLocation();

  return (
    <StackLayout className="not-found">
      <div className="middle">
        <Icon name="warning" size="large" />
        {/* //warning /close-circle /close-outline */}
        <h1>{translate(baseStrings.PERMISSION_DENIED)}</h1>
        <hr />
        <span>{translate(baseStrings.PERMISSION_CONTACT_ADMIN)}</span>
        {commandName || location.state?.commandName ? (
          <>
            <br />
            <span style={{ fontSize: "small", fontStyle: "italic" }}>
              {commandName || location.state.commandName}
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
    </StackLayout>
  );
}
