import apiClient from "../common/api/client";
import endpoints from "./endpoints";
import AccountingFiscalYearModel from "../interfaces/accountingFiscalYears/AccountingFiscalYearModel";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import AccountingPeriodModel from "../interfaces/accountingPeriods/AccountingPeriodModel";
import {
  AccountingFiscalYearAndPeriodModel,
  AccountingFiscalYearRequestModel,
} from "../interfaces/api/AccountingFiscalYearsApiModels";
import PagingModel from "../common/interfaces/PagingModel";

const endpoint = endpoints.ACCOUNTING_FISCAL_YEARS;

const route_accounting_fiscal_year = "/accounting-fiscal-year";
const route_accounting_periods = "/accounting-periods";
const route_accounting_period = "/accounting-period";
const act_create_new_accounting_fiscal_year =
  "/create-new-accounting-fiscal-year";
const act_update_accounting_fiscal_year = "/update-accounting-fiscal-year";
const act_delete = "/delete";
const act_check_in = "/checkin-accounting-fiscal-year";
const act_checkout = "/checkout-accounting-fiscal-year";
const act_generate_accounting_periods = "/generate-accounting-periods";
const act_rollback_accounting_periods = "/rollback-accounting-periods";
const act_open_accounting_period = "/open-accounting-period";
const act_close_accounting_period = "/close-accounting-period";
const act_update_accounting_period = "/update-accounting-period";
const act_generate_fiscal_year_closing_transactions =
  "/generate-fiscal-year-closing-transactions";
const act_rollback_fiscal_year_closing_transactions =
  "/rollback-fiscal-year-closing-transactions";
const act_create_generate_fiscal_year_opening_balance_transactions_task =
  "/create-generate-fiscal-year-opening-balance-transactions-task";
const act_detach_generate_fiscal_year_opening_balance_transactions_task =
  "/detach-generate-fiscal-year-opening-balance-transactions-task";
const act_create_rollback_generate_fiscal_year_opening_balance_transactions_task =
  "/create-rollback-generate-fiscal-year-opening-balance-transactions-task";
const act_remove_fiscal_year_task = "/remove-fiscal-year-task";
const act_generate_budget_periods = "/generate-budget-periods";
const act_rollback_budget_periods = "/rollback-budget-periods";

const getAccountingFiscalYears = (
  criteria: Record<string, any>,
  paging: PagingModel
): BaseResponse<AccountingFiscalYearModel[]> =>
  apiClient.post(endpoint, JSON.stringify({ ...paging, ...criteria }), {
    headers: { "X-HTTP-Method-Override": "GET" },
  });

const createNewAccountingFiscalYear = (
  accountingFiscalYear: Partial<AccountingFiscalYearRequestModel>
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.post(
    endpoint + act_create_new_accounting_fiscal_year,
    JSON.stringify({ ...accountingFiscalYear })
  );
};

const updateAccountingFiscalYear = (
  accountingFiscalYearID: number,
  criteria: Partial<AccountingFiscalYearRequestModel>
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_update_accounting_fiscal_year,
    JSON.stringify({ ...criteria })
  );
};

const deleteAccountingFiscalYear = (
  accountingFiscalYearID: number
): BaseResponse<null> => {
  return apiClient.delete(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_delete
  );
};

const getAccountingFiscalYear = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> =>
  apiClient.get(
    endpoint + route_accounting_fiscal_year + "/" + accountingFiscalYearID
  );

const getAccountingPeriods = (criteria: {
  AccountingFiscalYearID: number;
}): BaseResponse<AccountingPeriodModel[]> =>
  apiClient.post(
    endpoint + route_accounting_periods,
    JSON.stringify({ ...criteria }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );

const generateAccountingPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_generate_accounting_periods
  );
};

const rollbackAccountingPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_rollback_accounting_periods
  );
};

const openAccountingPeriod = (
  accountingFiscalYearID: number,
  accountingPeriodID: number
): BaseResponse<AccountingFiscalYearAndPeriodModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      route_accounting_period +
      "/" +
      accountingPeriodID +
      act_open_accounting_period
  );
};

const closeAccountingPeriod = (
  accountingFiscalYearID: number,
  accountingPeriodID: number
): BaseResponse<AccountingFiscalYearAndPeriodModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      route_accounting_period +
      "/" +
      accountingPeriodID +
      act_close_accounting_period
  );
};

const updateAccountingPeriod = (
  accountingPeriodID: number,
  criteria: { ForeignName: string; LocalName: string }
): BaseResponse<AccountingFiscalYearAndPeriodModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      route_accounting_period +
      "/" +
      accountingPeriodID +
      act_update_accounting_period,
    JSON.stringify({ ...criteria })
  );
};

const checkInAccountingFiscalYear = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_check_in
  );
};

const checkoutAccountingFiscalYear = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_checkout
  );
};

const generateFiscalYearClosingTransactions = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_generate_fiscal_year_closing_transactions
  );
};

const rollbackFiscalYearClosingTransactions = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_rollback_fiscal_year_closing_transactions
  );
};

const generateFiscalYearOpeningBalanceTransactionsTask = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_create_generate_fiscal_year_opening_balance_transactions_task
  );
};

const detachFiscalYearOpeningBalanceTransactionsTask = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_detach_generate_fiscal_year_opening_balance_transactions_task
  );
};

const createRollbackFiscalYearOpeningBalanceTransactionsTask = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_create_rollback_generate_fiscal_year_opening_balance_transactions_task
  );
};

const removeFiscalYearTask = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint +
      route_accounting_fiscal_year +
      "/" +
      accountingFiscalYearID +
      act_remove_fiscal_year_task
  );
};

const generateBudgetPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint + act_generate_budget_periods + "/" + accountingFiscalYearID
  );
};

const rollbackBudgetPeriods = (
  accountingFiscalYearID: number
): BaseResponse<AccountingFiscalYearModel> => {
  return apiClient.put(
    endpoint + act_rollback_budget_periods + "/" + accountingFiscalYearID
  );
};

const accountingFiscalYearsApi = {
  getAccountingFiscalYears,
  createNewAccountingFiscalYear,
  updateAccountingFiscalYear,
  deleteAccountingFiscalYear,
  getAccountingFiscalYear,
  //---------ACCOUNTING PERIODS---------
  getAccountingPeriods,
  generateAccountingPeriods,
  rollbackAccountingPeriods,
  openAccountingPeriod,
  closeAccountingPeriod,
  updateAccountingPeriod,
  //---------ACTIONS ON FISCAL YEAR---------
  checkInAccountingFiscalYear,
  checkoutAccountingFiscalYear,
  generateFiscalYearClosingTransactions,
  rollbackFiscalYearClosingTransactions,
  generateFiscalYearOpeningBalanceTransactionsTask,
  detachFiscalYearOpeningBalanceTransactionsTask,
  createRollbackFiscalYearOpeningBalanceTransactionsTask,
  removeFiscalYearTask,
  //---------BUDGET PERIODS---------
  generateBudgetPeriods,
  rollbackBudgetPeriods,
};

export default accountingFiscalYearsApi;
