import { useContext } from "react";
import AppButton from "../buttons/AppButton";
import { StackLayout } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { LocalizationContext } from "../../context/localizationContext";
import styleConstants from "../../constants/styleConstants";
import baseStrings from "../../base/baseStrings";

interface NoResultsProps {
  visible: boolean;
  textVisible?: boolean;
  showRetry?: boolean;
  showRefresh?: boolean;
  onRetry?: () => void;
  onRefresh?: () => void;
  isError?: boolean;
  children: string;
}

export default function NoResults({
  visible,
  textVisible = true,
  showRetry = false,
  showRefresh = false,
  onRetry,
  onRefresh,
  isError,
  children,
}: NoResultsProps) {
  const { translate } = useContext(LocalizationContext);

  if (!visible) return null;

  return (
    <StackLayout
      orientation="vertical"
      align={{ horizontal: "center", vertical: "top" }}
      gap={styleConstants.GAP_MEDIUM}
    >
      {textVisible &&
        (isError ? (
          <Label>
            <span className="no-results error">{translate(children)}</span>
          </Label>
        ) : (
          <Label>
            <span className="no-results regular">{translate(children)}</span>
          </Label>
        ))}
      {showRefresh && (
        <AppButton
          title="refresh"
          onClick={onRefresh}
          icon="k-icon k-font-icon k-i-refresh"
        >
          {translate(baseStrings.REFRESH)}
        </AppButton>
      )}
      {showRetry && (
        <AppButton
          title="retry"
          onClick={onRetry}
          icon="k-icon k-font-icon k-i-refresh"
        >
          {translate(baseStrings.RETRY)}
        </AppButton>
      )}
    </StackLayout>
  );
}
