import { Dispatch, SetStateAction, useContext, useState } from "react";
import SearchFieldModel from "../../common/interfaces/SearchFieldModel";
import { LocalizationContext } from "../../common/context/localizationContext";
import strings from "../../base/strings";
import AppDropDownSearchField from "../../common/components/search/AppDropDownSearchField";
import FundAutoComplete from "../../controls/funds/base/FundAutoComplete";
import CheckupAgreementAutoComplete from "../../controls/checkup/base/CheckupAgreementAutoComplete";
import ProviderPaymentVoucherAutoComplete from "../../controls/pvps/base/ProviderPaymentVoucherAutoComplete";
import ICMPaymentVoucherAutoComplete from "../../controls/pvis/base/ICMPaymentVoucherAutoComplete";
import ClientPaymentVoucherAutoComplete from "../../controls/pvcs/base/ClientPaymentVoucherAutoComplete";
import ICMPaymentVoucherGroupAutoComplete from "../../controls/pvis/base/ICMPaymentVoucherGroupAutoComplete";
import ClaimInvoiceAutoComplete from "../../controls/claims/base/ClaimInvoiceAutoComplete";
import ClientDebitVoucherAutoComplete from "../../controls/clientDebitVouchers/base/ClientDebitVoucherAutoComplete";
import PolicyAutoComplete from "../../controls/policy/base/PolicyAutoComplete";
import AppNumericSearchField from "../../common/components/search/AppNumericSearchField";
import { DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import AdvancePaymentAutocomplete from "../../controls/advancePayments/base/AdvancePaymentAutoComplete";
import BillPaymentAutoComplete from "../../controls/billPayments/base/BillPaymentAutoComplete";

interface Props extends SearchFieldModel {
  setErrorMessage: Dispatch<SetStateAction<string>>;
  searchFieldName?: string;
}

export const AppAccountingTransactionSystemEntitySearchFieldKeys = {
  SystemEntityType: "SystemEntityType",
  SystemEntityID: "SystemEntityID",
};
export default function AppAccountingTransactionSystemEntitySearchField({
  setSearchFieldValues,
  removeSearchFieldValue,
  searchFieldName = "Code",
  setErrorMessage,
}: Props) {
  const { translate, translateIn } = useContext(LocalizationContext);

  const [entityType, setEntityType] = useState<string>();

  const EEntities = {
    PAYMENT: translate(strings.PAYMENT),
    FUND: translate(strings.FUND),
    CHECKUP_AGREEMENT: translate(strings.CHECKUP_AGREEMENT),
    INVOICE: translate(strings.INVOICE),
    PROVIDER_PAYMENT_VOUCHER: translate(strings.PVP),
    PAYMENT_VOUCHER: translate(strings.PVI),
    CLIENT_PAYMENT_VOUCHER: translate(strings.PVC),
    PAYMENT_VOUCHER_GROUP: translate(strings.PVI_GROUP),
    CLAIM_INVOICE: translate(strings.CLAIM_INVOICE),
    CLIENT_DEBIT_VOUCHER: translate(strings.CDV),
    POLICY_REVISION: translate(strings.POLICY_REVISION),
    ADVANCE_PAYMENT: translate(strings.ADVANCE_PAYMENT),
    BILL: translate(strings.BILL),
    BILL_PAYMENT: translate(strings.BILL_PAYMENT),
  };

  const getEntityTypes = async () => {
    const entityTypes = [
      {
        ID: 1,
        LocalName: translateIn(strings.PAYMENT, "ar"),
        ForeignName: translateIn(strings.PAYMENT, "en"),
        Code: "PAYMENT",
      },
      {
        ID: 2,
        LocalName: translateIn(strings.FUND, "ar"),
        ForeignName: translateIn(strings.FUND, "en"),
        Code: "FUND",
      },
      {
        ID: 3,
        LocalName: translateIn(strings.CHECKUP_AGREEMENT, "ar"),
        ForeignName: translateIn(strings.CHECKUP_AGREEMENT, "en"),
        Code: "CHECKUP_AGREEMENT",
      },
      {
        ID: 4,
        LocalName: translateIn(strings.INVOICE, "ar"),
        ForeignName: translateIn(strings.INVOICE, "en"),
        Code: "INVOICE",
      },
      {
        ID: 5,
        LocalName: translateIn(strings.PVP, "ar"),
        ForeignName: translateIn(strings.PVP, "en"),
        Code: "PROVIDER_PAYMENT_VOUCHER",
      },
      {
        ID: 6,
        LocalName: translateIn(strings.PVI, "ar"),
        ForeignName: translateIn(strings.PVI, "en"),
        Code: "PAYMENT_VOUCHER",
      },
      {
        ID: 7,
        LocalName: translateIn(strings.PVC, "ar"),
        ForeignName: translateIn(strings.PVC, "en"),
        Code: "CLIENT_PAYMENT_VOUCHER",
      },
      {
        ID: 8,
        LocalName: translateIn(strings.PVI_GROUP, "ar"),
        ForeignName: translateIn(strings.PVI_GROUP, "en"),
        Code: "PAYMENT_VOUCHER_GROUP",
      },
      {
        ID: 9,
        LocalName: translateIn(strings.CLAIM_INVOICE, "ar"),
        ForeignName: translateIn(strings.CLAIM_INVOICE, "en"),
        Code: "CLAIM_INVOICE",
      },
      {
        ID: 10,
        LocalName: translateIn(strings.CDV, "ar"),
        ForeignName: translateIn(strings.CDV, "en"),
        Code: "CLIENT_DEBIT_VOUCHER",
      },
      {
        ID: 11,
        LocalName: translateIn(strings.POLICY_REVISION, "ar"),
        ForeignName: translateIn(strings.POLICY_REVISION, "en"),
        Code: "POLICY_REVISION",
      },
      {
        ID: 12,
        LocalName: translateIn(strings.ADVANCE_PAYMENT, "ar"),
        ForeignName: translateIn(strings.ADVANCE_PAYMENT, "en"),
        Code: "ADVANCE_PAYMENT",
      },
      {
        ID: 13,
        LocalName: translateIn(strings.BILL, "ar"),
        ForeignName: translateIn(strings.BILL, "en"),
        Code: "BILL",
      },
      {
        ID: 14,
        LocalName: translateIn(strings.BILL_PAYMENT, "ar"),
        ForeignName: translateIn(strings.BILL_PAYMENT, "en"),
        Code: "BILL_PAYMENT",
      },
    ];

    return entityTypes;
  };

  const handleEntityTypeChange = async (
    event: DropDownButtonItemClickEvent,
    searchValue: string
  ) => {
    removeSearchFieldValue(
      AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
    );
    if (event.itemIndex !== 0) {
      setEntityType(event.item);
    } else {
      setEntityType("");
    }
  };

  const renderAutoComplete = (entityType?: string) => {
    switch (entityType) {
      case EEntities.PAYMENT:
        return (
          <AppNumericSearchField
            title={translate(strings.PAYMENT)}
            setSearchFieldValues={setSearchFieldValues}
            removeSearchFieldValue={removeSearchFieldValue}
            criteriaTitle={
              AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
            }
            defaultShow={true}
          />
        );
      case EEntities.FUND:
        return (
          <FundAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.FUND)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.CHECKUP_AGREEMENT:
        return (
          <CheckupAgreementAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CHECKUP_AGREEMENT)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.INVOICE:
        return (
          <AppNumericSearchField
            title={translate(strings.INVOICE)}
            setSearchFieldValues={setSearchFieldValues}
            removeSearchFieldValue={removeSearchFieldValue}
            criteriaTitle={
              AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
            }
            defaultShow={true}
          />
        );
      case EEntities.PROVIDER_PAYMENT_VOUCHER:
        return (
          <ProviderPaymentVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVP)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.PAYMENT_VOUCHER:
        return (
          <ICMPaymentVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVI)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.CLIENT_PAYMENT_VOUCHER:
        return (
          <ClientPaymentVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVC)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.PAYMENT_VOUCHER_GROUP:
        return (
          <ICMPaymentVoucherGroupAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.PVI_GROUP)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.CLAIM_INVOICE:
        return (
          <ClaimInvoiceAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CLAIM_INVOICE)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.CLIENT_DEBIT_VOUCHER:
        return (
          <ClientDebitVoucherAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.CDV)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.POLICY_REVISION:
        return (
          <PolicyAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.POLICY_REVISION)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.ADVANCE_PAYMENT:
        return (
          <AdvancePaymentAutocomplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.ADVANCE_PAYMENT)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      case EEntities.BILL:
        return (
          <AppNumericSearchField
            title={translate(strings.BILL)}
            setSearchFieldValues={setSearchFieldValues}
            removeSearchFieldValue={removeSearchFieldValue}
            criteriaTitle={
              AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
            }
            defaultShow={true}
          />
        );
      case EEntities.BILL_PAYMENT:
        return (
          <BillPaymentAutoComplete
            columns={[]}
            dataList={[]}
            loadDataOnOpen
            placeholder={translate(strings.BILL_PAYMENT)}
            className="search-component"
            onChange={(event) => {
              if (event.value)
                setSearchFieldValues(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID,
                  event.value.ID
                );
              else
                removeSearchFieldValue(
                  AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityID
                );
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <div style={{ display: "-webkit-inline-box" }}>
      <AppDropDownSearchField
        definitionType={""}
        setErrorMessage={setErrorMessage}
        removeSearchFieldValue={removeSearchFieldValue}
        setSearchFieldValues={setSearchFieldValues}
        criteriaTitle={
          AppAccountingTransactionSystemEntitySearchFieldKeys.SystemEntityType
        }
        defaultShow={false}
        title={translate(strings.ENTITY)}
        searchFieldName={searchFieldName}
        onChange={handleEntityTypeChange}
        useCustomBinding
        getCustomList={getEntityTypes}
      />
      {renderAutoComplete(entityType)}
    </div>
  );
}
