export enum EObjectShortcuts {
  CHRONIC_FORM = "CRF",
  CHRONIC_FORM_REQUEST = "CFR",
  TPA_COMPANY = "TPA",
  SERVICE = "SRV",
  // POLICY = "POLICY",
  // POLICY_REVISION = "POLICY_REVISION",
  // APPLICATION = "APPLICATION",
  // APPROVAL_REQUEST = "APPROVAL_REQUEST",
  // APPROVAL = "APPROVAL",
  // REQUEST = "REQUEST",
  // CLAIM = "CLAIM",
  // ISSUANCE_ITEM = "ISSUANCE_ITEM",
  // PACK = "PACK",
  // PLAN = "PLAN",
  // ONLINE_SUBMISSION = "ONLINE_SUBMISSION",
  // PROVIDER_GROUP = "PROVIDER_GROUP",
  // PROVIDER = "PROVIDER",
  // INFO = "INFO",
  // PVI_GROUP = "PVI_GROUP",
  // PVI = "PVI",
  // REIMBURSEMENT_BATCH = "REIMBURSEMENT_BATCH",
  // REIMBURSEMENT_ITEM = "REIMBURSEMENT_ITEM",
  // REIMBURSEMENT_REQUEST = "REIMBURSEMENT_REQUEST",
  // SERVICE = "SERVICE",
  // TICKET = "TICKET",
  // TRANSMITTAL = "TRANSMITTAL",
}
