import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import AppScrollbar from "../scrollbar/AppScrollbar";
import NoResults from "../general/NoResults";
import { listPageDefaultSize } from "../../constants/appConstants";
import { ApiResponse } from "apisauce";
import errorHandler from "../../utility/errorHandler";
import { StackLayout } from "@progress/kendo-react-layout";
import baseStrings from "../../base/baseStrings";

interface Props<T> {
  data: T[];
  setData: (data: any) => void;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
  fetchDataOnStartup: boolean;
  renderComponent: (item: T, index: number) => ReactElement;
  loadData: (
    pageNumber: number,
    pageSize: number
  ) => Promise<ApiResponse<any, any>>;
  onFetchingInitialData?: () => void;
  onRefresh?: () => void;
  onRetry?: () => void;
  resetPageNumber?: boolean;
  setResetPageNumber?: Dispatch<SetStateAction<boolean>>;
  horizontalAlignment?: "start" | "center" | "end" | "stretch";
  pageSize?: number;
}
export default function AppCardList<T>({
  data,
  setData,
  loading,
  setLoading,
  fetchDataOnStartup = true,
  loadData,
  renderComponent,
  onFetchingInitialData,
  onRefresh,
  onRetry,
  resetPageNumber,
  setResetPageNumber,
  horizontalAlignment = "center",
  pageSize = listPageDefaultSize,
}: Props<T>) {
  const [pageNumber, setPageNumber] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [endOfData, setEndOfData] = useState(false);

  useEffect(() => {
    if (fetchDataOnStartup) {
      getData(0);
    } else {
      setPageNumber(1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (resetPageNumber) {
      setCurrentPageNumber();
      if (setResetPageNumber) setResetPageNumber(false);
    }
    // eslint-disable-next-line
  }, [data]);

  const setCurrentPageNumber = () => {
    if (!data || data.length === 0) setPageNumber(0);
    else {
      const numPages = data.length / pageSize;
      setPageNumber(Math.ceil(numPages));
      if (data.length % pageSize !== 0) setEndOfData(true);
      else setEndOfData(false);
    }
  };
  const handleScroll = (event: any) => {
    const e = event;
    if (
      !loading &&
      e.target.scrollTop + 5 >= e.target.scrollHeight - e.target.clientHeight &&
      e.target.scrollTop
    ) {
      if (!endOfData) {
        getData(pageNumber);
      }
    }
  };

  const getData = async (page: number) => {
    setLoading(true);

    const response = await loadData(page, pageSize);

    if (response.ok) {
      if (response.data !== null) {
        if (page !== 0) {
          setData([...data, ...response.data]);
        } else setData([...response.data]);
        setPageNumber(page + 1);
        if (response.data.length < pageSize) setEndOfData(true);
      } else {
        if (page === 0) setData([]);
        else setEndOfData(true);
      }
    } else {
      setErrorMessage(errorHandler.getErrorMessage(response));
    }
    if (page === 0 && onFetchingInitialData) onFetchingInitialData();
    setLoading(false);
    return response.data;
  };

  return (
    <AppScrollbar onScroll={handleScroll}>
      {errorMessage ? (
        <NoResults
          visible={!!errorMessage}
          isError
          showRetry
          onRetry={() => {
            setErrorMessage("");
            if (onRetry) {
              if (setResetPageNumber) setResetPageNumber(true);
              onRetry();
            } else getData(0);
          }}
        >
          {errorMessage}
        </NoResults>
      ) : !data || data.length === 0 ? (
        <NoResults
          visible
          isError={false}
          showRefresh
          onRefresh={() => {
            if (onRefresh) {
              if (setResetPageNumber) setResetPageNumber(true);
              onRefresh();
            } else getData(0);
          }}
        >
          {baseStrings.NO_RECORDS_TO_DISPLAY}
        </NoResults>
      ) : (
        <StackLayout
          orientation="vertical"
          align={{ horizontal: horizontalAlignment }}
        >
          {data &&
            data.length > 0 &&
            React.Children.toArray(
              data.map((item, i) => renderComponent(item, i))
            )}
        </StackLayout>
      )}
    </AppScrollbar>
  );
}
