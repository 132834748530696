import apiClient from "../common/api/client";
import PagingModel from "../common/interfaces/PagingModel";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import AdvancePaymentModel from "../interfaces/advancePayments/AdvancePaymentModel";
import AdvancePaymentStatusModel from "../interfaces/advancePayments/AdvancePaymentStatusModel";
import {
  AddAdvancePaymentRequestModel,
  EditAdvancePaymentRequestModel,
} from "../interfaces/api/AdvancePaymentApiModels";
import endpoints from "./endpoints";

const endpoint = endpoints.ADVANCE_PAYMENTS;

const route_advance_payment = "/advance-payment";
const route_advance_payment_status = "/advance-payment-status";

const act_download_advance_payment_details_report =
  "/download-advance-payment-details-report";
const act_download_advance_payments_audit_report =
  "/download-advance-payments-audit-report";
const act_add_advance_payment = "/add-advance-payment";
const act_update_advance_payment = "/update-advance-payment";
const act_delete_advance_payment = "/delete-advance-payment";
const act_accept_advance_payment = "/accept-advance-payment";
const act_reject_advance_payment = "/reject-advance-payment";
const act_rollback_advance_payment = "/roll-back-advance-payment";
const act_reverse_advance_payment = "/reverse-advance-payment";
const act_rollback_reverse_advance_payment =
  "/rollback-reverse-advance-payment";
const act_set_advance_payment_as_paid = "/set-advance-payment-as-paid";

const getAdvancePayments = (
  criteria: Record<string, any>,
  paging: PagingModel
): BaseResponse<AdvancePaymentModel[]> => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getAdvancePayment = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> =>
  apiClient.get(endpoint + route_advance_payment + "/" + advancePaymentID);

const createNewAdvancePayment = (
  advancePayment: AddAdvancePaymentRequestModel
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.post(
    endpoint + act_add_advance_payment,
    JSON.stringify({ ...advancePayment })
  );
};

const updateAdvancePayment = (
  advancePaymentID: number,
  criteria: EditAdvancePaymentRequestModel
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_update_advance_payment + "/" + advancePaymentID,
    JSON.stringify({ ...criteria })
  );
};

const deleteAdvancePayment = (advancePaymentID: number): BaseResponse<null> => {
  return apiClient.delete(
    endpoint + act_delete_advance_payment + "/" + advancePaymentID
  );
};

const acceptAdvancePayment = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_accept_advance_payment + "/" + advancePaymentID
  );
};

const rejectAdvancePayment = (
  advancePaymentID: number,
  AdditionalInfo?: string
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_reject_advance_payment + "/" + advancePaymentID,
    JSON.stringify({
      Text: AdditionalInfo,
    })
  );
};

const rollbackAdvancePayment = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_rollback_advance_payment + "/" + advancePaymentID
  );
};

const reverseAdvancePayment = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_reverse_advance_payment + "/" + advancePaymentID
  );
};

const rollbackReverseAdvancePayment = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_rollback_reverse_advance_payment + "/" + advancePaymentID
  );
};

const setAdvancePaymentAsPaid = (
  advancePaymentID: number
): BaseResponse<AdvancePaymentModel> => {
  return apiClient.put(
    endpoint + act_set_advance_payment_as_paid + "/" + advancePaymentID
  );
};

const getAdvancePaymentStatus = (): BaseResponse<AdvancePaymentStatusModel[]> =>
  apiClient.get(endpoint + route_advance_payment_status);

const downloadAdvancePaymentDetailsReport = (criteria: Record<string, any>) => {
  return apiClient.post(
    endpoint + act_download_advance_payment_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadAdvancePaymentsAuditReport = (criteria: Record<string, any>) => {
  return apiClient.post(
    endpoint + act_download_advance_payments_audit_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const advancePaymentsApi = {
  getAdvancePayments,
  getAdvancePayment,
  createNewAdvancePayment,
  updateAdvancePayment,
  deleteAdvancePayment,
  acceptAdvancePayment,
  rejectAdvancePayment,
  rollbackAdvancePayment,
  reverseAdvancePayment,
  rollbackReverseAdvancePayment,
  setAdvancePaymentAsPaid,
  getAdvancePaymentStatus,
  downloadAdvancePaymentDetailsReport,
  downloadAdvancePaymentsAuditReport,
};

export default advancePaymentsApi;
