import {
  createRef,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
} from "react";

import { Dialog, DialogProps } from "@progress/kendo-react-dialogs";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";
import styleConstants from "../../constants/styleConstants";
import { Breakpoints } from "../../constants/breakpoints";
import LoadingPanel from "../general/LoadingPanel";
import breakpointKeys from "../../constants/breakpointKeys";
import useBreakpoint from "../../hooks/useBreakpoint";

interface Props extends DialogProps {
  children: ReactNode;
  isForm?: boolean;
  dialogWidth?:
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "xxxlarge";
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}

export default function AppDialog({
  children,
  autoFocus,
  title,
  closeIcon,
  width,
  height,
  minWidth,
  onClose,
  isForm = false,
  dialogWidth,
  loading,
  ...rest
}: Props) {
  const { translate, isRTL } = useContext(LocalizationContext);
  const { breakpoint } = useBreakpoint();

  const modalRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (modalRef.current) {
      const firstElement = uiHelper.getFirstFocusableElement(
        modalRef.current,
        true
      );
      if (firstElement) (firstElement as HTMLElement).focus();
    }
    // eslint-disable-next-line
  }, []);

  const getDialogWidth = () => {
    if (breakpoint === breakpointKeys.MOBILE)
      return styleConstants.DIALOG_WIDTH_SMALL;
    if (dialogWidth) {
      switch (dialogWidth) {
        case "small":
          return styleConstants.DIALOG_WIDTH_SMALL;
        case "large":
          return styleConstants.DIALOG_WIDTH_LARGE;
        case "xlarge":
          if (breakpoint === breakpointKeys.TABLET)
            return styleConstants.DIALOG_WIDTH_LARGE;
          return styleConstants.DIALOG_WIDTH_XLARGE;
        case "xxlarge":
          if (breakpoint === breakpointKeys.TABLET)
            return styleConstants.DIALOG_WIDTH_LARGE;
          return styleConstants.DIALOG_WIDTH_XXLARGE;
        case "xxxlarge":
          if (breakpoint === breakpointKeys.TABLET)
            return styleConstants.DIALOG_WIDTH_LARGE;
          return styleConstants.DIALOG_WIDTH_XXXLARGE;
        case "medium":
        default:
          return styleConstants.DIALOG_WIDTH_MEDIUM;
      }
    }
    return width || styleConstants.DIALOG_WIDTH_MEDIUM;
  };

  return (
    <Dialog
      style={{ zIndex: 100 }}
      title={translate(title)}
      autoFocus={autoFocus}
      closeIcon={closeIcon}
      width={getDialogWidth()}
      height={height}
      minWidth={minWidth}
      onClose={onClose}
      className={
        "app-dialog" +
        (isForm === true ? "-form" : "") +
        (isRTL ? " rtlDirection" : " ltrDirection")
      }
      contentStyle={
        breakpoint === breakpointKeys.MOBILE
          ? { maxHeight: styleConstants.DIALOG_MAX_HEIGHT_MOBILE + "px" }
          : breakpoint === breakpointKeys.TABLET
            ? { maxHeight: styleConstants.DIALOG_MAX_HEIGHT_TABLET + "px" }
            : breakpoint === breakpointKeys.DESKTOP
              ? { maxHeight: styleConstants.DIALOG_MAX_HEIGHT_DESKTOP + "px" }
              : { maxHeight: styleConstants.DIALOG_HEIGHT_XLARGE + "px" }
      }
      {...rest}
    >
      <div ref={modalRef} style={{ height: "100%" }}>
        {children}
      </div>
      {loading && <LoadingPanel isDialogLoader={true} />}
    </Dialog>
  );
}
