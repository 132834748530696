export default Object.freeze({
  APPROVALS: "/approvals",
  PROVIDERS: "/providers",
  APPROVAL_REQUESTS: "/approval-requests",
  ATTACHMENTS: "/attachments",
  CARDS: "/cards",
  CHRONIC_FORMS: "/chronic-forms",
  CLAIMS: "/claims",
  COMMENTS: "/comments",
  COUNTRIES: "/countries",
  DIAGNOSES: "/diagnoses",
  // INSURANCE_COMPANIES: "/insurancecompanies",
  SEARCH_FIELDS: "/search-fields",
  SERVICES: "/services",
  USERS: "/users",
  ATTACHMENT_TYPES: "/attachment_types",
  INSURANCE_COMPANIES: "/insurance-companies",
  CURRENCIES: "/currencies",
  MEDICAL_FILES: "/medical-files",
  INSURED: "/insured",
  MEDICAL_CONDItIONS: "/medical-conditions",
  SMS: "/sms",
  SYSTEM_SETTINGS: "/system-settings",
  TRANSACTION_COMMENTS: "/transaction-comments",
  ISSUANCE_ITEMS: "/issuance-items",
  APPLICATIONS: "/applications",
  BASE_CONCEPTS: "/base-concepts",
  ORGANIZATIONS: "/organizations",
  REIMBURSEMENT_REQUESTS: "/reimbursement-requests",
  CLIENTS: "/clients",
  INVOICES: "/invoices",
  TICKETS: "/tickets",
  PRESCRIPTIONS: "/prescriptions",
  PROVIDER_TRANSMITTALS: "/provider-transmittals",
  REPORTS: "/reports",
  POLICIES: "/policies",
  BATCHES: "/batches",
  TRANSMITTALS: "/transmittals",
  TPA_COMPANIES: "/tpa-companies",
  BILLS: "/bills",
  BILL_PAYMENTS: "/bill-payments",
  PVP: "/pvp",
  BANKS: "/banks",
  PORTAL_NOTIFICATIONS: "/portal-notifications",
  PLANS: "/Plans",
  PVI: "/pvi",
  PRICING_SCHEMES: "/pricing-schemes",
  TAXES: "/taxes",
  PACKS: "/packs",
  PVC: "/pvc",
  BENEFIT_MAPPINGS: "/benefit-mappings",
  INSURANCE_TYPES: "/insurance-types",
  TASKS: "/tasks",
  INSTALLMENT_PERIODS: "/installment-periods",
  BROKERS: "/brokers",
  SANITY_CHECKS: "/sanity-checks",
  PROVIDER_GROUPS: "/provider-groups",
  INSURED_RELATIONS: "/insured-relations",
  CLASS_TYPES: "/class-types",
  PRODUCTS: "/products",
  CHARGES: "/charges",
  PARTIES: "/parties",
  APPLICATION_WORKSPACES: "/application-workspaces",
  APPOINTMENTS: "/appointments",
  CLIENT_DEBIT_VOUCHERS: "/client-debit-vouchers",
  DOCUMENTS: "/documents",
  REINSURERS: "/reinsurers",
  WORKFLOW_OBJECTS: "/workflow-objects",
  EMAILS: "/emails",
  FEATURES: "/features",
  FUNDS: "/funds",
  PERSONS: "/persons",
  CORPORATE_RELATION: "/corporate-relation",
  CHECKUP: "/checkup",
  TREASURIES: "/treasuries",
  CUSTOMERS: "/customers",
  VENDORS: "/vendors",
  ADVANCE_PAYMENTS: "/advance-payments",
  ACCOUNTING_ITEMS: "/accounting-items",
  SYNDICATES: "/syndicates",
  OFFICIAL_LETTERS: "/official-letters",
  COLLECTIONS: "/collections",
  SALES: "/sales",
  CONTACTS: "/contacts",
  OFFERED_SERVICES: "/offered-services",
  EMPLOYEES: "/employees",
  ACCOUNTS: "/accounts",
  COST_CENTERS: "/cost-centers",
  ACCOUNTING_FISCAL_YEARS: "/accounting-fiscal-years",
  BUDGETS: "/budgets",
  TRANSACTIONS: "/transactions",
  FILE_REVIEWS: "/file-reviews",
  REGIONS: "/regions",
  NOTIFICATIONS: "/notifications",
  TAX_REPORTS: "/tax-reports",
  IN_HOUSE: "/in-house",
  DIGITAL_PRESCRIPTIONS: "/digital-prescriptions",
  QUERY_ROUTES: "/query-routes",
});
