interface BaseLanguageCode {
  Key: "LOCAL" | "FOREIGN";
  Name: string;
  Code: string;
  Localization: string;
  rtlActive: boolean;
}

const ELanguageCodes: { ARABIC: BaseLanguageCode; ENGLISH: BaseLanguageCode } =
  {
    // Code: To show Language Code in drop down List.
    // Localization: Same field name in Localizations Object.
    // rtlActive: To know if the language written from left to right or right to left
    ARABIC: {
      Key: "LOCAL",
      Name: "العربية",
      Code: "ar",
      Localization: "ArabicLocalization",
      rtlActive: true,
    },
    ENGLISH: {
      Key: "FOREIGN",
      Name: "English",
      Code: "en",
      Localization: "EnglishLocalization",
      rtlActive: false,
    },
  };

export default ELanguageCodes;
