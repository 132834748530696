import { colorCategory } from "../base/colorCategory";

export const getColor = (color: colorCategory) => {
  switch (color) {
    case colorCategory.SUCCESS:
      return "green";
    case colorCategory.ERROR:
      return "red";
    case colorCategory.WARNING:
      return "#b98d1d";
    case colorCategory.DISABLED:
      return "#ccccccr";
    case colorCategory.UNDER_PROCESSING:
      return "goldenrod";
    case colorCategory.NEEDS_SUBMISSION:
      return "cadetblue";
    case colorCategory.ORANGE:
      return "orange";
    case colorCategory.YELLOW:
      return "";
    case colorCategory.LINK:
      return "#0d6efd";
    case colorCategory.PURPLE:
      return "purple";
    case colorCategory.BLUE:
      return "blue";
    case colorCategory.GRAY:
      return "gray";
    case colorCategory.DARK_RED:
      return "darkred";
    case colorCategory.WHITE:
      return "white";
    case colorCategory.DARK_GREEN:
      return "darkgreen";
    case colorCategory.REJECTED:
      return "orangered";
    case colorCategory.TRANSPARENT:
      return "";
    case colorCategory.PRIMARY:
      return "#1A3B67";
  }
};
