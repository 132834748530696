import portalCodes from "./portalCodes";
import portals from "./portals";
import systemReportCodes from "../controls/reports/base/systemReportCodes";
import baseRoutes from "../common/base/baseRoutes";
import UserModel from "./../interfaces/user/UserModel";
import ETenant from "./ETenant";

const getUserPortal = (user: UserModel, token: string) => {
  let returnValue: string = baseRoutes.LOGIN;
  if (!user || !token) return returnValue;

  // if (user.IsInsuredPerson) returnValue = portals.INSURED_PORTAL;
  // else if (user.IsProvider) returnValue = portals.PROVIDER_PORTAL;
  // else

  if (process.env.REACT_APP_TENANT === ETenant.TPA) {
    if (user.IsSystemUser) returnValue = portals.ADMIN_PORTAL;
    else if (user.IsProvider) returnValue = portals.PROVIDER_PORTAL;
    else if (user.IsInsuranceCompany)
      returnValue = portals.INSURANCE_COMPANY_PORTAL;
    else if (user.IsClientCompany || user.IsClientPerson)
      returnValue = portals.CLIENT_PORTAL;
    else if (user.IsReinsurer) returnValue = portals.REINSURER_PORTAL;
    else returnValue = baseRoutes.ERROR_PAGE;
  } else {
    if (user.IsSystemUser) returnValue = portals.PAYER_PORTAL;
    else returnValue = baseRoutes.ERROR_PAGE;
  }
  //   switch (user.UserType) {
  //     case userTypes.TPA_COMPANY:
  //       returnValue = portals.ADMIN_PORTAL;
  //       break;
  //     case userTypes.PROVIDER:
  //       returnValue = portals.PROVIDER_PORTAL;
  //       break;
  //     case userTypes.POLICY_HOLDER:
  //       returnValue = portals.CLIENT_PORTAL;
  //       break;
  //     case userTypes.INSURED_PERSON:
  //       returnValue = portals.INSURED_PORTAL;
  //       break;
  //     case userTypes.INSURANCE_COMPANY:
  //       returnValue = portals.INSURANCE_COMPANY_PORTAL;
  //       break;
  //     default:
  //       break;

  return returnValue;
};

const getUserPortalCode = (user: UserModel) => {
  if (!user) return null;
  if (user.IsSystemUser) return portalCodes.ADMIN;
  else if (user.IsProvider) return portalCodes.PROVIDER;
  else if (user.IsClientCompany || user.IsClientPerson)
    return portalCodes.CLIENT;
  else if (user.IsInsuredPerson) return portalCodes.INSURED;
  else if (user.IsInsuranceCompany) return portalCodes.INSURANCE_COMPANY;
  else if (user.IsReinsurer) return portalCodes.REINSURER;
  return null;
};

const getUserReportRootNodeTag = (user: UserModel) => {
  if (!user) return systemReportCodes.NONE;
  else if (user.IsInsuranceCompany)
    return systemReportCodes.INSURANCE_COMPANY_GROUP_REPORTS;
  if (user.IsProvider) return systemReportCodes.PROVIDER_GROUP_REPORTS;
  else if (user.IsClientCompany || user.IsClientPerson)
    return systemReportCodes.CLIENT_GROUP_REPORTS;
  else if (user.IsSystemUser) return systemReportCodes.REPORTS;
  else if (user.IsInsuredPerson) return systemReportCodes.INSURED_GROUP_REPORTS;
  else if (user.IsReinsurer) return systemReportCodes.REINSURER_GROUP_REPORTS;
  return systemReportCodes.NONE;
};

const userHelper = {
  getUserPortal,
  getUserPortalCode,
  getUserReportRootNodeTag,
};

export default userHelper;
