import { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../context/localizationContext";
import uiHelper from "../helpers/uiHelper";
import { EObjectShortcuts } from "../base/objectShortcuts";

export default function useDocumentTitle(
  prefix: string,
  additionalData?: string
) {
  const { translate, isRTL } = useContext(LocalizationContext);
  const [additionalInfoTitle, setAdditionalInfoTitle] = useState<string>("");

  useEffect(() => {
    if (additionalData) {
      setAdditionalInfoTitle(additionalData);
      if (!isRTL) {
        if (EObjectShortcuts[prefix as keyof object])
          uiHelper.setDocumentTitle(
            EObjectShortcuts[prefix as keyof object] +
              " (" +
              additionalInfoTitle +
              ") "
          );
        else {
          uiHelper.setDocumentTitle(
            translate(prefix) + " (" + additionalInfoTitle + ") "
          );
        }
      } else {
        uiHelper.setDocumentTitle(
          translate(prefix) + " (" + additionalInfoTitle + ") "
        );
      }
    } else uiHelper.setDocumentTitle(translate(prefix));
    // eslint-disable-next-line
  }, [additionalInfoTitle, isRTL, prefix, additionalData]);
}
