import {
  JSXElementConstructor,
  cloneElement,
  useContext,
  useState,
  useEffect,
  MouseEventHandler,
} from "react";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
  MultiColumnComboBoxColumn,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { LocalizationContext } from "../../context/localizationContext";
import uiHelper from "../../helpers/uiHelper";
import PillNotification from "../buttons/PillNotification";
import FormMultiColumnComboBox from "../formComponents/formMultiColumnComboBox";
import { NotificationCell } from "../../helpers/appGridColumnsHelper";
import baseStrings from "../../base/baseStrings";
import AutoCompleteObject from "../../interfaces/AutoCompleteObject";

interface CustomCellProps extends GridCellProps {
  autocompleteObjectName: string;
  editable: boolean;
  loadData: (prop: any) => Promise<any>;
  textField: string;
  bindingObject: string;
  editableInNew: boolean;
  handleOnRowClick?: (e: any) => void;
  onClose?: (e: any) => void;
  notificationFieldList: NotificationCell[];
  locked?: boolean;
  handleOnKeyDown?: (e: any, value: any) => void;
  submitOnChange?: boolean;
  onClick?: (event: any, dataItem: any) => void;
  autoCompleteSearchField: string;
  getImage?: (dataItem: any) => any;
  handleImageClick?: (event: MouseEventHandler, dataItem: any) => void;
  handleItemClick?: (event: MouseEventHandler, dataItem: any) => void;
  autocompleteObjects: { [key: string]: AutoCompleteObject };
  showLocalNameOnly?: boolean;
  showForeignNameOnly?: boolean;
  customRowColor?: (dataItem: any) => string | undefined;
}

export default function AppAutoCompleteCell({
  dataItem,
  editable,
  field = "",
  dataIndex,
  bindingObject,
  textField,
  loadData,
  onChange,
  editableInNew,
  autocompleteObjectName,
  handleOnRowClick,
  handleOnKeyDown,
  onClose,
  rowType,
  notificationFieldList,
  className,
  style,
  submitOnChange = true,
  onClick,
  autoCompleteSearchField,
  getImage,
  handleImageClick,
  handleItemClick,
  autocompleteObjects,
  showLocalNameOnly = false,
  showForeignNameOnly = false,
  customRowColor,
}: CustomCellProps) {
  const { getName, translate, getNameField } = useContext(LocalizationContext);

  const [newMode, setNewMode] = useState<boolean>(dataItem.isNewItem);
  const [editMode, setEditMode] = useState<boolean>(dataItem.inEdit);

  const [dataList, setDataList] = useState([]);
  const [value, setValue] = useState<any>(null);
  const [, setAutocomplete] = useState({
    columns: [{}],
    autoCompleteEndpoint: "",
    autoCompleteSearchKey: "",
  });
  const [comboBoxColumns, setComboBoxColumns] = useState<any[]>([
    { field: "ID", header: translate(baseStrings.ID), width: "100px" },
  ]);

  useEffect(() => {
    const autoCompleteData = autocompleteObjects[autocompleteObjectName];
    setAutocomplete(autoCompleteData);

    let filteredColumns: MultiColumnComboBoxColumn[] = [];

    if (showForeignNameOnly)
      filteredColumns = autoCompleteData.columns.filter(
        (column) => column.field !== "LocalName"
      );
    else if (showLocalNameOnly)
      filteredColumns = autoCompleteData.columns.filter(
        (column) => column.field !== "ForeignName"
      );
    else filteredColumns = [...autoCompleteData.columns];

    const localizedColumns = filteredColumns.map((column) => {
      if (column.field === "Name")
        return {
          ...column,
          header: translate(column.header),
          field: getNameField(),
        };

      return { ...column, header: translate(column.header) };
    });
    setComboBoxColumns(localizedColumns);

    // setComboBoxColumns(autoCompleteData.columns);
    setNewMode(dataItem.isNewItem ? dataItem.isNewItem : false);
    setEditMode(dataItem.inEdit ? dataItem.inEdit : false);

    setValue(
      bindingObject && dataItem[bindingObject]
        ? dataItem[bindingObject]
        : dataItem
    );
    // eslint-disable-next-line
  }, [dataItem[field]]);

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (event) {
      setValue(event.target.value);
    }
    if (submitOnChange && onChange) {
      onChange({
        dataItem,
        dataIndex,
        syntheticEvent: event.syntheticEvent,
        field,
        value: event.target.value,
      });
    }
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement, string | JSXElementConstructor<any>>,
    itemProps: ListItemProps
  ) => {
    const children = comboBoxColumns.map((col, i) => {
      return (
        <span
          className="k-table-td"
          style={{
            width: col.width,
            color: customRowColor
              ? customRowColor(itemProps.dataItem)
              : undefined,
          }}
          key={col.width + i}
        >
          {itemProps.dataItem[col.field] instanceof Object
            ? getName(itemProps.dataItem[col.field])
              ? getName(itemProps.dataItem[col.field])
              : itemProps.dataItem[col.field].ID
            : itemProps.dataItem[col.field]}
        </span>
      );
    });

    return cloneElement(li, { ...li.props }, children);
  };

  let interval: NodeJS.Timeout | undefined;
  const handleOnFilterChange = async (event: ComboBoxFilterChangeEvent) => {
    if (interval) clearInterval(interval);

    interval = setInterval(async () => {
      const chars = event.filter.value;
      if (chars && chars.length >= 2) {
        const response: any = await loadData(chars);
        if (response) {
          setDataList(response);
        } else {
          setDataList([]);
        }
      }
      clearInterval(interval);
    }, 1000);
  };

  const handleClick = (e: any) => {
    onClick && onClick(e, dataItem);
    if (handleOnRowClick) handleOnRowClick({ dataItem });
  };

  const handleOnClose = (event: any) => {
    if (onClose) onClose(event);
  };

  if (rowType === "groupHeader") {
    return null;
  }

  const canEdit = () => {
    return (editMode && editable) || (editMode && newMode && editableInNew);
  };

  const renderNotifications = () => {
    return (
      <div
        style={{ display: "flex", padding: "2px", justifyContent: "center" }}
      >
        {notificationFieldList.map((notificationItem, index) => {
          const value = uiHelper.getFieldValue(
            dataItem,
            notificationItem.field
          );

          let popupText = "";
          if (notificationItem.notificationPopupTextField) {
            popupText = uiHelper.getFieldValue(
              dataItem,
              notificationItem.notificationPopupTextField
            );
          }

          return (
            value && (
              <PillNotification
                text={notificationItem.title}
                notificationStatus={notificationItem.notificationType}
                key={index}
                className="pill-notification-button"
                notificationPopupText={popupText}
                handleClick={() =>
                  notificationItem.handleClick &&
                  notificationItem.handleClick(dataItem)
                }
              />
            )
          );
        })}
      </div>
    );
  };

  const handleKeyDown = (e: any) => {
    if (canEdit() && (e.code === "Enter" || e.keyCode === 13)) {
      e.preventDefault();
      //Enter

      // if (handleOnKeyDown) handleOnKeyDown(e, value);
      if (handleOnKeyDown) handleOnKeyDown({ e, dataItem }, value);
    }
  };

  const handleBlur = (e: any) => {
    if (!submitOnChange && valueChanged() && onChange) {
      onChange({
        dataItem,
        dataIndex,
        syntheticEvent: e.syntheticEvent,
        field,
        value,
      });
    }
  };

  const valueChanged = () => {
    let returnValue = false;

    if (value && dataItem[field]) {
      returnValue = value.ID !== dataItem[field].ID;
    } else if (value === null && dataItem[field] === null) returnValue = false;
    else if (value != null || dataItem[field] != null) returnValue = true;
    return returnValue;
  };

  const imageClick = (event: any) => {
    handleImageClick && handleImageClick(event, dataItem);
  };
  const itemClick = (event: any) => {
    handleItemClick && handleItemClick(event, dataItem);
  };

  return (
    <td
      role={"gridcell"}
      // tabIndex={canEdit() ? -1 : 0}
      onClick={handleClick}
      className={className}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      style={style}
    >
      {canEdit() ? (
        <FormMultiColumnComboBox
          value={value ? (value[textField] ? value : undefined) : undefined}
          filterable={true}
          data={dataList}
          textField={autoCompleteSearchField}
          columns={comboBoxColumns}
          onFilterChange={handleOnFilterChange}
          onChange={handleChange}
          itemRender={itemRender}
          onClose={handleOnClose}
          // disabled={editMode ? !editable : !editableInNew}
          disabled={newMode ? !editableInNew : !editable}
        />
      ) : (
        <>
          <span
            onClick={itemClick}
            className={handleItemClick ? "link-color" : ""}
          >
            {bindingObject && dataItem[bindingObject]
              ? dataItem[bindingObject][textField]
              : dataItem[textField]}
          </span>
          {getImage && (
            <span onClick={imageClick}>{!canEdit() && getImage(dataItem)}</span>
          )}
          {notificationFieldList &&
          !uiHelper.isNullOrEmptyArray(notificationFieldList)
            ? renderNotifications()
            : null}
        </>
      )}
    </td>
  );
}
