import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../context/authenticationContext";
import urlBuilder from "../helpers/urlBuilder";
import Alert from "../components/general/Alert";
import errorHandler from "../utility/errorHandler";
import baseRoutes from "../base/baseRoutes";
import baseStrings from "./../base/baseStrings";
import { UserPortalContext } from "./../context/userPortalContext";
import { LocalizationContext } from "./../context/localizationContext";

export default function usePermissions(
  commandName: string,
  isDialog: boolean = false
) {
  const navigate = useNavigate();
  const { user, token, logOut } = useContext(AuthenticationContext);
  const { translate } = useContext(LocalizationContext);
  const { userHasAccessToCommand, getUserPortal } =
    useContext(UserPortalContext);

  useEffect(() => {
    if (!isDialog) checkUserHasPermission();
  }, []);

  const checkUserHasPermission = async () => {
    const response = await userHasAccessToCommand(commandName);
    if (!response.ok) {
      const message = errorHandler.getErrorMessage(response);
      if (message === "SESSION_EXPIRED") {
        logOut();
        navigate(baseRoutes.LOGIN);
      } else if (message === "invalid_credentials") {
        logOut();
        navigate(baseRoutes.LOGIN);
      }
      navigate(
        urlBuilder.buildUrl(
          getUserPortal,
          user,
          token,
          baseRoutes.PERMISSION_DENIED
        ),
        {
          state: { commandName },
        }
      );
    }
  };

  const hasPermission = async () => {
    const response = await userHasAccessToCommand(commandName);
    if (!response.ok) {
      return false;
    }
    return true;
  };

  const renderPermissionAlert = (
    handleCloseAlert: () => void,
    commandName: string
  ) => {
    return (
      <Alert
        message={
          translate(baseStrings.PERMISSION_DENIED) + " (" + commandName + ")"
        }
        setMessage={() => {}}
        onClose={handleCloseAlert}
      />
    );
  };
  return {
    checkUserHasPermission,
    hasPermission,
    renderPermissionAlert,
  };
}
