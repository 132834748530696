import { useContext } from "react";
import { MultiColumnComboBoxProps } from "@progress/kendo-react-dropdowns";
import { LocalizationContext } from "../../../common/context/localizationContext";
import strings from "../../../base/strings";
import AppAutoComplete from "../../../components/autocomplete/AppAutoComplete";
import usersApi from "../../../api/users";
import GroupModel from "../../../interfaces/userSecurity/GroupModel";

interface GroupAutoCompleteProps extends MultiColumnComboBoxProps {
  dataList?: Array<GroupModel>;
  searchCriteria?: Object;
  charNumber?: number;
  loadDataOnOpen?: boolean;
  isFormComponent?: boolean;
}

export default function GroupAutoComplete({
  dataList,
  columns,
  charNumber = 3,
  loadDataOnOpen,
  isFormComponent,
  ...rest
}: GroupAutoCompleteProps) {
  const { translate, getNameField } = useContext(LocalizationContext);

  const objectColumns = [
    { field: "ID", header: translate(strings.ID), width: "50px" },
    { field: getNameField(), header: translate(strings.Name), width: "100px" },
    { field: "Code", header: translate(strings.CODE), width: "100px" },
    { field: "Comment", header: translate(strings.COMMENT), width: "100px" },
  ];

  const getData = (inputString?: string, searchCriteria?: Object) => {
    return usersApi.getGroups({
      GroupName: inputString,
      PageSize: 10,
      Page: 0,
      ...searchCriteria,
    });
  };

  return (
    <AppAutoComplete<GroupModel>
      dataList={dataList || []}
      columns={objectColumns}
      textFieldDisplay={getNameField()}
      getData={getData}
      loadDataOnOpen={loadDataOnOpen}
      charNumber={charNumber}
      isFormComponent={isFormComponent}
      {...rest}
    />
  );
}
