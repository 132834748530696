import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import uiHelper from "../../helpers/uiHelper";
import { useContext } from "react";
import { ApplicationContext } from "./../../context/applicationContext";

interface Props extends ButtonProps {
  additionalClass?: string;
  extraIconClasses?: string;
}
export default function AppButton({
  children,
  additionalClass,
  icon,
  extraIconClasses = "",
  ...rest
}: Props) {
  const { iconFactory } = useContext(ApplicationContext);
  return (
    <Button
      className={"app-button" + (additionalClass ? " " + additionalClass : "")}
      {...rest}
    >
      {icon &&
        (uiHelper.isCustomIcon(icon) ? (
          uiHelper.renderCustomIcon(iconFactory, icon, { minWidth: "25px" })
        ) : (
          <span
            className={
              "k-icon k-font-icon k-i-" + icon + " " + extraIconClasses
            }
            style={{ padding: "0 10px" }}
          />
        ))}
      {children}
    </Button>
  );
}
